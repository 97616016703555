import { Component, OnInit ,Inject} from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-pay-my-account',
  templateUrl: './pay-my-account.component.html',
  styleUrls: ['./pay-my-account.component.css']
})
export class PayMyAccountComponent implements OnInit {
  contactusData: any = Object;
  locationData: string[];
  PaymentPromotion: string[];
  BaseUrl:string;
  constructor(private api:ApiService,private router:Router,private meta:Meta,private title:Title, @Inject('BASE_URL') baseUrl: string) {
    this.BaseUrl = baseUrl;  }

  ngOnInit() {
    this.api.getData().subscribe(data => {
      this.contactusData  = data;
      this.title.setTitle(data['paymyaccount']['SEO']['MetaTitle']);
      // this.meta.updateTag({ name: 'title', content: data['paymyaccount']['SEO']['MetaTitle'] });
			this.meta.updateTag({ name: 'description', content: data['paymyaccount']['SEO']['MetaDescription'] });
      this.meta.updateTag({ name: 'keywords', content: data['paymyaccount']['SEO']['MetaKeywords'] });

      if(data['paymyaccount']['LocationData'] != null || data['paymyaccount']['LocationData'] != undefined){
        let newIndex = [];
        let checkImage: any = data['paymyaccount']['LocationData'];
        checkImage.forEach((k1, v1) => {
          newIndex[v1] = k1;
          newIndex[v1]['image'] = JSON.parse(newIndex[v1]['image']);
        });
        this.locationData = newIndex;
        // this.locationData = data['paymyaccount']['LocationData'];
      }
      if(data['paymyaccount']['PaymentPromotion'] != null || data['paymyaccount']['PaymentPromotion'] != undefined){
        this.PaymentPromotion = JSON.parse(data['paymyaccount']['PaymentPromotion']);
      }

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);
      });
    });
  }

}
