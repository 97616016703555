import { Component, OnInit,Inject } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

declare const $: any;

@Component({
  selector: 'app-office-space-for-rent',
  templateUrl: './office-space-for-rent.component.html',
  styleUrls: ['./office-space-for-rent.component.css']
})
export class OfficeSpaceForRentComponent implements OnInit {

  defaultImage = 'assets/images/default-banner.jpg';
	loadingImg = 'assets/images/loading.gif';
	offset = 1000;

	tileClasstruefalse = {
		"iframePopup": true,
		"openIframePopup": false
	}
	storageError: boolean = false;
	locationError: boolean = false;
	LocationData: any;

	// title = "Home";
	homeData: any = Object;
	displayError: any = Object;
	Testimonial: string[];
	Blog: string[];
	DdOffers: string[];
	StorageType: string[];
	OurService: any = Object;
	Location: string[];
	LocationbannerFrm: string[];
	Feature: any = Object;
	Banner: any;
	OfferBanner: string[];
	Mobile_banner: any;
	Other_Section: any = Object;
	WhyUs: any = Object;
	bannerFrm: FormGroup;
	BaseUrl: string;
	private bannerformSumitAttempt: boolean;
	Description: any;
	Featuretitle: any;
	pageDetails: any;
	selectedValue: any;
	selectSpaceValue: any;
	serviceArrlength:number;
	// data: any;
	form: FormGroup;
	private formSumitAttempt: boolean;
	Slider_images: any;
	navi_title: any;
	promotional_text: any;
	minDate: Date;
	pageName: string;
	constructor(@Inject('BASE_URL') baseUrl: string,private api: ApiService, private router: Router, private formBuilder: FormBuilder, public meta: Meta, public title: Title, public datepipe: DatePipe) { 
		this.BaseUrl = baseUrl;
		this.loadScript('https://api.feefo.com/api/javascript/hills-self-storage');
		this.minDate = new Date();
		this.minDate.setDate(this.minDate.getDate());
	}

	ngOnInit() {
		this.api.getData().subscribe(data => {
			this.title.setTitle(data['officespaceforrent']['pageDetails']['meta_title']);
			this.meta.updateTag({ name: 'description', content: data['officespaceforrent']['pageDetails']['meta_description'] });
			this.meta.updateTag({ name: 'keywords', content: data['officespaceforrent']['pageDetails']['meta_keyword'] });
      		this.Banner = data['officespaceforrent']['Banner'];
      		this.LocationData = data['officespaceforrent']['LocationData'];
			this.selectedValue = "galston";
			this.selectSpaceValue = '';
			if(data['officespaceforrent']['pageDetails'] != null || data['officespaceforrent']['pageDetails'] != undefined){
				this.pageDetails = data['officespaceforrent']['pageDetails'];
			}

      		if (this.Banner.length == 0) {
        		this.Banner = [{ 'name': this.Banner.title, 'alt': this.Banner.title, 'title': this.Banner.title }];
      		}
      
      		if (data['officespaceforrent']['OfferBanner'] != null || data['officespaceforrent']['OfferBanner'] != undefined) {
				this.OfferBanner = data['officespaceforrent']['OfferBanner'] as string[];
			}
			if (data['officespaceforrent']['OurService'] != null || data['officespaceforrent']['OurService'] != undefined) {
				this.OurService = data['officespaceforrent']['OurService'];
			}
			if (data['officespaceforrent']['pageDetails']['feature'] != null || data['officespaceforrent']['pageDetails']['feature'] != undefined) {
				this.Feature = data['officespaceforrent']['pageDetails']['feature'];
			}
			if (data['officespaceforrent']['pageDetails']['feature_title'] != null || data['officespaceforrent']['pageDetails']['feature_title'] != undefined) {
				this.Featuretitle = data['officespaceforrent']['pageDetails']['feature_title'];
			}
			if (data['officespaceforrent']['pageDetails']['description'] != null || data['officespaceforrent']['pageDetails']['description'] != undefined) {
				this.Description = data['officespaceforrent']['pageDetails']['description'];
			}
			if (data['officespaceforrent']['Other_Section'] != null || data['officespaceforrent']['Other_Section'] != undefined) {
				this.Other_Section = data['officespaceforrent']['Other_Section'];
			}
			if (data['officespaceforrent']['WhyUs'] != null || data['officespaceforrent']['WhyUs'] != undefined) {
				this.WhyUs = data['officespaceforrent']['WhyUs'];
				this.serviceArrlength = this.WhyUs.service.length;
			}
			if (data['officespaceforrent']['pageDetails']['slider_images'] != null || data['officespaceforrent']['pageDetails']['slider_images'] != undefined) {
				this.Slider_images = JSON.parse(data['officespaceforrent']['pageDetails']['slider_images'])['img'];
			}
			if (data['officespaceforrent']['pageDetails']['navi_title'] != null || data['officespaceforrent']['pageDetails']['navi_title'] != undefined) {
				this.navi_title = data['officespaceforrent']['pageDetails']['navi_title'];
			}
			if (data['officespaceforrent']['pageDetails']['promotional_text'] != null || data['officespaceforrent']['pageDetails']['promotional_text'] != undefined) {
				this.promotional_text = data['officespaceforrent']['pageDetails']['promotional_text'];
			}
			this.pageName = data['officespaceforrent']['pageDetails']['name'];

			return new Promise(resolve => {
				const scriptElementHeader = document.createElement('script');
				// scriptElementHeader.src = "";
				scriptElementHeader.innerHTML = data['seo_header_content'];
				scriptElementHeader.onload = resolve;
				document.head.appendChild(scriptElementHeader);
		
				const scriptElementFooter = document.createElement('script');
				// scriptElementFooter.src = "";
				scriptElementFooter.innerHTML = data['seo_footer_content'];
				scriptElementFooter.onload = resolve;
				document.body.appendChild(scriptElementFooter);
		
				const scriptElementSchema = document.createElement('script');
				scriptElementSchema.type = "application/ld+json";
				// scriptElementSchema.src = "";
				scriptElementSchema.innerHTML = data['schema'];
				scriptElementSchema.onload = resolve;
				document.body.appendChild(scriptElementSchema);
		
				const BreadcrumbSchema = document.createElement('script');
				BreadcrumbSchema.type = "application/ld+json";
				// scriptElementSchema.src = "";
		
				BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"' + this.pageName + '","item":"' + location.href + '"}]}';
				BreadcrumbSchema.onload = resolve;
				document.body.appendChild(BreadcrumbSchema);
			  });
		});

		this.api.getAQuoteData().subscribe(innerdata => {
			let urlpart: any = this.router.url.split('/');
			if (urlpart[urlpart.length - 1] == 'contact-us') {
				document.querySelector('.commonQuoteForm').setAttribute('id','contactgtm');
			}
		});
		this.form = this.formBuilder.group({
			first_name: [null, Validators.required],
			last_name: [null, Validators.required],
			phone_no: [null, [Validators.required]],
			email_address: [null, [Validators.required, Validators.email]],
			move_in_date: [null, [Validators.required]],
			location: [''],
			office_space: [''],
			form_title: ['get_a_office_space_quote'],
			additional_info: [''],
			recaptcha: [false, [Validators.required]],
		});

		if (!this.Banner){
			document.getElementById("InnerBanner").innerHTML = '';
			document.getElementById("InnerBanner").classList.remove('mainBannerSec');
		}
	}

	public loadScript(url) {
		let node = document.createElement('script');
		node.src = url;
		node.type = 'text/javascript';
		document.getElementsByTagName('head')[0].appendChild(node);
	}
	
	//slides = this.Banner;
	slideConfig = { infinite: true, slidesToShow: 1, slidesToScroll: 1, arrows: false, speed: 2000, dots: true, };
	offerSlideConfig = { infinite: true, slidesToShow: 1, slidesToScroll: 1, arrows: true, autoplaySpeed: 6000, dots: false, autoplay: true,speed:2000, pauseOnHover:true,};
	storageSlideConfig = {slidesToShow:4,slidesToScroll: 1,arrows: true,autoplay: true,autoplaySpeed: 6000,dots: false,infinite: true,speed: 2000, responsive: [
		{
		  breakpoint: 1199,
		  settings: {
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: true,
			dots: false
		  }
		},
		{
			breakpoint: 991,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 1,
			  infinite: true,
			  dots: false
			}
		  },
		{
			breakpoint: 767,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			  infinite: true,
			  dots: false
			}
		  }]};
	slickInit(e) {
		//console.log(this.Banner.image_name);
	}

	scroll() {
		document.querySelector('#getQuote').scrollIntoView({ behavior: 'smooth' });
	}
	scrollService(title) {
		document.querySelector('#getQuote').scrollIntoView({ behavior: 'smooth' });
		this.selectSpaceValue = title;
	}

	onScriptLoad() { }

	onScriptError() { }

	isFieldValid(field: string) {
		return (
			(!this.form.get(field).valid && this.form.get(field).touched) ||
			(this.form.get(field).untouched && this.formSumitAttempt)
		);
	}
	displayFieldCss(field: string) {
	
		return {
			'has-error': this.isFieldValid(field),
			'has-feedback': this.isFieldValid(field)
		};
		
	}

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	onSubmit() {
		this.formSumitAttempt = true;

		let estorage = document.getElementById("office_space") as HTMLSelectElement;
		let storagesel = estorage.selectedIndex;
		let storageopt = estorage.options[storagesel];

		// let estorage = (document.getElementById("storage_option")) as HTMLOptionElement;
		let elocation = document.getElementById("getquotelocation") as HTMLSelectElement;
		let locationsel = elocation.selectedIndex;
		let locationopt = elocation.options[locationsel];
		if (locationopt == undefined || locationopt == null) {
			this.locationError = true;
		} else if (locationopt.value == "") {
			this.locationError = true;
		} else {
			this.locationError = false;
		}
		let urlpart: any = this.router.url.split('/');
		if (urlpart[urlpart.length - 1] == 'contact-us') {
			this.form.value.form_title = 'contactus_enquiry';
		}
		
		if (storageopt == undefined) {
			this.storageError = true;
			document.querySelector(".storage_optionDiv .error-msg").setAttribute("style", "display:block");
		} else if (storageopt.value == "") {
			this.storageError = true;
			document.querySelector(".storage_optionDiv .error-msg").setAttribute("style", "display:block");
		} else {
			this.storageError = false;
		}
		if (this.form.valid && this.locationError == false && this.storageError == false) {
			document.getElementById('step2Loading').setAttribute('style', "display:block;");	
			document.getElementById('getaquotesubmit').setAttribute('style', 'pointer-events:none;');
			this.form.value.move_in_date = this.datepipe.transform(this.form.value.move_in_date, 'yyyy-MM-dd');
			this.form.value.office_space = storageopt.value;
			this.form.value.location = locationopt.text;
			this.api.postData(this.form.value).subscribe(data => {
				if (data['status'] == 200 && data['EmailMessage'] == "Email Sent.") {
					// this.router.navigateByUrl('/'+data['thankyou']);
					let url = data['thankyou'];
					window.location.href=url;
				} else {
					document.getElementById('getaquotesubmit').setAttribute('style', 'pointer-events:all;');
					document.getElementById('errorMsg').setAttribute("style", "display:block;");
					(<HTMLInputElement>document.getElementById('errorMsg')).textContent = data['EmailMessage'];
				}
			});
		}
	}
}
