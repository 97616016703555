import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import {PopoverModule} from 'ngx-smart-popover';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { FormsModule } from '@angular/forms';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';
import { EscapeHtmlPipe } from '../pipes/keep-html.pipe';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { GetaquoteComponent } from './getaquote/getaquote.component';
import { StorageOptionsComponent } from './storage-options/storage-options.component';
import { SelfStoragePriceListComponent } from './self-storage-price-list/self-storage-price-list.component';
import { SelfStorageComponent } from './self-storage/self-storage.component';
import { PricelistQuoteThankyouComponent } from './pricelist-quote-thankyou/pricelist-quote-thankyou.component';
import { SelfStorageLocationComponent } from './self-storage-location/self-storage-location.component';
import { BoxesAndSuppliesComponent } from './boxes-and-supplies/boxes-and-supplies.component';
import { TruckHireComponent } from './truck-hire/truck-hire.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { VideosComponent } from './videos/videos.component';
import { FaqsComponent } from './faqs/faqs.component';
import { FormsComponent } from './forms/forms.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { SelfStorageLocationsComponent } from './self-storage-locations/self-storage-locations.component';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { NgxPaginationModule } from 'ngx-pagination';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { OrderEnquiryComponent } from './order-enquiry/order-enquiry.component';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { LightboxModule } from 'ngx-lightbox';
import { CmspageComponent } from './cmspage/cmspage.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DatePipe } from '@angular/common';
import { PayMyAccountComponent } from './pay-my-account/pay-my-account.component';
import { PayMyAccountLocationComponent } from './pay-my-account-location/pay-my-account-location.component';
import { KeepingItLocalComponent } from './keeping-it-local/keeping-it-local.component';
import { SitemapComponent } from './sitemap/sitemap.component'
import { AgmCoreModule } from '@agm/core';
import { ProposalPricelistComponent } from './proposal-pricelist/proposal-pricelist.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CalcumateComponent } from './calcumate/calcumate.component';
import { CalcumateGetAQuoteComponent } from './calcumate-get-a-quote/calcumate-get-a-quote.component';
import { SearchOptionsComponent } from './search-options/search-options.component';
import { OfficeSpaceForRentComponent } from './office-space-for-rent/office-space-for-rent.component';
import { InstantQuoteComponent } from './instant-quote/instant-quote.component';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
} 
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    FieldErrorDisplayComponent,
    EscapeHtmlPipe,
    ThankYouComponent,
    GetaquoteComponent,
    StorageOptionsComponent,
    SelfStoragePriceListComponent,
    SelfStorageComponent,
    PricelistQuoteThankyouComponent,
    SelfStorageLocationComponent,
    BoxesAndSuppliesComponent,
    TruckHireComponent,
    AboutUsComponent,
    VideosComponent,
    FaqsComponent,
    FormsComponent,
    ContactUsComponent,
    TestimonialsComponent,
    SelfStorageLocationsComponent,
    ShoppingCartComponent,
    OrderEnquiryComponent,
    SafePipe,    
    CmspageComponent, PageNotFoundComponent, PayMyAccountComponent, PayMyAccountLocationComponent, KeepingItLocalComponent, SitemapComponent, ProposalPricelistComponent, HeaderComponent, FooterComponent, CalcumateComponent, CalcumateGetAQuoteComponent, SearchOptionsComponent, OfficeSpaceForRentComponent, InstantQuoteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PopoverModule,
    FormsModule,
    SlickCarouselModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule.forRoot({
      siteKey: '6LcJYa0UAAAAAAGTQftQZgJbFXEEMNV6hkzHP0mp',
    }),
    AngularSvgIconModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    LazyLoadImageModule.forRoot({preset: intersectionObserverPreset}),
    LightboxModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAFgM81Qz-SwfTzUsr4F51AgDj0HdN88CQ'
    })
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe,{ provide: 'BASE_URL', useFactory: getBaseUrl }],
  bootstrap: [AppComponent]
})
export class AppModule { }
