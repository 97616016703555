import { Component, OnInit, Inject } from '@angular/core';
import { inject } from '@angular/core/testing';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  BaseUrl: string;
  constructor(@Inject('BASE_URL') baseUrl: string) {
    this.BaseUrl = baseUrl;
  }
  ngOnInit() {
    // document.getElementById('logo').setAttribute('src', 'assets/images/logo.png');
  }

}
