import { Component, OnInit,Inject } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';

declare const $: any;
@Component({
	selector: 'app-homepage',
	templateUrl: './homepage.component.html',
	styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

	defaultImage = 'assets/images/default-banner.jpg';
	loadingImg = 'assets/images/loading.gif';
	offset = 1000;
	starRating = 0;

	tileClasstruefalse = {
		"iframePopup": true,
		"openIframePopup": false
	}
	storageError: boolean = false;
	locationError: boolean = false;
	locationData: any;
	locDataArr: any = new Array();
	// title = "Home";
	homeData: any = Object;
	displayError: any = Object;
	Testimonial: string[];
	Blog: string[];
	DdOffers: string[];
	StorageType: string[];
	OurService: any = Object;
	Location: string[];
	LocationbannerFrm: string[];
	Video: any = Object;
	Banner: string[];
	OfferBanner: string[];
	Mobile_banner: any;
	Other_Section: any = Object;
	WhyUs: any = Object;
	bannerFrm: FormGroup;
	BaseUrl: string;
	/* Start - Below script inserted for the Google reviews*/
	GoogleReviews: string[];
	showShortDesciption: boolean = true;
	selectedText: string = '';
	genReviewCnt: any = 0;
	getnRatingCnt: any = 0;
	numSequence(n: number): Array<number> {
		return Array(Math.floor(n));
	  }
	alterdescText(text){
		console.log(text);
		this.selectedText = text;
		//return this.showShortDesciption = !this.showShortDesciption;
	}
	/* End - Below script inserted for the Google reviews*/
	
	private bannerformSumitAttempt: boolean;
	// data: any;

	constructor(@Inject('BASE_URL') baseUrl: string,private api: ApiService, private router: Router, private formBuilder: FormBuilder, public meta: Meta, public title: Title) { 
		this.BaseUrl = baseUrl;
		this.loadScript('https://api.feefo.com/api/javascript/hills-self-storage');
	}

	ngOnInit() {
		// let data = this.api.getData();
		// let data: any = JSON.parse(localStorage.getItem("PageData"));
		this.api.getData().subscribe(data => {
			this.title.setTitle(data['home']['SEO']['MetaTitle']);
			// this.meta.updateTag({ name: 'title', content: data['home']['SEO']['MetaTitle'] });
			this.meta.updateTag({ name: 'description', content: data['home']['SEO']['MetaDescription'] });
			this.meta.updateTag({ name: 'keywords', content: data['home']['SEO']['MetaKeywords'] });

			this.homeData = data;
			if (data['home']['Banner'] != null || data['home']['Banner'] != undefined) {
				this.Banner = data['home']['Banner'] as string[];
			}
			if (data['home']['OfferBanner'] != null || data['home']['OfferBanner'] != undefined) {
				this.OfferBanner = data['home']['OfferBanner'] as string[];
			}
			if (data['home']['Mobile_banner'] != null || data['home']['Mobile_banner'] != undefined) {
				this.Mobile_banner = data['home']['Mobile_banner'];
			}
			if (data['home']['StorageType'] != null || data['home']['StorageType'] != undefined) {
				this.StorageType = data['home']['StorageType'] as string[];
			}
			if (data['home']['OurService'] != null || data['home']['OurService'] != undefined) {
				this.OurService = data['home']['OurService'];
			}
			if (data['home']['Video'] != null || data['home']['Video'] != undefined) {
				this.Video = data['home']['Video'];
			}
			if (data['home']['Other_Section'] != null || data['home']['Other_Section'] != undefined) {
				this.Other_Section = data['home']['Other_Section'];
			}
			if (data['home']['WhyUs'] != null || data['home']['WhyUs'] != undefined) {
				this.WhyUs = data['home']['WhyUs'];
			}
			if (data['home']['Testimonial'] != null || data['home']['Testimonial'] != undefined) {
				this.Testimonial = data['home']['Testimonial'] as string[];
			}
			if (data['home']['Blog'] != null || data['home']['Blog'] != undefined) {
				this.Blog = data['home']['Blog'] as string[];
			}
			if (data['home']['DdOffers'] != null || data['home']['DdOffers'] != undefined) {
				this.DdOffers = data['home']['DdOffers'] as string[];
			}
			console.log(data);
			var ldata = data['home']['LocationData'];
			for(var i=0;i<ldata.length;i++){
				this.locDataArr[ldata[i].slug] = new Array();
				this.locDataArr[ldata[i].slug][0] = ldata[i].id;
				this.locDataArr[ldata[i].slug][1] = ldata[i].name;
				this.locDataArr[ldata[i].slug][2] = ldata[i].slug;
			}
			
			/* Start - Below script inserted for the Google reviews*/
			if (data['home']['SEO']['googlereview'] != null || data['home']['SEO']['googlereview'] != undefined) {
				var gReviews = JSON.parse(data['home']['SEO']['googlereview']);
				var reivewjsonData = JSON.parse(gReviews.generalReviews);
				this.genReviewCnt = this.api.genReviewCnt;
				this.getnRatingCnt = this.api.getnRatingCnt;
				console.log(this.api.getnRatingCnt);
				//console.log(this.WhyUs.service);
				this.GoogleReviews = reivewjsonData as string[];
			}
			/* End - Below script inserted for the Google reviews*/
			
			return new Promise(resolve => {
				const scriptElementHeader = document.createElement('script');
				// scriptElementHeader.src = "";
				scriptElementHeader.innerHTML = data['seo_header_content'];
				scriptElementHeader.onload = resolve;
				document.head.appendChild(scriptElementHeader);

				const scriptElementFooter = document.createElement('script');
				// scriptElementFooter.src = "";
				scriptElementFooter.innerHTML = data['seo_footer_content'];
				scriptElementFooter.onload = resolve;
				document.body.appendChild(scriptElementFooter);

				const scriptElementSchema = document.createElement('script');
				scriptElementSchema.type = "application/ld+json";
				// scriptElementSchema.src = "";
				scriptElementSchema.innerHTML = data['schema'];
				scriptElementSchema.onload = resolve;
				document.body.appendChild(scriptElementSchema);

				const metaElementSchema = document.createElement('meta');
				metaElementSchema.name = "facebook-domain-verification";
				metaElementSchema.content = "n1h7i31qx1dgy92mwq0zshf4kdccfm";
				// scriptElementSchema.src = "";
				// scriptElementSchema.innerHTML = data['schema'];
				metaElementSchema.onload = resolve;
				document.head.appendChild(metaElementSchema);
			});
		});
		this.bannerFrm = this.formBuilder.group({
			storage: [null, [Validators.required]],
			location: [null, [Validators.required]],
		});

		if (!this.Banner){
			document.getElementById("InnerBanner").innerHTML = '';
			document.getElementById("InnerBanner").classList.remove('mainBannerSec');
		}
	}

	public loadScript(url) {
		let node = document.createElement('script');
		node.src = url;
		node.type = 'text/javascript';
		document.getElementsByTagName('head')[0].appendChild(node);
	 }

	popup() {
		this.tileClasstruefalse.openIframePopup = true;
		document.getElementById('video').setAttribute('src', this.Video.url);
	}

	closepop() {
		this.tileClasstruefalse.openIframePopup = false;
	}

	// isBannerFieldValid(field: string) {
	//   return (
	//     (!this.bannerFrm.get(field).valid && this.bannerFrm.get(field).touched) ||
	//     (this.bannerFrm.get(field).untouched && this.bannerformSumitAttempt)
	//   );
	// }

	shoeStorageerror(){
		let estorage = (document.getElementById("storage")) as HTMLSelectElement;
		let storagesel = estorage.selectedIndex;
		let storageopt = estorage.options[storagesel];
		if (storageopt == undefined) {
			this.storageError = true;
		} else if (storageopt.value == "") {
			this.storageError = true;
		} else {
			this.storageError = false;
		}
	}

	onSearch() {
		this.bannerformSumitAttempt = true;
		let estorage = (document.getElementById("storage")) as HTMLSelectElement;
		let storagesel = estorage.selectedIndex;
		let storageopt = estorage.options[storagesel];
		if (storageopt == undefined) {
			this.storageError = true;
		} else if (storageopt.value == "") {
			this.storageError = true;
		} else {
			this.storageError = false;
		}

		let elocation = (document.getElementById("location")) as HTMLSelectElement;
		let locationsel = elocation.selectedIndex;
		let locationopt = elocation.options[locationsel];
		let locationValue;
		if (locationopt == undefined) {
			this.locationError = true;
			document.querySelector(".locationSelect .error-msg").setAttribute("style","display:block;");
		} else if (locationopt.value == "") {
			this.locationError = true;
			document.querySelector(".locationSelect .error-msg").setAttribute("style", "display:block");
		} else {
			this.locationError = false;
			document.querySelector('.selectlocation').textContent = locationopt.text;
			locationValue = (<HTMLOptionElement>locationopt).value;
		}
		if (this.locationError == false && this.storageError == false) {
			let urlData = '/self-storage-price-list/' + storageopt.value + '/' + locationValue;
			this.router.navigateByUrl(urlData);
		}
		// if (this.bannerFrm.valid) {
		//   this.router.navigateByUrl('/self-storage-price-list/' + this.bannerFrm.value.storage + '/' + this.bannerFrm.value.location);
		// }
	}

	StorageDataChange(event, formname) {
		let text = event.target.options[event.target.options.selectedIndex].text;
		event.target.parentNode.firstChild.textContent = text;
		event.target.parentNode.parentNode.nextSibling.firstChild.firstChild.textContent = "Select location"

		document.querySelector('.locationSelect').setAttribute("class","form-group locationSelect offerNotSelected");

		if (text == "Select Storage") {
			this.storageError = true;
			// this.locationError = true;
		} else {
			this.storageError = false;
			// this.locationError = true;
		}

		let html = '';
		var newIndex = [];
		this.StorageType = this.homeData['home']['StorageType'];
		var dat = this.StorageType.filter(x => x['slug'] === event.target.value);
		if (dat[0] != undefined && dat[0]['loc_name'] != null) {
			if (dat[0]['loc_name'].indexOf(',') > -1) {
				let locationOffers;
				if (this.DdOffers[dat[0]['id']] != undefined && this.DdOffers[dat[0]['id']] != null){
					locationOffers = (this.DdOffers[dat[0]['id']]['location_ids']).split(',');
				}			

				var Ldata = (dat[0]['loc_name'].split(',')).sort();
				html += '<span class="spanOut selectlocation">Storage location</span>\
				<select onchange="setSpanValue(this);changeOffer(this);" formControlName="location" id="location" title="Select Location">\
				<option value="">Select location</option>';
				Ldata.forEach((k1, v1) => {
					newIndex[v1] = k1.split('@');
					let offer:any;
					if (this.DdOffers[dat[0]['id']] != undefined && this.DdOffers[dat[0]['id']] != null) {
						offer = locationOffers.filter(x => x === k1.split('@')[1]);
						if (offer.length > 0){
							html += '<option value="' + k1.split('@')[2] + '" data-offer="' + this.DdOffers[dat[0]['id']]['offer_text'] + '">' + k1.split('@')[0];
								if (this.DdOffers[dat[0]['id']]['offer_text'].length > 0){
									html += ' (';
									html += this.DdOffers[dat[0]['id']]['offer_text'];
									html += ')';
								}
								html += '</option>';
						} else {
							html += '<option value="' + k1.split('@')[2] + '" data-offer="">' + k1.split('@')[0] + '</option>';
						}
					}else{
						html += '<option value="' + k1.split('@')[2] + '" data-offer="">' + k1.split('@')[0]+'</option>';
					}

				});
				html += '</select>';
				// this.LocationbannerFrm = newIndex;
				document.getElementById("locationDiv").innerHTML = html;
				document.getElementById("offerText").innerHTML = '';
			} else {
				// this.LocationbannerFrm = [dat[0]['loc_name'].split('@')];
				html += '<span class="spanOut selectlocation">Storage location</span>\
				<select onchange="setSpanValue(this);changeOffer(this);" formControlName="location" id="location" title="Select Location">\
				<option value="">Select location</option>';
				let locationOffers;
				let offer:any;
				if (this.DdOffers[dat[0]['id']] != undefined && this.DdOffers[dat[0]['id']] != null) {
					locationOffers = (this.DdOffers[dat[0]['id']]['location_ids']).split(',');
					offer = locationOffers.filter(x => x === dat[0]['loc_name'].split('@')[1]);
					if (offer.length > 0){
						html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" data-offer="' + this.DdOffers[dat[0]['id']]['offer_text'] + '">' + dat[0]['loc_name'].split('@')[0];
						if (this.DdOffers[dat[0]['id']]['offer_text'].length > 0){
							html +=' (';
							html += this.DdOffers[dat[0]['id']]['offer_text'];
							html +=')';
						}
						html += '</option>';
					} else {
						html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" data-offer="">' + dat[0]['loc_name'].split('@')[0] + '</option>';
					}
				}else{
					html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" data-offer="">' + dat[0]['loc_name'].split('@')[0] + '</option>';
				}
				html += '</select>';
				document.getElementById("locationDiv").innerHTML = html;
				document.getElementById("offerText").innerHTML = '';
			}
		} else {
			html += '<span class="spanOut selectlocation">Storage location</span>\
				<select onchange="setSpanValue(this);changeOffer(this);" formControlName="location" id="location" title="Select Location">\
				<option value="">Select location</option>';
			html += '</select>';
			document.getElementById("locationDiv").innerHTML = html;
			document.getElementById("offerText").innerHTML = '';
			// this.LocationbannerFrm = null;
		}
	}
	
	setSpanValue(event) {
		let text = event.target.options[event.target.options.selectedIndex].text;
		event.target.parentNode.firstChild.textContent = text;
		// this.DdOffers
		// if (text == "Select location") {
		// 	this.locationError = true;
		// } else {
		// 	this.locationError = false;
		// }
	}

	checkError(event) {
		console.log(event);
		let text = event.target.options[event.target.options.selectedIndex].text;
		event.target.parentNode.firstChild.textContent = text;

		if (text == "Select location") {
			this.locationError = true;
		} else {
			this.locationError = false;
		}
	}

	//slides = this.Banner;
	slideConfig = { infinite: true, slidesToShow: 1, slidesToScroll: 1, arrows: false, speed: 2000, dots: true, };
	offerSlideConfig = { infinite: true, slidesToShow: 1, slidesToScroll: 1, arrows: true, autoplaySpeed: 6000, dots: false, autoplay: true,speed:2000, pauseOnHover:true,};
	testimonialConfig = {
		infinite: true, slidesToShow: 5, slidesToScroll: 5, arrows: false, speed: 2000, dots: true,
		responsive: [
		{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
					arrows: false
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
					arrows: false
				}
			},
			{
				breakpoint: 566,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
					arrows: false
				}
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		]

	};

	slickInit(e) {
		//console.log(this.Banner.image_name);
	}
}
