import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {
  sitemapData : any = Object;
  boxes_supplies : string[];
  links : string[];
  location : string[];
  paymyaccount : string[];
  staticLinks : string[];
  storage_options : string[];
  BaseUrl:string;
  constructor(private api: ApiService, private router: Router, private title: Title, private meta: Meta, @Inject('BASE_URL') baseUrl: string) {
    this.BaseUrl = baseUrl; }
  slug = this.router.url;
  ngOnInit() {
    this.api.getData().subscribe(data => {

      this.title.setTitle(data['sitemap']['SEO']['MetaTitle']);
      // this.meta.updateTag({ name: 'title', content: data['sitemap']['SEO']['MetaTitle'] });
      this.meta.updateTag({ name: 'description', content: data['sitemap']['SEO']['MetaDescription'] });
      this.meta.updateTag({ name: 'keywords', content: data['sitemap']['SEO']['MetaKeywords'] });

      this.sitemapData  = data;
      if(data['sitemap']['Links']['boxes_supplies'] != null || data['sitemap']['Links']['boxes_supplies'] != undefined){
        this.boxes_supplies = data['sitemap']['Links']['boxes_supplies'];
      }
      if(data['sitemap']['Links']['links'] != null || data['sitemap']['Links']['links'] != undefined){
        this.links = data['sitemap']['Links']['links'][0];
      }
      if(data['sitemap']['Links']['location'] != null || data['sitemap']['Links']['location'] != undefined){
        this.location = data['sitemap']['Links']['location'];
      }
      if(data['sitemap']['Links']['paymyaccount'] != null || data['sitemap']['Links']['paymyaccount'] != undefined){
        this.paymyaccount = data['sitemap']['Links']['paymyaccount'];
      }
      if(data['sitemap']['Links']['staticLinks'] != null || data['sitemap']['Links']['staticLinks'] != undefined){
        this.staticLinks = data['sitemap']['Links']['staticLinks'];
      }
      if(data['sitemap']['Links']['storage_options'] != null || data['sitemap']['Links']['storage_options'] != undefined){
        this.storage_options = data['sitemap']['Links']['storage_options'];
      }

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";

        BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"Sitemap","item":"' + location.href + '"}]}';
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
      });
    });
  }

}
