import { Component, OnInit ,Inject} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  BaseUrl:string;
  constructor( @Inject('BASE_URL') baseUrl: string,private meta: Meta, private title: Title,) {
    this.BaseUrl = baseUrl;  }

  ngOnInit() {
    this.title.setTitle('Page Not Found | Hills Self Storage');
    this.meta.updateTag({ name: 'description', content: 'Sorry! the page you are looking for does not exist or appear on this site.' });
    this.meta.updateTag({ name: 'keywords', content:'' });
  }

}