import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-keeping-it-local',
  templateUrl: './keeping-it-local.component.html',
  styleUrls: ['./keeping-it-local.component.css']
})
export class KeepingItLocalComponent implements OnInit {
  truckhireData: any = Object;
  keepItLocal: string[];
  LocationData: string[];
  StorageType: string[];
  storageBannerData: any;
  BaseUrl:string;
  constructor(private api: ApiService, private router: Router, private meta: Meta, private title: Title, @Inject('BASE_URL') baseUrl: string) {
    this.BaseUrl = baseUrl; }
  slug = this.router.url;
  ngOnInit() {
    this.api.getData().subscribe(data => {
      this.truckhireData  = data;
      this.title.setTitle(data['keepingitlocal']['SEO']['MetaTitle']);
      // this.meta.updateTag({ name: 'title', content: data['keepingitlocal']['SEO']['MetaTitle'] });
			this.meta.updateTag({ name: 'description', content: data['keepingitlocal']['SEO']['MetaDescription'] });
      this.meta.updateTag({ name: 'keywords', content: data['keepingitlocal']['SEO']['MetaKeywords'] });

      if(data['keepingitlocal']['keepItLocal'] != null || data['keepingitlocal']['keepItLocal'] != undefined){
        this.keepItLocal  = data['keepingitlocal']['keepItLocal'];
      }
      if(data['keepingitlocal']['LocationData'] != null || data['keepingitlocal']['LocationData'] != undefined){
        this.LocationData  = data['keepingitlocal']['LocationData'];
      }
      if(data['keepingitlocal']['StorageType'] != null || data['keepingitlocal']['StorageType'] != undefined){
        this.StorageType  = data['keepingitlocal']['StorageType'];
      }
      if(data['keepingitlocal']['Banner'] != null || data['keepingitlocal']['Banner'] != undefined){ 
        this.storageBannerData  = data['keepingitlocal']['Banner'];
        if(this.storageBannerData['image_name'] != undefined && this.storageBannerData['image_name'] != ''){
          this.storageBannerData  = this.storageBannerData['image_name'];
        }else{
          this.storageBannerData  = this.storageBannerData['default'];
        }
      }

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";

        BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"Keepin It Local","item":"' + location.href + '"}]}';
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
      });
    });
  }

}
