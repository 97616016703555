import { Component, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent {
// export class AppComponent implements AfterViewInit {
	title = 'hills-self-storage';

	// loading;
	constructor(private router: Router) {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		// this.loading = true; 
	}

	// ngAfterViewInit() {
	// 	this.router.events.subscribe((event) => {
	// 		if (event instanceof NavigationStart) {
	// 			this.loading = true;
	// 		} else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
	// 			setTimeout(() => {
	// 				this.loading = false;
	// 			}, 300);
	// 		}
	// 	});
	// }

	onActivate(event) {
		window.scroll(0, 0);
		//or document.body.scrollTop = 0;
		//or document.querySelector('body').scrollTo(0,0)
	}

}
