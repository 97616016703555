import { Component, OnInit, NgModuleFactoryLoader } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {
  cartData: any = Object;
  cartArr: string[];
  totalprice: any;
  GST: number;
  totalBill: string;
  cartArrlength: number;
  constructor(private api: ApiService, private router: Router, private meta: Meta, private title: Title) { }

  ngOnInit() {
    let form = { 'page_type': "cms_page", 'page_name': this.router.url };
    this.api.postData(form).subscribe(data => {
      this.title.setTitle(data['SEO']['MetaTitle']);
      // this.meta.updateTag({ name: 'title', content: data['meta_title']});
      this.meta.updateTag({ name: 'description', content: data['SEO']['MetaDescription'] });
      this.meta.updateTag({ name: 'keywords', content: data['SEO']['MetaKeywords'] });

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);
      });
    });

    this.cartData = localStorage.getItem('cart');
    if (this.cartData) {
      this.cartArr = JSON.parse(this.cartData);
      this.cartArrlength = this.cartArr.length;
      let totalprice = 0;
      this.cartArr.forEach((k1, v1) => {
        totalprice = (totalprice + parseFloat(k1['price']));
        this.totalprice = totalprice.toFixed(2);
      })

      // GST Amount = Original cost of the product or service - [Original cost of the product or service x {100 / (100 + GST%)}]
      // Net Price = Original cost of the product or service - GST amount 

      let gstAmount = totalprice - (totalprice * (100 / (100 + 10)));
      this.GST = gstAmount;
      // this.GST = (this.totalprice * 10) / 100;
    } else {
      this.cartArrlength = 0;
    }
  }
  deleteRow(cdid) {
    if (window.confirm("Do you really want to remove item?")) {

      let index = this.cartArr.map((i) => i['id']).indexOf(cdid);
      delete this.cartArr[index];
      let filtered = this.cartArr.filter(function (el) {
        return el != null;
      });
      this.cartArr = filtered;
      this.cartArrlength = this.cartArr.length;
      let totalprice = 0;
      filtered.forEach((k1, v1) => {
        totalprice = (totalprice + parseFloat(this.cartArr[v1]['price']));
      });

      // GST Amount = Original cost of the product or service - [Original cost of the product or service x {100 / (100 + GST%)}]
      // Net Price = Original cost of the product or service - GST amount 

      let gstAmount = totalprice - (totalprice * (100/(100 + 10)));
      this.GST = gstAmount;
      this.totalprice = totalprice.toFixed(2);
      localStorage.setItem('cart', JSON.stringify(filtered));
    }
  }
  updateCart() {
    let collection = document.querySelectorAll('.qty');
    let tprice;
    let unitprice;
    let totalprice = 0;
    collection.forEach((k1, v1) => {
      let qtydata = (<HTMLInputElement>document.getElementById(k1.getAttribute("data-cid"))).value;
      let index = this.cartArr.map((i) => i['id']).indexOf(k1.getAttribute("data-cid"));
      if (parseFloat(this.cartArr[index]['more_price']) != 0) {
        if (parseInt(qtydata) >= 1 && parseInt(qtydata) < parseInt(this.cartArr[index]['more_qty'])) {
          tprice = parseInt(qtydata) * parseFloat(this.cartArr[index]['unitpprice']);
          unitprice = parseFloat(this.cartArr[index]['unitpprice']);
        } else if (parseInt(qtydata) >= parseInt(this.cartArr[index]['more_qty'])) {
          tprice = parseInt(qtydata) * parseFloat(this.cartArr[index]['more_price']);
          unitprice = parseFloat(this.cartArr[index]['more_price']);
        } else {
          tprice = parseFloat(this.cartArr[index]['unitpprice']);
          unitprice = parseFloat(this.cartArr[index]['unitpprice']);
        }
      } else {
        if (parseInt(qtydata) >= 1) {
          tprice = parseInt(qtydata) * parseFloat(this.cartArr[index]['unitpprice']);
          unitprice = parseFloat(this.cartArr[index]['unitpprice']);
        } else {
          tprice = parseFloat(this.cartArr[index]['unitpprice']);
          unitprice = parseFloat(this.cartArr[index]['unitpprice']);
        }
      }
      this.cartArr[index]['qty'] = qtydata;
      this.cartArr[index]['price'] = tprice.toFixed(2);
      this.cartArr[index]['Newunitpprice'] = unitprice;

      totalprice = (totalprice + parseFloat(this.cartArr[index]['price']));
      this.totalprice = totalprice.toFixed(2);
    });

    // GST Amount = Original cost of the product or service - [Original cost of the product or service x {100 / (100 + GST%)}]
    // Net Price = Original cost of the product or service - GST amount 

    let gstAmount = totalprice - (totalprice * (100 / (100 + 10)));
    this.GST = gstAmount;
    this.cartArrlength = this.cartArr.length;
    localStorage.setItem('cart', JSON.stringify(this.cartArr));
    document.getElementById("updateCart").setAttribute("style", "display:block");
    setTimeout(() => {
      document.getElementById("updateCart").setAttribute("style", "display:none");
    }, 5000);
  }

  orderNow() {
    this.updateCart();
    localStorage.setItem('cart', JSON.stringify(this.cartArr));
    this.router.navigateByUrl('/order-enquiry');
  }

  plus(pid) {
    let count = 1;
    let countEl = (<HTMLInputElement>document.getElementById(pid)).value;
    let inccount = parseInt(countEl) + count;
    (<HTMLInputElement>document.getElementById(pid)).value = String(inccount);
  }
  minus(pid) {
    let count = 1;
    let countEl = (<HTMLInputElement>document.getElementById(pid)).value;
    if (parseInt(countEl) > 1) {
      (<HTMLInputElement>document.getElementById(pid)).value = String(parseInt(countEl) - count);
    }
  }
}
