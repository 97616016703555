import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  formData: any = Object;
  pageDetails: string[];
  storageBannerData: any;
  FindstorageTypeData: string[];
  LocationbannerFrm: string[];
  url: string;
  BaseUrl:string;
  constructor(private api: ApiService, private router: Router, private meta: Meta, private title: Title, @Inject('BASE_URL') baseUrl: string) {
    this.BaseUrl = baseUrl; }
  slug = this.router.url;
  ngOnInit() {
    this.api.getData().subscribe(data => {
      this.formData  = data;
      this.title.setTitle(data['videos']['SEO']['MetaTitle']);
      // this.meta.updateTag({ name: 'title', content: data['videos']['SEO']['MetaTitle'] });
			this.meta.updateTag({ name: 'description', content: data['videos']['SEO']['MetaDescription'] });
      this.meta.updateTag({ name: 'keywords', content: data['videos']['SEO']['MetaKeywords'] });

      if(data['videos']['video'] != null || data['videos']['video'] != undefined){
        this.pageDetails  = data['videos']['video'] as string[];
      }
      if(data['videos']['Banner'] != null || data['videos']['Banner'] != undefined){
        this.storageBannerData  = data['videos']['Banner'];
        if(this.storageBannerData['image_name'] != undefined && this.storageBannerData['image_name'] != ''){
          this.storageBannerData  = this.storageBannerData['image_name'];
        }else{
            this.storageBannerData  = this.storageBannerData['default'];
        }
      }

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";

        BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"Videos","item":"' + location.href + '"}]}';
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
      });
    });
  }
  StorageDataChange(event, formname) {    
    let text = event.target.options[event.target.options.selectedIndex].text;
    event.target.parentNode.firstChild.textContent = text;
    event.target.parentNode.parentNode.nextSibling.firstChild.firstChild.textContent = "Select Storage location";

    let newIndex = [];
    this.FindstorageTypeData = this.formData['forms']['StorageType'];
    let dat = this.FindstorageTypeData.filter(x => x['slug'] === event.target.value);
    if (dat[0]['loc_name'] != null) {
      if (dat[0]['loc_name'].indexOf(',') > -1) {
        let Ldata = dat[0]['loc_name'].split(',');
        Ldata.forEach((k1, v1) => {
          newIndex[v1] = k1.split('@');
        });
        this.LocationbannerFrm = newIndex;
      } else {
        this.LocationbannerFrm = [dat[0]['loc_name'].split('@')];
      }
    }
  }
}
