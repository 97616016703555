import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-self-storage-location',
  templateUrl: './self-storage-location.component.html',
  styleUrls: ['./self-storage-location.component.css']
})
export class SelfStorageLocationComponent implements OnInit {

  defaultImage = 'assets/images/default-banner.jpg';
  defaultImg = 'assets/images/banner-img.jpg';
  loadingImg = 'assets/images/loading.gif';
  offset = 1000;
  storageError: boolean = false;
  locationError: boolean = false;
  selfstoragelocationData: any = Object;
  Testimonial: string[];
  adddataparams: any = Object;
  Testimoniallng:any;
  LocationData: any = Object;
  FindstorageTypeData: string[];
  LocationbannerFrm: string[];
  stypeArr: string[];
  detailAdditionImage: string[];
  DdOffers:any = Object;
  feaArr: string[];
  url: string[];
  imageArrlength: number;
  feaArrlength: number;
  stypeArrLength: number;
  displayError: any = Object;
  private isVisible: boolean;
  private bannerformSumitAttempt: boolean;
  bannerFrm: FormGroup;
  advance_information: string[];
  OfferBanner: string[];
  bannerImages: any;
  bannerMobileImages: any;
  LocationDataName: any;
  locspec: any;
  lat: number;
  lng: number;
  urldata: any;
  BaseUrl: any;
  genReviewCnt: any = 0;
  getnRatingCnt: any = 0;
  
  /* Start - Below script inserted for the Google reviews*/
	GoogleReviews: string[];
	showShortDesciption: boolean = true;
	selectedText: string = '';
	numSequence(n: number): Array<number> {
		return Array(Math.floor(n));
	  }
	alterdescText(text){
		console.log(text);
		this.selectedText = text;
		//return this.showShortDesciption = !this.showShortDesciption;
	}
	/* End - Below script inserted for the Google reviews*/
	
  constructor(private api: ApiService, private router: Router, private formBuilder: FormBuilder, private meta: Meta, private title: Title, @Inject('BASE_URL') baseUrl: string){
    this.BaseUrl = baseUrl; 
    this.loadScript('https://api.feefo.com/api/javascript/hills-self-storage');
  }

  ngOnInit() {
    this.urldata = this.router.url.split('/')[2];
    if (this.urldata == 'kings-park') {
      this.router.navigateByUrl('/404', {skipLocationChange: true});
    }
    this.api.getData().subscribe(data => {
      this.selfstoragelocationData = data;
      this.title.setTitle(data['selfstoragelocation']['LocationData']['meta_title']);
      // this.meta.updateTag({ name: 'title', content: data['selfstoragelocation']['LocationData']['meta_title'] });
      this.meta.updateTag({ name: 'description', content: data['selfstoragelocation']['LocationData']['meta_description'] });
      this.meta.updateTag({ name: 'keywords', content: data['selfstoragelocation']['LocationData']['meta_keyword'] });

      if (data['selfstoragelocation']['StorageType'] != null || data['selfstoragelocation']['StorageType'] != undefined) {
        this.FindstorageTypeData = data['selfstoragelocation']['StorageType'] as string[];
      }
      if (data['selfstoragelocation']['OfferBanner'] != null || data['selfstoragelocation']['OfferBanner'] != undefined) {
				this.OfferBanner = data['selfstoragelocation']['OfferBanner'] as string[];
			}
      this.locspec = data['selfstoragelocation']['LocationDataspec'];

      if (data['selfstoragelocation']['DdOffers'] != null || data['selfstoragelocation']['DdOffers'] != undefined) {
        this.DdOffers = data['selfstoragelocation']['DdOffers'];
      }

      let ddOfferKeys = Object.keys(this.DdOffers);
      if (this.DdOffers[ddOfferKeys[0]] != undefined){
        let letLocatiosOffer = this.DdOffers[ddOfferKeys[0]]['location_ids'].split(',');
        let locspec = this.locspec;
        locspec.forEach((k1,v1) => {
          if (letLocatiosOffer.indexOf(k1['id']) > -1) {
            if (this.DdOffers[ddOfferKeys[0]]['offer_text'].length > 0){
              this.locspec[v1]['offer'] = this.DdOffers[ddOfferKeys[0]]['offer_text'];
            }else{
              this.locspec[v1]['offer'] = "";
            }
          }else{
            this.locspec[v1]['offer'] = "";
          }
        });
      }
      
      if (data['selfstoragelocation']['LocationData'] != null || data['selfstoragelocation']['LocationData'] != undefined) {
        this.LocationData = data['selfstoragelocation']['LocationData'] as string[];
        this.LocationData.phone = this.LocationData.phone.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3");
        this.LocationData.fax = this.LocationData.fax.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3");
        
        this.bannerImages = JSON.parse(this.LocationData['banner']);
        if (this.bannerImages.length == 0){
          this.bannerImages = [{ 'name': this.defaultImg, 'alt': this.LocationData['name'], 'title': this.LocationData['name']}];
        }
        this.bannerMobileImages = JSON.parse(this.LocationData['mobile_banner_image'])[0];
				if (this.bannerMobileImages.length == 0) {
					this.bannerMobileImages = [{ 'name': this.defaultImg, 'alt': this.LocationData['headerTitle'], 'title': this.LocationData['headerTitle'] }];
				}
        this.LocationDataName = this.LocationData['name'];
        this.detailAdditionImage = JSON.parse(this.LocationData['images']);
        this.imageArrlength = this.detailAdditionImage.length;

        let newIndex = [];
        let Ldata = [];
        let dataArr = this.FindstorageTypeData;
        dataArr.forEach((k2, v2) => {
          Ldata[v2] = k2['loc_name'].includes(this.LocationData['slug']);
          if (Ldata[v2] === true) {
            newIndex = newIndex.concat(k2);
          }
        });
        this.stypeArr = newIndex;
        this.stypeArrLength = this.stypeArr.length;
        let Ldatas: any = [];
        let featurData = this.LocationData['facilities'];
        if (featurData.indexOf('\r\n') > -1) {
          Ldatas = featurData.split('\r\n');
        }
        this.feaArr = Ldatas;
        this.feaArrlength = this.feaArr.length;

        this.advance_information = JSON.parse(this.LocationData['advance_information']);
        this.lat = parseFloat(this.LocationData['latitude']);
        this.lng = parseFloat(this.LocationData['longitude']);
      }
      if (data['selfstoragelocation']['Testimonial'] != null || data['selfstoragelocation']['Testimonial'] != undefined) {
        this.Testimonial = data['selfstoragelocation']['Testimonial'] as string[];
        this.Testimoniallng = this.Testimonial.length;
      }

      // console.log(this.Testimonial)
      // let html = '<iframe src="' + this.LocationData['map_embedded'] + '" width="100%" height="440" frameborder="0" style="border:0" allowfullscreen loading="lazy"> </iframe>';
      // document.getElementById('locationMap').innerHTML = html;

      // this.LocationData['advance_information'] = JSON.parse(this.LocationData['advance_information']);
      // let addcond;
      // this.LocationData['advance_information'].forEach((k2, v2) => {
      //   if(k2['advance_condition'] == "Video"){
      //     addcond = JSON.parse(k2['advance_videos']);
      //     this.url = addcond['video_url'];
      //   }
      //   this.adddataparams = addcond
      // });
	  //console.log(data);
		/* Start - Below script inserted for the Google reviews*/
			if (data['selfstoragelocation']['SEO']['googlereview'] != null || data['selfstoragelocation']['SEO']['googlereview'] != undefined) {
				var reivewjsonData = JSON.parse(data['selfstoragelocation']['SEO']['googlereview']);
				this.genReviewCnt = reivewjsonData.loctotReview;
				this.getnRatingCnt = reivewjsonData.locRatings;
				//console.log("=========="+reivewjsonData.loctotReview);
				reivewjsonData = JSON.parse(reivewjsonData.generalReviews);
				
				//console.log(this.WhyUs.service);
				
				this.GoogleReviews = reivewjsonData as string[];
			}
		/* End - Below script inserted for the Google reviews*/

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"Self Storage Location","item":"' + this.BaseUrl + 'self-storage-location"},{"@type":"ListItem","position":3,"name":"' + this.LocationDataName  + '","item":"' + location.href + '"}]}';
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
      });
    });

    this.bannerFrm = this.formBuilder.group({
      storage: [null, [Validators.required]],
      location: [null, [Validators.required]],
    });

    if (!this.bannerImages) {
      document.getElementById("InnerBanner").innerHTML = '';
      document.getElementById("InnerBanner").classList.remove('mainBannerSec');
    }
  }

  public loadScript(url) {
		let node = document.createElement('script');
		node.src = url;
		node.type = 'text/javascript';
		document.getElementsByTagName('head')[0].appendChild(node);
  }
   
  OfferOnLoad(id) {
    let ddOfferKeys = Object.keys(this.DdOffers);
    if (this.DdOffers[ddOfferKeys[0]] != undefined){
      let letLocatiosOffer = this.DdOffers[ddOfferKeys[0]]['location_ids'].split(',');
      if (letLocatiosOffer.indexOf(id) > -1) {
        if (this.DdOffers[ddOfferKeys[0]]['offer_text'].length > 0){
          document.querySelector('.locationSelect').setAttribute("class","form-group locationSelect");
          document.getElementById('offerText').innerHTML = "<span>&#9733;</span>" + this.DdOffers[ddOfferKeys[0]]['offer_text'] +"<span>&#9733;</span>";
        }
      } else {
        document.querySelector('.locationSelect').setAttribute("class","form-group locationSelect offerNotSelected");
        document.getElementById('offerText').innerHTML = "";
      }
    }
  }
  
  redirectUrl(){
    let elocation = (document.getElementById("location")) as HTMLSelectElement;
    let locationsel = elocation.selectedIndex;
    let locationopt = elocation.options[locationsel];
    if (locationopt == undefined){
      this.locationError = true;
      document.querySelector(".locationSelect .error-msg").setAttribute("style", "display:block");
    } else if (locationopt.value == ''){
      this.locationError = true;
      document.querySelector(".locationSelect .error-msg").setAttribute("style", "display:block");
    }else{
      this.locationError = false; 
    }

    if (this.locationError == false){
      let baseUrl = document.getElementsByTagName('base')[0].href;
      let urlData = baseUrl +'self-storage-location/' + locationopt.value;
      window.location.replace(urlData);
    }
  }

  scroll() {
    document.querySelector('#getQuote').scrollIntoView({ behavior: 'smooth' });
  }

  isBannerFieldValid(field: string) {
    return (
      (!this.bannerFrm.get(field).valid && this.bannerFrm.get(field).touched) ||
      (this.bannerFrm.get(field).untouched && this.bannerformSumitAttempt)
    );
  }

  onSearch() {
    let estorage = (document.getElementById("storage")) as HTMLSelectElement;
    let storagesel = estorage.selectedIndex;
    let storageopt = estorage.options[storagesel];
    if (storageopt == undefined) {
      this.storageError = true;
    } else if (storageopt.value == "") {
      this.storageError = true;
    } else {
      this.storageError = false;
    }

    let elocation = (document.getElementById("location")) as HTMLSelectElement;
    let locationsel = elocation.selectedIndex;
    let locationopt = elocation.options[locationsel];
    let locationValue;
    if (locationopt == undefined) {
      this.locationError = true;
      document.querySelector(".locationSelect .error-msg").setAttribute("style", "display:block;");
    } else if (locationopt.value == "") {
      this.locationError = true;
      document.querySelector(".locationSelect .error-msg").setAttribute("style", "display:block");
    } else {
      this.locationError = false;
      document.querySelector('.selectlocation').textContent = locationopt.text;
      locationValue = (<HTMLOptionElement>locationopt).value;
    }
    if (this.locationError == false && this.storageError == false) {
      let urlData = '/self-storage-price-list/' + storageopt.value + '/' + locationValue;
      this.router.navigateByUrl(urlData);
    }
  }
  StorageDataChange(event, formname) {
    let text = event.target.options[event.target.options.selectedIndex].text;
    event.target.parentNode.firstChild.textContent = text;
    event.target.parentNode.parentNode.nextSibling.firstChild.firstChild.textContent = "Select Storage location";

    if (text == "Select Storage") {
      this.storageError = true;
      // this.locationError = true;
    } else {
      this.storageError = false;
      // this.locationError = true;
    }

    let html = '';
    let newIndex = [];
    this.FindstorageTypeData = this.selfstoragelocationData['selfstoragelocation']['StorageType'];
    let dat = this.FindstorageTypeData.filter(x => x['slug'] === event.target.value);
    if (dat[0]['loc_name'] != null) {
      if (dat[0]['loc_name'].indexOf(',') > -1) {
        let Ldata = dat[0]['loc_name'].split(',');
        html += '<span class="spanOut selectlocation" id="locationSpan">Select Storage location</span>\
					<select onchange="setSpanValue(this)" formControlName="location" id="location" title="Select Location"><option value=""> Select Storage location </option>';
        Ldata.forEach((k1, v1) => {
          newIndex[v1] = k1.split('@');
          html += '<option value="' + k1.split('@')[2] + '" id="' + k1.split('@')[2] + '">' + k1.split('@')[0] + '</option>';
        });
        html += '</select>';
        document.getElementById('locationDiv').innerHTML = html;
        // Ldata.forEach((k1, v1) => {
        //   newIndex[v1] = k1.split('@');
        // });
        // this.LocationbannerFrm = newIndex;
      } else {
        // this.LocationbannerFrm = [dat[0]['loc_name'].split('@')];
        html += '<span class="spanOut selectlocation" id="locationSpan">Select Storage location</span>\
					<select onchange="setSpanValue(this)" formControlName="location" id="location" title="Select Location"><option value=""> Select Storage location </option>';
        html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" id="' + dat[0]['loc_name'].split('@')[2] + '">' + dat[0]['loc_name'].split('@')[0] + '</option>';
        html += '</select>';
        document.getElementById('locationDiv').innerHTML = html;
      }
    }else{
      html += '<span class="spanOut selectlocation" id="locationSpan">Select Storage location</span>\
					<select onchange="setSpanValue(this)" formControlName="location" id="location" title="Select Location"><option value=""> Select Storage location </option>';
      html += '</select>';
      document.getElementById('locationDiv').innerHTML = html;
    }
  }

  setSpanValue(event) {
    let text = event.target.options[event.target.options.selectedIndex].text;
    event.target.parentNode.firstChild.textContent = text;
  }

  //slides = this.Banner;
  slideConfig = { infinite: true, slidesToShow: 1, slidesToScroll: 1, arrows: false, speed: 2000, dots: true, };
  offerSlideConfig = { infinite: true, slidesToShow: 1, slidesToScroll: 1, arrows: true, autoplaySpeed: 6000, dots: false, autoplay: true,speed:2000, pauseOnHover:true,};
  testiSlideConfig = {
		infinite: true, slidesToShow: 5, slidesToScroll: 5, arrows: false, speed: 2000, dots: true,
		responsive: [
		{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
					arrows: false
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
					arrows: false
				}
			},
			{
				breakpoint: 566,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
					arrows: false
				}
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		]

	};

  storageSlideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 1500,
  };

  slickInit(e) { }

  searchPageLink(styepData) {
    var urlData = '/self-storage-price-list/' + styepData + '/' + this.LocationData['slug'];
    this.router.navigateByUrl(urlData);
  }
}
