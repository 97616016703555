import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { templateJitUrl } from '@angular/compiler';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cmspage',
  templateUrl: './cmspage.component.html',
  styleUrls: ['./cmspage.component.css']
})
export class CmspageComponent implements OnInit {

  defaultImage = 'assets/images/banner-img-lazy.jpg';
  loadingImg = 'assets/images/loading.gif';
  offset = 1000;

  pageName: any;
  pagehead: any;
  data: any;
  description: any;
  image_title: any = null;
  image_position: any;
  images: any;
  videos: any;
  videoUrl: any;
  videoPosition: any;
  feature: any;
  feature_title: any;
  templateNumber: any;
  Locations: any;
  banner: any;
  BaseUrl: string;
  internal_pages: string[];
  category_location :any;
  constructor(@Inject('BASE_URL') baseUrl: string, private api: ApiService, private router: Router, private meta: Meta, private title: Title) {
    this.BaseUrl = baseUrl;
  }
  slug = this.router.url;
  ngOnInit() {
    let form = { "page_type": "cms_page", "page_name": this.router.url };
    this.api.postData(form).subscribe(data => {

      this.title.setTitle(data['meta_title']);
      // this.meta.updateTag({ name: 'title', content: data['meta_title']});
      this.meta.updateTag({ name: 'description', content: data['meta_description'] });
      this.meta.updateTag({ name: 'keywords', content: data['meta_keyword'] });

      if (data['template'] == undefined) {
        this.router.navigateByUrl('/404', {skipLocationChange: true});
      }
      if (data['Banner']['image_name'] != undefined && data['Banner']['image_name'] != '') {
        this.banner = data['Banner']['image_name'];
      } else {
        this.banner = data['Banner']['default'];
      }

      this.pageName = data['name'];
      this.pagehead = data['navi_title'];
      this.description = data['description'];
      if (data['slider_images'] != undefined && data['slider_images'] != "") {
        let silderData = JSON.parse(data['slider_images']);
        this.image_position = silderData['image_position'];
        this.image_title = silderData['image_title'];
        this.images = silderData['img'];
      }

      if (data['videos'] != undefined && data['videos'] != "") {
        this.videos = JSON.parse(data['videos']);
        this.videoUrl = this.videos['url'];
        this.videoPosition = this.videos['position'];
      }

      if (data['location'] != undefined) {
        let newIndex = [];
        let checkImage: any = data['location'];
        checkImage.forEach((k1, v1) => {
          newIndex[v1] = k1;
          newIndex[v1]['image'] = JSON.parse(newIndex[v1]['image']);
        });
        this.Locations = newIndex;
      }

      if(data['category_location'] != null){
        this.category_location = data['category_location'];
      }
      if(data['internal_pages'] != null){
        this.internal_pages = data['internal_pages'];
      }

      this.feature_title = data['feature_title'];
      let Ldatas: any = [];
      let featurData = data['feature'];
      if (featurData != '' && featurData != null && featurData.indexOf('\r\n') >= -1) {
        Ldatas = featurData.split('\r\n');
      }
      this.feature = Ldatas;
      this.templateNumber = data['template'];

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";

        BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"' + this.pageName + '","item":"' + location.href + '"}]}';
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
      });

    });
  }
  slideConfig = { infinite: true, slidesToShow: 1, autoplay: true,  slidesToScroll: 1, arrows: false, speed: 2000, dots: true, initialSlide: 0 };
  slickInit(e) { }
}