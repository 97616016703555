import { Component, OnInit ,Inject} from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-order-enquiry',
  templateUrl: './order-enquiry.component.html',
  styleUrls: ['./order-enquiry.component.css']
})
export class OrderEnquiryComponent implements OnInit {
  statedd: string[];
  cartData: any = Object;
  cartArr:string[];
  totalprice:any;
  GST:any;
  totalBill: string;
  myRecaptcha: boolean;
  form: FormGroup;
  displayError: any = Object;
  minDate: Date;
  private formSumitAttempt: boolean;
  BaseUrl:string;
  constructor(private api: ApiService, private router: Router,private formBuilder: FormBuilder,public datepipe: DatePipe,private meta:Meta,private title:Title, @Inject('BASE_URL') baseUrl: string) {
    this.BaseUrl = baseUrl; 
  }
  data:any;
  ngOnInit() {
    this.cartData = localStorage.getItem('cart');
    if (this.cartData == null){
      this.router.navigateByUrl('/shopping-cart');
    } 
    this.cartArr = JSON.parse(this.cartData);
    if(this.cartArr.length == 0){
      this.router.navigateByUrl('/shopping-cart');
    }else{
    let form = { "page_type": "cms_page", "page_name": this.router.url };
    this.api.postData(form).subscribe(data => {
      this.title.setTitle(data['SEO']['MetaTitle']);
      // this.meta.updateTag({ name: 'title', content: data['meta_title']});
      this.meta.updateTag({ name: 'description', content: data['SEO']['MetaDescription'] });
      this.meta.updateTag({ name: 'keywords', content: data['SEO']['MetaKeywords'] });
      this.statedd = data['statedd'] as string[];

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);
      });
    });
    let totalprice = 0;
    this.cartArr.forEach((k1, v1) => {
      totalprice = (totalprice + parseFloat(k1['price']));
      this.totalprice = totalprice.toFixed(2);
    });

      // GST Amount = Original cost of the product or service - [Original cost of the product or service x {100 / (100 + GST%)}]
      // Net Price = Original cost of the product or service - GST amount 

      let gstAmount = totalprice - (totalprice * (100 / (100 + 10)));
      this.GST = gstAmount;
    // this.GST = this.totalprice * 10 / 100;

    this.form = this.formBuilder.group({
      customer_name: [null, Validators.required],
      customer_phone: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      pickup_date: [null, [Validators.required]],
      billing_postcode: [null],
      billing_street: [null],
      enqmessage: [null],
      company_name: [null],
      billing_suburb: [null],
      cart: [null],
      billing_state: [null],
      location: [null, [Validators.required]],
      form_title :['cart_order_enquiry'],
      recaptcha:[false, [Validators.required]]
    });

    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    }

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onScriptLoad() {}

  onScriptError() {}

  isFieldValid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSumitAttempt)
    );
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  onSubmit() {
    this.formSumitAttempt = true;
    if (this.form.valid) {
      document.getElementById('step2Loading').setAttribute('style', "display:block;");
      document.getElementById('orderEnquirySubmit').setAttribute('style',"pointer-events:none;");
      this.form.value.pickup_date = this.datepipe.transform(this.form.value.move_in_date, 'yyyy-MM-dd');
      this.form.controls['cart'].setValue(this.cartData);
      this.api.postData(this.form.value).subscribe(data=>{
        if(data['status'] == 200 && data['EmailMessage'] == "Email Sent."){
          // this.router.navigateByUrl('/'+data['thankyou']);
          let url = data['thankyou'];
					window.location.href=url;
        }else{
          document.getElementById('orderEnquirySubmit').setAttribute('style', "pointer-events:all;");
          document.getElementById('errorMsg').setAttribute("style","display:block");
          (<HTMLInputElement>document.getElementById('errorMsg')).textContent = data['EmailMessage'];
        }
      });
    }
  }
}
