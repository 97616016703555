import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component'; 
import { ThankYouComponent } from './thank-you/thank-you.component';
import { StorageOptionsComponent } from './storage-options/storage-options.component';
import { SelfStoragePriceListComponent } from './self-storage-price-list/self-storage-price-list.component';
import { SelfStorageComponent } from './self-storage/self-storage.component';
import { PricelistQuoteThankyouComponent } from './pricelist-quote-thankyou/pricelist-quote-thankyou.component';
import { SelfStorageLocationComponent } from './self-storage-location/self-storage-location.component';
import { SelfStorageLocationsComponent } from './self-storage-locations/self-storage-locations.component';
import { GetaquoteComponent } from './getaquote/getaquote.component';
import { BoxesAndSuppliesComponent } from './boxes-and-supplies/boxes-and-supplies.component';
import { TruckHireComponent } from './truck-hire/truck-hire.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { OrderEnquiryComponent } from './order-enquiry/order-enquiry.component';
import { CmspageComponent } from './cmspage/cmspage.component';
import { FormsComponent } from './forms/forms.component';
import { VideosComponent } from './videos/videos.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OfficeSpaceForRentComponent } from './office-space-for-rent/office-space-for-rent.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { PayMyAccountLocationComponent } from './pay-my-account-location/pay-my-account-location.component';
import { PayMyAccountComponent } from './pay-my-account/pay-my-account.component';
import { KeepingItLocalComponent } from './keeping-it-local/keeping-it-local.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProposalPricelistComponent } from './proposal-pricelist/proposal-pricelist.component';
import { CalcumateComponent } from "./calcumate/calcumate.component";
import { CalcumateGetAQuoteComponent } from './calcumate-get-a-quote/calcumate-get-a-quote.component';
import { SearchOptionsComponent } from './search-options/search-options.component';
import { InstantQuoteComponent } from './instant-quote/instant-quote.component';

const routes: Routes = [
  {path:'', component: HomepageComponent},
  {path:'storage-options/:stype',component:StorageOptionsComponent},
  {path:'truck-hire',component:TruckHireComponent},
  {path:'faqs',component:FaqsComponent},
  {path:'boxes-and-supplies',component:BoxesAndSuppliesComponent},
  {path:'boxes-and-supplies/:btype',component:BoxesAndSuppliesComponent},
  {path:'pricelist-quote-thankyou',component:PricelistQuoteThankyouComponent},
  {path:'pricelist-quote-thankyou/:type',component:PricelistQuoteThankyouComponent},
  {path:'book-now-thankyou',component:PricelistQuoteThankyouComponent},
  {path:'book-now-thankyou/:type',component:PricelistQuoteThankyouComponent},
  {path:'self-storage-location/:location',component:SelfStorageLocationComponent},
  {path:'self-storage-locations',component:SelfStorageLocationsComponent},
  // {path:'self-storage/:stype',component:SelfStorageComponent},
  {path:'self-storage',component:SelfStorageComponent},
  {path:'self-storage/:location/:stype',component:SelfStorageComponent},
  {path:'proposal-pricelist/:userid/:timstamp',component:ProposalPricelistComponent},
  {path:'#getQuote', component: GetaquoteComponent}, 
  {path:'#searchForm', component: SearchOptionsComponent}, 
  { path: 'calcumate-get-a-quote', component: CalcumateGetAQuoteComponent}, 
  {path:'space-calculate', component: CalcumateComponent}, 
  {path:'self-storage-price-list/:stype/:location',component:SelfStoragePriceListComponent},
  {path:'self-storage-price-list/:stype/:location/:chatteam',component:SelfStoragePriceListComponent},
  {path:'contact-thank-you',component:ThankYouComponent}, 
  {path:'truckhire-thank-you',component:ThankYouComponent}, 
  {path:'order-thank-you',component:ThankYouComponent}, 
  {path:'quote-thank-you',component:ThankYouComponent}, 
  {path:'calcumate-thank-you',component:ThankYouComponent}, 
  {path:'office-space-thank-you',component:ThankYouComponent},
  {path:'thank-you',component:ThankYouComponent},
  {path:'quote-thank-you',component:ThankYouComponent},
  {path:'online-move-in-thank-you',component:ThankYouComponent},
  {path:'shopping-cart',component:ShoppingCartComponent}, 
  {path:'order-enquiry',component:OrderEnquiryComponent},   
  {path:'forms',component:FormsComponent},
  {path:'videos',component:VideosComponent},
  {path:'contact-us',component:ContactUsComponent},
  {path:'about-us',component:AboutUsComponent},
  {path:'office-space-for-rent',component:OfficeSpaceForRentComponent},
  {path:'testimonials',component:TestimonialsComponent},
  {path:'404',component:PageNotFoundComponent},
  {path:'pay-my-account-location/:stype',component:PayMyAccountLocationComponent},
  {path:'pay-my-account',component:PayMyAccountComponent},
  {path:'keeping-it-local',component:KeepingItLocalComponent},
  {path:'sitemap',component:SitemapComponent},
  {path:'get-a-quote',component:InstantQuoteComponent},
  {path:'**',component:CmspageComponent}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
