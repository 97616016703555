import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  searchData: any = Object;
  aboutus: string[];
  AllFaqsCategory:string[];
  FindstorageTypeData: string[];
  LocationbannerFrm: string[];
  LocationConfigData: any = Object;
  pageDetails: any = Object;
  storageBannerData: any;
  arr: string[];
  BaseUrl:string;
  constructor(@Inject('BASE_URL') baseUrl: string,private api: ApiService, private router: Router, private meta: Meta, private Maintitle: Title){
    this.BaseUrl = baseUrl;
  }
  slug = this.router.url;
  ngOnInit(){
    this.api.getData().subscribe(data => {
      this.searchData = data;
      
      this.Maintitle.setTitle(data['aboutus']['pageDetails']['meta_title']);
      this.meta.updateTag({ name: 'description', content: data['aboutus']['pageDetails']['meta_description'] });
      this.meta.updateTag({ name: 'keywords', content: data['aboutus']['pageDetails']['meta_keyword'] });

      if(data['aboutus'] != null || data['aboutus'] != undefined){
        this.aboutus = data['aboutus'];
      }
      if(data['aboutus']['LocationData'] != null || data['aboutus']['LocationData'] != undefined){
        // this.AllFaqsCategory = data['aboutus']['LocationData'];
          let newIndex = [];
          let checkImage: any = data['aboutus']['LocationData'];
          checkImage.forEach((k1, v1) => {
            newIndex[v1] = k1;
            newIndex[v1]['image'] = JSON.parse(newIndex[v1]['image']);
          });
          this.AllFaqsCategory = newIndex;          
      }

      if(data['aboutus']['pageDetails'] != null || data['aboutus']['pageDetails'] != undefined){
        this.pageDetails = data['aboutus']['pageDetails'];
      }
      if(data['aboutus']['Banner'] != null || data['aboutus']['Banner'] != undefined){
        if(data['aboutus']['Banner']['image_name'] != undefined && data['aboutus']['Banner']['image_name'] != ''){
          this.storageBannerData  = data['aboutus']['Banner']['image_name'];
        }else{
          this.storageBannerData  = data['aboutus']['Banner']['default'];
        }
      }

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);
        
        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        
        BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"About us","item":"' + location.href +'"}]}';
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
        
      });
    });
  }
  gotolink(locData){
    var urlData = '/self-storage-location/'+locData;
    this.router.navigateByUrl(urlData);
  }
  StorageDataChange(event, formname) {    
    let text = event.target.options[event.target.options.selectedIndex].text;
    event.target.parentNode.firstChild.textContent = text;
    event.target.parentNode.parentNode.nextSibling.firstChild.firstChild.textContent = "Select Storage location";

    let newIndex = [];
    this.FindstorageTypeData = this.searchData['selfstoragelocations']['StorageType'];
    let dat = this.FindstorageTypeData.filter(x => x['slug'] === event.target.value);
    if (dat[0]['loc_name'] != null) {
      if (dat[0]['loc_name'].indexOf(',') > -1) {
        let Ldata = dat[0]['loc_name'].split(',');
        Ldata.forEach((k1, v1) => {
          newIndex[v1] = k1.split('@');
        });
        this.LocationbannerFrm = newIndex;
      } else {
        this.LocationbannerFrm = [dat[0]['loc_name'].split('@')];
      }
    }
  }

}
