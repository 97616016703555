import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
// import { customJs } from "../../assets/js/custom.js";

@Component({
  selector: 'app-truck-hire',
  templateUrl: './truck-hire.component.html',
  styleUrls: ['./truck-hire.component.css']
})
export class TruckHireComponent implements OnInit {
  truckhireData: any = Object;
  pageDetails: any = Object;

  PickupDateError: boolean = false;
  DropoffDateError: boolean = false;

  storageBannerData: any;
  locationValue: any;
  todayDate: any;
  myRecaptcha: boolean;
  form: FormGroup;
  displayError: any = Object;
  private formSumitAttempt: boolean;
  defaultImage = 'assets/images/banner-img-lazy.jpg';
  loadingImg = 'assets/images/loading.gif';
  offset = 1000;
  maxDate: Date;
  tileClasstruefalse = {
    "iframePopup": true,
    "openIframePopup": false
  }
  BaseUrl: string;
  constructor(private api: ApiService, private router: Router, private formBuilder: FormBuilder, public datepipe: DatePipe, private meta: Meta, private title: Title, @Inject('BASE_URL') baseUrl: string) {
    this.BaseUrl = baseUrl;
    this.todayDate = new Date()
    this.todayDate = this.datepipe.transform(this.todayDate, 'yyyy-MM-dd hh:mm');
  }
  slug = this.router.url;
  data: any;
  $: any;
  popup() {
    this.tileClasstruefalse.openIframePopup = true;
    document.getElementById('video').setAttribute('src', "https://www.youtube.com/embed/wYgBQxpwEAo");
  }
  closepop() {
    this.tileClasstruefalse.openIframePopup = false;
  }

  ngOnInit() {
    this.api.getData().subscribe(data => {
      this.truckhireData = data;
      this.title.setTitle(data['truckhire']['pageDetails']['meta_title']);
      // this.meta.updateTag({ name: 'title', content: data['truckhire']['SEO']['MetaTitle'] });
      this.meta.updateTag({ name: 'description', content: data['truckhire']['pageDetails']['meta_description'] });
      this.meta.updateTag({ name: 'keywords', content: data['truckhire']['pageDetails']['meta_keyword'] });

      if (data['truckhire']['pageDetails'] != null || data['truckhire']['pageDetails'] != undefined) {
        this.pageDetails = data['truckhire']['pageDetails'];
        this.locationValue = data['truckhire']['pageDetails']['locations'];
      }
      if (data['truckhire']['Banner'] != null || data['truckhire']['Banner'] != undefined) {
        this.storageBannerData = data['truckhire']['Banner'];
        if (this.storageBannerData['image_name'] != undefined && this.storageBannerData['image_name'] != '') {
          this.storageBannerData = this.storageBannerData['image_name'];
        } else {
          this.storageBannerData = this.storageBannerData['default'];
        }
      }
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate());

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";

        BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"Truck Hire","item":"' + location.href + '"}]}';
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
      });
    });

    this.form = this.formBuilder.group({
      fname: [null, Validators.required],
      lname: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      DOB: [null],
      Validhire: ['Bhrs', Validators.required],
      Validage: [true],
      contact_number: [null, [Validators.required]],
      PickupDate: [''],
      DropoffDate: [''],
      ampm_pickup:['AM'],
      ampm_dropoff:['AM'],
      storage_type: ['Personal'],
      location: [null, [Validators.required]],
      form_title: ['book_your_truck_now'],
      additional_info: [''],
      recaptcha: [false, [Validators.required]]
    });
    this.loadScript('assets/js/dateformPicker.js');
    this.rescaleCaptcha();
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  rescaleCaptcha() {
    document.querySelectorAll('.angular-google-recaptcha-container').forEach(function () {
      const width = document.querySelector('.captchaDiv').clientWidth;
      let scale;
      if (width < 302) {
        scale = width / 302;
      } else {
        scale = 1.0;
      }
      document.querySelector('.angular-google-recaptcha-container').setAttribute('style', 'transform:scale(' + scale + ');-webkit-transform:scale(' + scale + ');transform-origin:0 0;-webkit-transform-origin:0 0;');
    });
  }

  onScriptLoad() { }

  onScriptError() { }

  isFieldValid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSumitAttempt)
    );
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  convert24Time(time){
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
  }

  onSubmit() {
    this.formSumitAttempt = true;
    this.form.value.DropoffDate = (<HTMLInputElement>document.getElementById('DropoffDate')).value;
    this.form.value.PickupDate = (<HTMLInputElement>document.getElementById('PickupDate')).value;
    if(this.form.value.Validhire == "24hrs"){
      this.form.value.ampm_pickup = '';
      this.form.value.ampm_dropoff = '';
    }
    if (this.form.value.DropoffDate != '' && this.form.value.PickupDate != '') {
      this.PickupDateError = false;
      this.DropoffDateError = false;
    } else {
      if (this.form.value.DropoffDate == '') {
        this.DropoffDateError = true;
      } else {
        this.DropoffDateError = false;
      }
      if (this.form.value.PickupDate == '') {
        this.PickupDateError = true;
      } else {
        this.PickupDateError = false;
      }
    }

    if (this.form.valid) {
      this.form.value.DOB = this.datepipe.transform(this.form.value.DOB, 'yyyy-MM-dd');
      // let PickupDate;
      // if (this.form.value.PickupDate != '' && this.form.value.PickupDate != undefined) { 
      //   PickupDate = this.form.value.PickupDate.split(/\-|\s/);
      //   PickupDate[3] = this.convert24Time(PickupDate[3] + " " + PickupDate[4]);
      //   this.form.value.PickupDate = PickupDate.slice(0, 3).reverse().join('-') + ' ' + PickupDate[3];
      // }
      // let DropoffDate;
      // if (this.form.value.DropoffDate != '' && this.form.value.DropoffDate != undefined) { 
      //   DropoffDate = this.form.value.DropoffDate.split(/\-|\s/);
      //   DropoffDate[3] = this.convert24Time(DropoffDate[3] + " " + DropoffDate[4]);
      //   this.form.value.DropoffDate = DropoffDate.slice(0, 3).reverse().join('-') + ' ' + DropoffDate[3];
      // }
      
      // if ((this.form.value.DropoffDate !== '' && DropoffDate.length !== 1) && (PickupDate.length !== 1 && this.form.value.PickupDate !== '')) {
      if ((this.form.value.DropoffDate !== '') && (this.form.value.PickupDate !== '')) {
        document.getElementById('step2Loading').setAttribute('style', "display:block;");
        document.getElementById('truckhireSubmit').setAttribute('style', 'pointer-events:none;');
        this.PickupDateError = false;
        this.DropoffDateError = false;
        // console.log(this.form.value)
        this.api.postData(this.form.value).subscribe(data => {
          if (data['status'] === 200 && data['EmailMessage'] === 'Email Sent.') {
            // this.router.navigateByUrl('/'+data['thankyou']);
            let url = data['thankyou'];
            window.location.href=url;
          } else {
            document.getElementById('truckhireSubmit').setAttribute('style', 'pointer-events:all;');
            document.getElementById('errorMsg').setAttribute('style', 'display:block');
            (<HTMLInputElement>document.getElementById('errorMsg')).textContent = data['EmailMessage'];
          }
        });
      } else {
        this.PickupDateError = true;
        this.DropoffDateError = true;
      }
    }
  }
}
