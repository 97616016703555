import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-self-storage',
  templateUrl: './self-storage.component.html',
  styleUrls: ['./self-storage.component.css']
})
export class SelfStorageComponent implements OnInit {

  selfstorageData: any = Object;
  storageChildData: string[];
  filterstorageChildData: string[];
  storageParentData: any = Object;
  storageBannerData: any;
  storageDetailData: any = Object;
  Testimonial: string[];
  LocationData: string[];
  StorageType: string[];
  LocationbannerFrm: string[];
  detailAdditionImage: string[];
  SetLocationDataUrl: string[];
  LocPopupArr: string[];
  locStorageType: string[];
  selfstorageCatChildData: string[];
  ConfigDataAdvanceInfo: string[];
  selfstorageConfigData: any = Object;
  private isVisible: boolean;
  BaseUrl:string;
  activeDivCat:string;
  constructor(private api: ApiService, private router: Router, private meta: Meta, private title: Title, @Inject('BASE_URL') baseUrl: string) {
    this.BaseUrl = baseUrl; }

  slug = this.router.url;
  ngOnInit() {
    this.activeDivCat = 'personal-storage';
    this.api.getData().subscribe(data => {
      this.selfstorageData  = data;
      this.selfstorageCatChildData  = data['selfstorage']['Data'];
      this.selfstorageConfigData   = JSON.parse(data['selfstorage']['ConfigData']);
      this.ConfigDataAdvanceInfo   = JSON.parse(this.selfstorageConfigData['advance_information']);
      this.title.setTitle(data['selfstorage']['SEO']['MetaTitle']);
      // this.meta.updateTag({ name: 'title', content: data['selfstorage']['SEO']['MetaTitle'] });
			this.meta.updateTag({ name: 'description', content: data['selfstorage']['SEO']['MetaDescription'] });
      this.meta.updateTag({ name: 'keywords', content: data['selfstorage']['SEO']['MetaKeywords'] });

      if( data['selfstorage']['Data'][0]['parent'] != null || data['selfstorage']['Data'][0]['parent'] != undefined){
        this.storageParentData = data['selfstorage']['Data'][0]['parent'];
      }
      if( data['selfstorage']['Banner'] != null || data['selfstorage']['Banner'] != undefined){
        this.storageBannerData  = data['selfstorage']['Banner'];
        if(this.storageBannerData['image_name'] != undefined && this.storageBannerData['image_name'] != ''){
          this.storageBannerData  = this.storageBannerData['image_name'];
        }else{
            this.storageBannerData  = this.storageBannerData['default'];
        }
      }
      if( data['selfstorage']['Details'] != null || data['selfstorage']['Details'] != undefined){
        this.storageDetailData = data['selfstorage']['Details'];
      }
      if( data['selfstorage']['Testimonial'] != null || data['selfstorage']['Testimonial'] != undefined){
        this.Testimonial = data['selfstorage']['Testimonial'] as string[];
      }
      if( data['selfstorage']['LocationData'] != null || data['selfstorage']['LocationData'] != undefined){
        this.LocationData = data['selfstorage']['LocationData'] as string[];
      }
      if( data['selfstorage']['Data'][0]['child'] != null || data['selfstorage']['Data'][0]['child'] != undefined){
        this.storageChildData = data['selfstorage']['Data'][0]['child'] as string[];
      }
      if( data['selfstorage']['StorageType'] != null || data['selfstorage']['StorageType'] != undefined){
        this.StorageType = data['selfstorage']['StorageType'] as string[];
      }
      if( data['selfstorage']['Data']['location'] != null || data['selfstorage']['Data']['location'] != undefined){
        this.SetLocationDataUrl = data['selfstorage']['Data']['location'] as string[];
        this.locStorageType = data['selfstorage']['Data']['Loc_Storage_Type'] as string[];
        // document.getElementById('view_btn').setAttribute('style', 'display:block');
      }else{
        this.locStorageType = data['selfstorage']['StorageType'] as string[];
        // document.getElementById('view_btn').setAttribute('style', 'display:none');
      }
      // if( data['selfstorage']['Data'][0]['parent'] != null || data['selfstorage']['Data'][0]['parent'] != undefined){
      //   let newIndex = [];
      //   let LocPopupArr = [];
      //   let dat = this.storageParentData['loc_name'];
      //   if (dat != null) {
      //     if (dat.indexOf(',') > -1) {
      //       var Ldata = dat.split(',');
      //       Ldata.forEach((k1, v1) => {
      //         newIndex[v1] = k1.split('@')[1];
      //         LocPopupArr[v1] = this.LocationData.filter(x => x['id'] === k1.split('@')[1]);
      //         LocPopupArr[v1][0]['image'] = JSON.parse(LocPopupArr[v1][0]['image']);
      //       });
      //       this.LocationbannerFrm = newIndex;
      //     } else {
      //       this.LocationbannerFrm = [dat.split('@')[1]];
      //       LocPopupArr[0] = this.LocationData.filter(x => x['id'] === dat.split('@')[1]);
      //       LocPopupArr[0][0]['image'] = JSON.parse(LocPopupArr[0][0]['image']);
      //     }
      //   }     
      //   this.LocPopupArr = LocPopupArr as string[];
      //   document.getElementById('hoverImg').setAttribute('src', this.storageChildData[0]['main_image']);
      // }
      

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";

        BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"Storage Options","item":"' + location.href + '"}]}';
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
      });
    });
  }
  scroll() {
    document.querySelector('#getQuote').scrollIntoView({ behavior: 'smooth'});
 }

 apistart(newurl = ''){
  this.isVisible = false;
  this.api.getSpecificData(newurl).subscribe(data => {
    this.selfstorageData  = data;
    //console.log(data);
    if( data['selfstorage']['Data'][0]['parent'] != null || data['selfstorage']['Data'][0]['parent'] != undefined){
      this.storageParentData = data['selfstorage']['Data'][0]['parent'];
    }
    if( data['selfstorage']['Banner'] != null || data['selfstorage']['Banner'] != undefined){
      this.storageBannerData  = data['selfstorage']['Banner'];
      if(this.storageBannerData['image_name'] != undefined && this.storageBannerData['image_name'] != ''){
        this.storageBannerData  = this.storageBannerData['image_name'];
      }else{
          this.storageBannerData  = this.storageBannerData['default'];
      }
    }
    if( data['selfstorage']['Details'] != null || data['selfstorage']['Details'] != undefined){
      this.storageDetailData = data['selfstorage']['Details'];
    }
    if( data['selfstorage']['Testimonial'] != null || data['selfstorage']['Testimonial'] != undefined){
      this.Testimonial = data['selfstorage']['Testimonial'] as string[];
    }
    if( data['selfstorage']['LocationData'] != null || data['selfstorage']['LocationData'] != undefined){
      this.LocationData = data['selfstorage']['LocationData'] as string[];
    }
    if( data['selfstorage']['Data'][0]['child'] != null || data['selfstorage']['Data'][0]['child'] != undefined){
      this.storageChildData = data['selfstorage']['Data'][0]['child'] as string[];
    }
    if( data['selfstorage']['StorageType'] != null || data['selfstorage']['StorageType'] != undefined){
      this.StorageType = data['selfstorage']['StorageType'] as string[];
    }
    if( data['selfstorage']['Data']['location'] != null || data['selfstorage']['Data']['location'] != undefined){
      this.locStorageType = data['selfstorage']['Data']['Loc_Storage_Type'] as string[];
      this.SetLocationDataUrl = data['selfstorage']['Data']['location'] as string[];
      // document.getElementById('view_btn').setAttribute('style', 'display:block');
    }else{
      this.locStorageType = data['selfstorage']['StorageType'] as string[];
      // document.getElementById('view_btn').setAttribute('style', 'display:none');
    }
    if( data['selfstorage']['Data'][0]['parent'] != null || data['selfstorage']['Data'][0]['parent'] != undefined){
      let newIndex = [];
      let LocPopupArr = [];
      let dat = this.storageParentData['loc_name'];
      if (dat != null) {
        if (dat.indexOf(',') > -1) {
          var Ldata = dat.split(',');
          Ldata.forEach((k1, v1) => {
            newIndex[v1] = k1.split('@')[1];
            LocPopupArr[v1] = this.LocationData.filter(x => x['id'] === k1.split('@')[1]);
          });
          this.LocationbannerFrm = newIndex;
        } else {
          this.LocationbannerFrm = [dat.split('@')[1]];
          LocPopupArr[0] = this.LocationData.filter(x => x['id'] === dat.split('@')[1]);
        }
      }     
      this.LocPopupArr = LocPopupArr as string[];
      document.getElementById('hoverImg').setAttribute('src', this.storageChildData[0]['main_image']);
    }
  });
}
onScriptLoad() {}

onScriptError() {}

getSubCatData(event){
  // document.getElementById("visualMainDiv").innerHTML = "";
  this.activeDivCat = event;
  // console.log(event)
  
  // let urlData = '';
  // if(this.SetLocationDataUrl){
  //   urlData = '/self-storage/'+this.SetLocationDataUrl['slug']+'/'+ event;
  // }else{
  //   urlData = '/self-storage/'+ event;
  // }
  // this.router.navigateByUrl(urlData);
  // this.apistart(urlData);
}
 slideConfig = { infinite: true, slidesToShow: 1, slidesToScroll: 1, arrows: false, speed: 2000, dots: true };
 slickInit(e) {}
  locpopup(schildId,mainCat){
    document.querySelector('#myModal_1_'+mainCat).classList.add('popVisible');
    document.querySelector('body,html').classList.add('fixedPopupBody');
    if(sessionStorage.getItem('subcat')){
      sessionStorage.removeItem('subcat');
    }
    sessionStorage.setItem('subcat', schildId);
  }

 searchPageLink(LocationData,storagSlug){
  var urlData = '/self-storage-price-list/'+storagSlug+'/'+LocationData;
  this.router.navigateByUrl(urlData);
 }

 getLocDetail(LocationData){
   var urlData = '/self-storage/'+LocationData+'/personal-storage';
   this.router.navigateByUrl(urlData);
   this.apistart(urlData);
  //  document.getElementById('view_btn').setAttribute('style', 'display:block');
  }

  mouseHover(imagePath,id,catId) {
    document.getElementById('hoverImg_'+catId).setAttribute('src', imagePath);
    document.getElementById('radioDiv'+id).setAttribute('checked','checked');
    document.getElementById('radioDivMain'+id).classList.add('hoverRadio');
  }
  mouseLeave(id,catId){
    document.getElementById('hoverImg_'+catId).setAttribute('src', this.storageChildData[0]['main_image']);
    document.getElementById('radioDiv'+id).removeAttribute('checked');
    document.getElementById('radioDivMain'+id).classList.remove('hoverRadio');
  }

  gotolocandstype(){
    var urlData = '/self-storage-price-list/'+this.storageParentData['slug']+'/'+this.SetLocationDataUrl['slug'];
    this.router.navigateByUrl(urlData); 
  }

  closepopup(catId){
    document.querySelector('#myModal_1_'+catId).classList.remove("popVisible");
    document.querySelector("body, html").classList.remove("fixedPopupBody");
  }
}
