import { Component, OnInit,Inject } from '@angular/core';
import { ApiService } from '../api.service';
import { Router, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-search-options',
  templateUrl: './search-options.component.html',
  styleUrls: ['./search-options.component.css']
})
export class SearchOptionsComponent implements OnInit {

  BaseUrl: any;
  displayError: any = Object;
  private bannerformSumitAttempt: boolean;
  bannerFrm: FormGroup;
  Location: string[];
  LocationbannerFrm: string[];
  FindstorageTypeData: string[];
  DdOffers: string[];
  locationData: string[];
  storageError: boolean = false;
  locationError: boolean = false;
  searchData: any = Object;
  
  constructor(private api: ApiService, private router: Router, private formBuilder: FormBuilder, public datepipe: DatePipe,@Inject('BASE_URL') baseUrl: string) {
		this.BaseUrl = baseUrl;
	}

  ngOnInit() {

    this.api.getSearchFormData().subscribe(innerdata => {
      this.searchData = innerdata['search_form'];
      this.LocationbannerFrm = innerdata['search_form']['LocationData'];
      this.FindstorageTypeData = innerdata['search_form']['StorageType'];

      if (innerdata['search_form']['DdOffers'] != null || innerdata['search_form']['DdOffers'] != undefined) {
				this.DdOffers = innerdata['search_form']['DdOffers'] as string[];
			}
    });		

		this.bannerFrm = this.formBuilder.group({
			// storage: [this.storageParentData['slug']],
      // location: [this.LocstorageData['slug']],
      
      storage: [],
			location: [],
		});

  }

  	shoeStorageerror(){
		let estorage = (document.getElementById("storage")) as HTMLSelectElement;
		let storagesel = estorage.selectedIndex;
		let storageopt = estorage.options[storagesel];
		if (storageopt == undefined) {
			this.storageError = true;
		} else if (storageopt.value == "") {
			this.storageError = true;
		} else {
			this.storageError = false;
		}
	}

 	 onSearch() {    
    	this.bannerformSumitAttempt = true;
		let estorage = (document.getElementById("storage")) as HTMLSelectElement;
		let storagesel = estorage.selectedIndex;
		let storageopt = estorage.options[storagesel];
		// let storageValue = (<HTMLOptionElement>storageopt).value;
		let storageValue;

		let elocation = (document.getElementById("location")) as HTMLSelectElement;
		let locationsel = elocation.selectedIndex;
		let locationopt = elocation.options[locationsel];
    let locationValue;
    
		if (locationopt == undefined && storageopt == undefined) {
			this.locationError = true;
			this.storageError = true;
			// document.querySelector(".locationSelect .error-msg").setAttribute("style", "display:block;");
			// document.querySelector(".storageSelect .error-msg").setAttribute("style", "display:block;");
		} else if (locationopt.value == "") {
			this.locationError = true;
			// document.querySelector(".locationSelect .error-msg").setAttribute("style", "display:block;");
		} else {
      this.locationError = false;
      this.storageError = false;
			locationValue = (<HTMLOptionElement>locationopt).value;
      storageValue = (<HTMLOptionElement>storageopt).value;
    //   console.log(storageValue)
			if (storageValue != '' && locationValue != '' && storageValue.length != 0 && locationValue.length != 0) {
				let urlData = '/self-storage-price-list/' + storageValue + '/' + locationValue;
				this.router.navigateByUrl(urlData);
			}
		}
	}

	setSpanValue(event) {
		let text = event.target.options[event.target.options.selectedIndex].text;
		text = text.split("(");
		event.target.parentNode.firstChild.textContent = text[0];
		if (text == "Select location") {
			this.locationError = true;
		} else {
			this.locationError = false;
		}
	}

  	StorageDataChange(event, formname) {
		let text = event.target.options[event.target.options.selectedIndex].text;
		event.target.parentNode.firstChild.textContent = text;
		event.target.parentNode.parentNode.nextSibling.firstChild.firstChild.textContent = "Select location";

		document.querySelector('.locationSelect').setAttribute("class", "form-group locationSelect offerNotSelected");

		if (text == "Select Storage") {
			this.storageError = true;
			this.locationError = true;
		} else {
			this.storageError = false;
			this.locationError = true;
		}

		let html = '';
		let newIndex = [];
		this.FindstorageTypeData = this.searchData['StorageType'];
		let dat = this.FindstorageTypeData.filter(x => x['slug'] === event.target.value);
		if (dat[0] != undefined && dat[0]['loc_name'] != null) {
			if (dat[0]['loc_name'].indexOf(',') > -1) {
				let Ldata = (dat[0]['loc_name'].split(',')).sort();
				let locationOffers;
				if (this.DdOffers[dat[0]['id']] != undefined && this.DdOffers[dat[0]['id']] != null) {
					locationOffers = (this.DdOffers[dat[0]['id']]['location_ids']).split(',');
				}
				html += '<span class="spanOut selectlocation" id="locationSpan">Select location</span>\
					<select onchange="setSpanValue(this);changeOffer(this);" formControlName="location" id="location" title="Select Location"><option value=""> Select location </option>';
				Ldata.forEach((k1, v1) => {
					newIndex[v1] = k1.split('@');
					if (this.DdOffers[dat[0]['id']] != undefined && this.DdOffers[dat[0]['id']] != null) {
						let offer: any;
						offer = locationOffers.filter(x => x === k1.split('@')[1]);
						if (offer.length > 0) {
							html += '<option value="' + k1.split('@')[2] + '" id="' + k1.split('@')[2] + '" data-offer="' + this.DdOffers[dat[0]['id']]['offer_text'] + '">' + k1.split('@')[0];
							if (this.DdOffers[dat[0]['id']]['offer_text'].length > 0){
								html += ' ('; 
								html += this.DdOffers[dat[0]['id']]['offer_text'];
								html += ')';
							}
								html += '</option>';
						} else {
							html += '<option value="' + k1.split('@')[2] + '" id="' + k1.split('@')[2] + '" data-offer="">' + k1.split('@')[0] + '</option>';
						}
					} else {
						html += '<option value="' + k1.split('@')[2] + '" id="' + k1.split('@')[2] + '" data-offer="">' + k1.split('@')[0] + '</option>';
					}

					// html += '<option value="' + k1.split('@')[2] + '" id="' + k1.split('@')[2] + '">' + k1.split('@')[0] + '</option>';
				});
				html += '</select>';
				document.getElementById('locationDiv').innerHTML = html;
				document.getElementById('offerText').innerHTML = '';
				// this.LocationbannerFrm = newIndex;
				// event.target.parentNode.parentNode.nextSibling.firstChild.firstChild.textContent = this.LocstorageData['name'];
				this.locationError = false;
			} else {
				html += '<span class="spanOut selectlocation" id="locationSpan">Select location</span>\
					<select onchange="setSpanValue(this);changeOffer(this);" formControlName="location" id="location" title="Select Location"><option value=""> Select location </option>';
				let locationOffers;
				let offer: any;
				if (this.DdOffers[dat[0]['id']] != undefined && this.DdOffers[dat[0]['id']] != null) {
					locationOffers = (this.DdOffers[dat[0]['id']]['location_ids']).split(',');
					offer = locationOffers.filter(x => x === dat[0]['loc_name'].split('@')[1]);
					if (offer.length > 0) {
						html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" id="' + dat[0]['loc_name'].split('@')[2] + '" data-offer="' + this.DdOffers[dat[0]['id']]['offer_text'] + '">' + dat[0]['loc_name'].split('@')[0];
						if (this.DdOffers[dat[0]['id']]['offer_text'].length > 0){
							html += ' (';
							html += this.DdOffers[dat[0]['id']]['offer_text'];
							html += ')';
						}
						 '</option>';
					} else {
						html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" id="' + dat[0]['loc_name'].split('@')[2] + '" data-offer="">' + dat[0]['loc_name'].split('@')[0] + '</option>';
					}
				} else {
					html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" id="' + dat[0]['loc_name'].split('@')[2] + '" data-offer="">' + dat[0]['loc_name'].split('@')[0] + '</option>';
				}

				// html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" id="' + dat[0]['loc_name'].split('@')[2] + '">' + dat[0]['loc_name'].split('@')[0] + '</option>';
				html += '</select>';
				document.getElementById('locationDiv').innerHTML = html;
				document.getElementById("offerText").innerHTML = '';
				// this.LocationbannerFrm = [dat[0]['loc_name'].split('@')];
			}
		} else {
			html += '<span class="spanOut selectlocation" id="locationSpan">Select location</span>\
					<select onchange="setSpanValue(this);changeOffer(this);" formControlName="location" id="location" title="Select Location">\
					<option value=""> Select location </option>';
			html += '</select>';
			document.getElementById('locationDiv').innerHTML = html;
			document.getElementById("offerText").innerHTML = '';
			// this.LocationbannerFrm = null;
		}
		this.locationData = null;
	}
}
