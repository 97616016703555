import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
	selector: 'app-storage-options',
	templateUrl: './storage-options.component.html',
	styleUrls: ['./storage-options.component.css']
})
export class StorageOptionsComponent implements OnInit {

	defaultImage = 'assets/images/default-banner.jpg';
	defaultImg = 'assets/images/banner-img.jpg';
	loadingImg = 'assets/images/loading.gif';
	offset = 1000;
	Testimoniallength: number;
	StorageOptionsData: any = Object;
	storageChildData: string[];
	filterstorageChildData: string[];
	storageParentData: any = Object;
	storageDetailData: any = Object;
	Testimonial: string[];
	LocationData: string[];
	StorageType: string[];
	LocationbannerFrm: string[];
	detailAdditionImage: string[];
	imageArrlength: number;
	feaArr: string[]; 
	OfferBanner: string[]; 
	url: string[];
	feaArrlength: number;
	advance_information: string[];
	adddataparams: any = Object;
	BaseUrl: any;
	bannerImages: any;
	bannerMobileImages: any;
	ConfigDataAdvanceInfo: string[];
	
	/* Start - Below script inserted for the Google reviews*/
	GoogleReviews: string[];
	showShortDesciption: boolean = true;
	selectedText: string = '';
	numSequence(n: number): Array<number> {
		return Array(Math.floor(n));
	  }
	alterdescText(text){
		console.log(text);
		this.selectedText = text;
		//return this.showShortDesciption = !this.showShortDesciption;
	}
	/* End - Below script inserted for the Google reviews*/
	
	constructor(private api: ApiService, private router: Router, public meta: Meta, private title: Title, @Inject('BASE_URL') baseUrl: string) {
		this.BaseUrl = baseUrl; 
		this.loadScript('https://api.feefo.com/api/javascript/hills-self-storage');
	}

	ngOnInit() {
		this.api.getData().subscribe(data => {
			if (data['storageoptions']['Data']['response'] == '204') {
				this.router.navigateByUrl('404');
			} else {
				this.StorageOptionsData = data;
				this.title.setTitle(data['storageoptions']['Details']['meta_title']);
				// this.meta.updateTag({ name: 'title', content: data['storageoptions']['Details']['meta_title'] });
				this.meta.updateTag({ name: 'description', content: data['storageoptions']['Details']['meta_description'] });
				this.meta.updateTag({ name: 'keywords', content: data['storageoptions']['Details']['meta_keyword'] });

				this.storageParentData = data['storageoptions']['Data'][0]['parent'];
				this.storageDetailData = data['storageoptions']['Details'];
				if(this.storageDetailData.advance_heading){
					this.ConfigDataAdvanceInfo   = JSON.parse(this.storageDetailData.addinfo);
				}
				if (data['storageoptions']['OfferBanner'] != null || data['storageoptions']['OfferBanner'] != undefined) {
					this.OfferBanner = data['storageoptions']['OfferBanner'] as string[];
				}
				let Ldatas: any = [];
				let featurData = this.storageDetailData['feature_list'];
				if (this.storageDetailData) {
					if (featurData.indexOf('\r\n') > -1) {
						Ldatas = featurData.split('\r\n');
					}
					this.feaArr = Ldatas;
					this.feaArrlength = this.feaArr.length;
				}

				this.Testimonial = data['storageoptions']['Testimonial'] as string[];
				this.Testimoniallength = this.Testimonial.length;
				let LocNewIndex = [];
				let checkImage: any = data['storageoptions']['LocationData'] as string[];
				checkImage.forEach((k1, v1) => {
					LocNewIndex[v1] = k1;
					LocNewIndex[v1]['image'] = JSON.parse(LocNewIndex[v1]['image']);
				});
				this.LocationData = LocNewIndex;
				// this.LocationData = data['storageoptions']['LocationData'] as string[];

				this.storageChildData = data['storageoptions']['Data'][0]['child'] as string[];
				this.StorageType = data['storageoptions']['StorageType'] as string[];
				this.detailAdditionImage = JSON.parse(this.storageDetailData['additional_image']);
				this.imageArrlength = this.detailAdditionImage.length;

				var newIndex = [];
				var dat = this.storageParentData['loc_name'];
				if (dat != null) {
					if (dat.indexOf(',') > -1) {
						var Ldata = dat.split(',');
						Ldata.forEach((k1, v1) => {
							newIndex[v1] = k1.split('@');
						});
						this.LocationbannerFrm = newIndex;
					} else {
						this.LocationbannerFrm = [dat.split('@')];
					}
				}
				this.advance_information = JSON.parse(this.storageDetailData['advance_information']);

				this.bannerImages = JSON.parse(data['storageoptions']['Details']['banner_image']);
				if (this.bannerImages.length == 0) {
					this.bannerImages = [{ 'name': this.defaultImg, 'alt': this.storageParentData['headerTitle'], 'title': this.storageParentData['headerTitle'] }];
				}
				this.bannerMobileImages = JSON.parse(data['storageoptions']['Details']['mobile_banner_image'])[0];
				if (this.bannerMobileImages.length == 0) {
					this.bannerMobileImages = [{ 'name': this.defaultImg, 'alt': this.storageParentData['headerTitle'], 'title': this.storageParentData['headerTitle'] }];
				}
			}
			
			//console.log(data);
			/* Start - Below script inserted for the Google reviews*/
			if (data['storageoptions']['SEO']['googlereview'] != null || data['storageoptions']['SEO']['googlereview'] != undefined) {
				var reivewjsonData = JSON.parse(data['storageoptions']['SEO']['googlereview']);
				reivewjsonData = JSON.parse(reivewjsonData.generalReviews);
				console.log(reivewjsonData);
				//console.log(this.WhyUs.service);
				this.GoogleReviews = reivewjsonData as string[];
			}
			/* End - Below script inserted for the Google reviews*/

			return new Promise(resolve => {
				const scriptElementHeader = document.createElement('script');
				// scriptElementHeader.src = "";
				scriptElementHeader.innerHTML = data['seo_header_content'];
				scriptElementHeader.onload = resolve;
				document.head.appendChild(scriptElementHeader);

				const scriptElementFooter = document.createElement('script');
				// scriptElementFooter.src = "";
				scriptElementFooter.innerHTML = data['seo_footer_content'];
				scriptElementFooter.onload = resolve;
				document.body.appendChild(scriptElementFooter);

				const scriptElementSchema = document.createElement('script');
				scriptElementSchema.type = "application/ld+json";
				// scriptElementSchema.src = "";
				scriptElementSchema.innerHTML = data['schema'];
				scriptElementSchema.onload = resolve;
				document.body.appendChild(scriptElementSchema);

				const BreadcrumbSchema = document.createElement('script');
				BreadcrumbSchema.type = "application/ld+json";
				BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"' + this.storageParentData['cat_name'] + '","item":"' + this.BaseUrl + 'storage-options/' + this.storageParentData['slug'] + '"}]}';
				BreadcrumbSchema.onload = resolve;
				document.body.appendChild(BreadcrumbSchema);
			});
		});


		setTimeout(function () {
			this.equalheight('.eqheight');
		}, 2000);


	}
	public loadScript(url) {
		let node = document.createElement('script');
		node.src = url;
		node.type = 'text/javascript';
		document.getElementsByTagName('head')[0].appendChild(node);
	 }
	scroll() {
		document.querySelector('#getQuote').scrollIntoView({ behavior: 'smooth' });
	}

	testiSlideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		infinite: false,
		// vertical:false,
		speed: 1500,
		// verticalSwiping:true,
		appendArrows: '.testArrow',
		responsive: [
			{
				breakpoint: 480,
				settings: {
					// vertical:true
					// verticalSwiping:true
				}
			}]
	};

	storageSlideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		dots: true,
		infinite: true,
		speed: 1500,
	};


	//slides = this.Banner;
	slideConfig = { infinite: true, slidesToShow: 1, slidesToScroll: 1, arrows: false, speed: 2000, dots: true, };
	offerSlideConfig = { infinite: true, slidesToShow: 1, slidesToScroll: 1, arrows: true, autoplaySpeed: 6000, dots: false, autoplay: true,speed:2000, pauseOnHover:true,};
	testimonialConfig = {
		infinite: true, slidesToShow: 5, slidesToScroll: 5, arrows: false, speed: 2000, dots: true,
		responsive: [
		{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
					arrows: false
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
					arrows: false
				}
			},
			{
				breakpoint: 566,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
					arrows: false
				}
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		]

	};
	slickInit(e) { }

	locpopup(schildId) {
		document.querySelector('#myModal_1').classList.add('popVisible');
		document.querySelector('body,html').classList.add('fixedPopupBody');
		if (sessionStorage.getItem('subcat')) {
			sessionStorage.removeItem('subcat');
		}
		sessionStorage.setItem('subcat', schildId);
	}

	searchPageLink(LocationData) {
		var urlData = '/self-storage-price-list/' + this.storageParentData['slug'] + '/' + LocationData;
		this.router.navigateByUrl(urlData);
	}

	closepopup() {
		document.querySelector('#myModal_1').classList.remove("popVisible");
		document.querySelector("body, html").classList.remove("fixedPopupBody");
	}
}
