import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {
  searchData: any = Object;
  AllFaqs: string[];
  AllFaqsCategory:string[];
  FindstorageTypeData: string[];
  LocationbannerFrm: string[];
  LocationConfigData: any = Object;
  storageBannerData: any;
  arr: string[];
  BaseUrl:string;
  constructor(@Inject('BASE_URL') baseUrl: string,private api: ApiService, private router: Router,private meta:Meta,private title:Title){
    this.BaseUrl = baseUrl;
  }
  slug = this.router.url;
  ngOnInit(){
    this.api.getData().subscribe(data => {
      this.searchData = data;
      this.title.setTitle(data['faqs']['SEO']['MetaTitle']);
      // this.meta.updateTag({ name: 'title', content: data['faqs']['SEO']['MetaTitle'] });
			this.meta.updateTag({ name: 'description', content: data['faqs']['SEO']['MetaDescription'] });
      this.meta.updateTag({ name: 'keywords', content: data['faqs']['SEO']['MetaKeywords'] });

      if(data['faqs']['AllFaqs']['faq'] != null || data['faqs']['AllFaqs']['faq'] != undefined){
        this.AllFaqs = data['faqs']['AllFaqs']['faq'];
      }
      if(data['faqs']['AllFaqs']['keys'] != null || data['faqs']['AllFaqs']['keys'] != undefined){
        this.AllFaqsCategory = data['faqs']['AllFaqs']['keys'];
      }
      if(data['faqs']['Banner'] != null || data['faqs']['Banner'] != undefined){
        this.storageBannerData  = data['faqs']['Banner'];
        if(data['faqs']['Banner']['image_name'] != undefined && data['faqs']['Banner']['image_name'] != ''){
          this.storageBannerData  = data['faqs']['Banner']['image_name'];
        }else{
          this.storageBannerData  = data['faqs']['Banner']['default'];
        }
      }

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";

        BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"FAQ’S","item":"' + location.href + '"}]}';
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
      });
    });
  }
  gotolink(locData){
    var urlData = '/self-storage-location/'+locData;
    this.router.navigateByUrl(urlData);
  }
  StorageDataChange(event, formname) {    
    let text = event.target.options[event.target.options.selectedIndex].text;
    event.target.parentNode.firstChild.textContent = text;
    event.target.parentNode.parentNode.nextSibling.firstChild.firstChild.textContent = "Select Storage location";

    let newIndex = [];
    this.FindstorageTypeData = this.searchData['selfstoragelocations']['StorageType'];
    let dat = this.FindstorageTypeData.filter(x => x['slug'] === event.target.value);
    if (dat[0]['loc_name'] != null) {
      if (dat[0]['loc_name'].indexOf(',') > -1) {
        let Ldata = dat[0]['loc_name'].split(',');
        Ldata.forEach((k1, v1) => {
          newIndex[v1] = k1.split('@');
        });
        this.LocationbannerFrm = newIndex;
      } else {
        this.LocationbannerFrm = [dat[0]['loc_name'].split('@')];
      }
    }
  }
}
