import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../api.service';
import { Router, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-calcumate-get-a-quote',
  templateUrl: './calcumate-get-a-quote.component.html',
  styleUrls: ['./calcumate-get-a-quote.component.css']
})
export class CalcumateGetAQuoteComponent implements OnInit {
  storageError: any;
  locationError: any;
  homeData: any = Object;
  StorageType: string[];
  Location: string[];
  LocationQuote: string[];
  mainkey: string[];
  myRecaptcha: boolean;
  form: FormGroup;
  displayError: any = Object;
  minDate: Date;
  urldata: any;
  callback: any;
  clicked: any;
  selectedStorageSize: any;
  private formSumitAttempt: boolean;
  BaseUrl: string;
  constructor(private api: ApiService, private router: Router, private formBuilder: FormBuilder, public datepipe: DatePipe, @Inject('BASE_URL') baseUrl: string,private meta: Meta, private title: Title) {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.BaseUrl = baseUrl;
   }
	data: any;

  ngOnInit() {
    this.api.getAQuoteData().subscribe(innerdata => {
      this.LocationQuote = innerdata['get_a_quote']['LocationData'];
      localStorage.setItem('CategoryList', JSON.stringify(innerdata['get_a_quote']['CategoryList']));
      this.urldata = this.router.url.split('/')[2];
      let html = '';
      let dat = this.LocationQuote.filter(x => x['slug'] === this.urldata);
      if (dat[0] != undefined && dat[0]['parent_category'] != null) {
        html += '<select class="selectDropdown" formControlName="storage_option" id="storage_option" title="Storage Option" required onchange="setSpanValueQuoteForm(this)"><option value="">Storage option</option>';
        let Ldata = dat[0]['parent_category'];
        Ldata.forEach((k1, v1) => {
          html += '<option value="' + k1['NAME'] + '" data-cat-id="' + k1['id'] + '">' + k1['NAME'] + '</option>';
        });
        html += '</select>';
        document.getElementById("storage_optionDiv").innerHTML = html;
      } else {
        this.urldata = '';
      }
      return new Promise(resolve => {
        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = innerdata['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);
      });
    });

    this.api.getData().subscribe(innerdata => {

      this.title.setTitle(innerdata['MetaTitle']);
      this.meta.updateTag({ name: 'description', content: innerdata['MetaDescription'] });
      
      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = innerdata['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = innerdata['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = innerdata['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);
      });
    });

    this.selectedStorageSize = localStorage.getItem("SelectedSize");

    this.form = this.formBuilder.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      phone_no: [null, [Validators.required]],
      email_address: [null, [Validators.required, Validators.email]],
      storage_option: [''],
      move_in_date: [null, [Validators.required]],
      location: [''],
      storage_cat: [''],
      storage_type: ['Personal'],
      form_title: ['calcumate_get_a_quote'],
      additional_info: [''],
      recaptcha: [false, [Validators.required]],
    });
  }

  StorageDataChange(event, formname) {
    if (event.target.options[event.target.options.selectedIndex].text == "Storage option") {
      this.locationError = true;
      this.storageError = true;
    } else {
      this.locationError = false;
      this.storageError = true;
    }
    var html = '';
    var dat = this.LocationQuote.filter(x => x['slug'] === event.target.value);
    if (dat[0] != undefined && dat[0]['parent_category'] != null) {
      html += '<select class="selectDropdown" formControlName="storage_option" id="storage_option" title="Storage Option" required onchange="setSpanValueQuoteForm(this)">\
          <option value="">Storage option</option>';
      var Ldata = dat[0]['parent_category'];
      Ldata.forEach((k1, v1) => {
        html += '<option value="' + k1['NAME'] + '" data-cat-id="' + k1['id'] + '">' + k1['NAME'] + '</option>';
      });
      html += '</select>';
      document.getElementById("storage_optionDiv").innerHTML = html;
    } else {
      html += '<select class="selectDropdown" formControlName="storage_option" id="storage_option" title="Storage Option" required onchange="setSpanValueQuoteForm(this)">\
          <option value="">Storage option</option>';
      html += '</select>';
      document.getElementById("storage_optionDiv").innerHTML = html;
    }
  }

  setSpanValueQuoteForm(event) {
    let text = event.target.options[event.target.options.selectedIndex].text;
    if (text == "Storage option") {
      this.storageError = true;
    } else {
      this.storageError = false;
    }
  }
  onScriptLoad() { }

  onScriptError() { }

  isFieldValid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSumitAttempt)
    );
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSubmit() {
    this.formSumitAttempt = true;

    // let estorage = document.getElementById("storage_option") as HTMLSelectElement;
    // let storagesel = estorage.selectedIndex;
    // let storageopt = estorage.options[storagesel];

    // let estorage = (document.getElementById("storage_option")) as HTMLOptionElement;
    let elocation = document.getElementById("getquotelocation") as HTMLSelectElement;
    let locationsel = elocation.selectedIndex;
    let locationopt = elocation.options[locationsel];
    if (locationopt == undefined || locationopt == null) {
      this.locationError = true;
    } else if (locationopt.value == "") {
      this.locationError = true;
    } else {
      this.locationError = false;
    }
    let urlpart: any = this.router.url.split('/');
    if (urlpart[urlpart.length - 1] == 'contact-us') {
      this.form.value.form_title = 'contactus_enquiry';
    }
    let estoragecat = (document.getElementById("storage_size") as HTMLInputElement).value;

    // if (storageopt == undefined) {
    //   this.storageError = true;
    //   document.querySelector(".storage_optionDiv .error-msg").setAttribute("style", "display:block");
    // } else if (storageopt.value == "") {
    //   this.storageError = true;
    //   document.querySelector(".storage_optionDiv .error-msg").setAttribute("style", "display:block");
    // } else {
    //   this.storageError = false;
    // }
    // if (this.form.valid && this.locationError == false && this.storageError == false) {
    if (this.form.valid && this.locationError == false) {
      document.getElementById('step2Loading').setAttribute('style', "display:block;");
      document.getElementById('getaquotesubmit').setAttribute('style', 'pointer-events:none;');
      this.form.value.move_in_date = this.datepipe.transform(this.form.value.move_in_date, 'yyyy-MM-dd');
      // this.form.value.storage_option = storageopt.value;
      this.form.value.storage_cat = estoragecat;
      this.form.value.location = locationopt.text;
      this.api.postData(this.form.value).subscribe(data => {
        if (data['status'] == 200 && data['EmailMessage'] == "Email Sent.") {
          localStorage.removeItem('SelectedSize');
          // this.router.navigateByUrl('/'+data['thankyou']);
          let url = data['thankyou'];
					window.location.href=url;
        } else {
          document.getElementById('getaquotesubmit').setAttribute('style', 'pointer-events:all;');
          document.getElementById('errorMsg').setAttribute("style", "display:block;");
          (<HTMLInputElement>document.getElementById('errorMsg')).textContent = data['EmailMessage'];
        }
      });
    }
  }
}
