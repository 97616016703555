import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe, Location  } from '@angular/common';
import { isEmpty } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { $ } from 'protractor';
import { ActivatedRoute } from '@angular/router';


@Component({
	selector: 'app-self-storage-price-list',
	templateUrl: './self-storage-price-list.component.html',
	styleUrls: ['./self-storage-price-list.component.css']
})
export class SelfStoragePriceListComponent implements OnInit {

	storageError: boolean = false;
	locationError: boolean = false;
	PickupDateError: boolean = false;
	DropoffDateError: boolean = false;
	ftlocationError: boolean = false;
	ftPickupDateError: boolean = false;
	ftDropoffDateError: boolean = false;
	storage_typeRadio: boolean = false;
	locationData: any;
	searchData: any = Object;
	resultGridList: any = Object;
	storageChildData: string[];
	statedd: any;
	filterstorageChildData: string[];
	storageParentData: any = Object;
	storageTypeData: string[];
	LocstorageData: any = Object;
	LocstorageName: any;
	calcumate: any;
	FindstorageTypeData: string[];
	detailAdditionImage: string[];
	detailAdditionImageThumb: string[];
	LocstorageDataimage: any = Object;
	bannerFrm: FormGroup;
	Location: string[];
	LocationbannerFrm: string[];
	DdOffers: string[];
	sub_category: string;
	selectedPackg: string;
	selectedPackgPrice: string;
	locName: any = Object;
	quoteUpperDetails: any = Object;
	selfstorageBannerData: any;
	public productData;
	private bannerformSumitAttempt: boolean;
	public isVisible: boolean;
	myRecaptcha: boolean;
	quoteForm: FormGroup;
	priceForm: FormGroup;
	private formSumitAttempt: boolean;
	displayError: any = Object;
	feaArr: string[];
	free_trailer_message_data: string[];
	minDate: Date;
	free_trailer_message: any;
	selectedPackgData: any;
	selectedPackgDuration: any;
	MetaTitle: any;
	MetaDescription: any;
	data: any;
	BaseUrl: any;
	LimitLocation: any;
	storageName: any;
	LocationData: string[];
	LocationDataDeatils: string[];
	feature_location_image: string[];
	feature_location_names: string[];
	feature_data_location: string[];
	feature_location_data: string[];
	urldata: any;
	genReviewCnt: any = 0;
	getnRatingCnt: any = 0;
	tooltip:boolean;
	userDetailsExists: boolean;
	user_first_name:string;
	user_last_name:string;
	user_email:string;
	user_contact:string;
	user_title:string;
	user_move_date:string;
	user_estimate_storage_period_month :string;
	vp_sub_category:string;
	vp_price_list:string;
	vp_product_id:number;
	
	constructor(private api: ApiService, private router: Router, private formBuilder: FormBuilder, public datepipe: DatePipe, private meta: Meta, private title: Title, @Inject('BASE_URL') baseUrl: string, private location: Location,private route: ActivatedRoute) {
		this.BaseUrl = baseUrl;
		this.urldata = this.router.url.split('/')[3];
		if (this.urldata == 'kings-park') {
			this.router.navigateByUrl('/404', {skipLocationChange: true});
		}
		this.loadScript('https://api.feefo.com/api/javascript/hills-self-storage');
		this.tooltip = false;
	}
	showtooltip(tooltip,productid){
		var tooltips = document.getElementsByClassName("tooltip-display");
		if(tooltips.length > 0){
			for (var i = 0; i < tooltips.length; i++) {
				if(typeof tooltips.item(i) !== null){
					tooltips.item(i).setAttribute("style", "display:none");
				}	
			}
		}
		document.getElementById("tooltip-"+productid).setAttribute("style", "display:none");
		this.tooltip = tooltip;
		if(this.tooltip){
			document.getElementById("tooltip-"+productid).setAttribute("style", "display:block");
		}
	}
	showtooltipFormPage(tooltip,productid){
		var tooltips = document.getElementsByClassName("tooltip-formpage-display");
		if(tooltips.length > 0){
			for (var i = 0; i < tooltips.length; i++) {
				if(typeof tooltips.item(i) !== null){
					tooltips.item(i).setAttribute("style", "display:none");
				}	
			}
		}
		document.getElementById("tooltip-formpage-"+productid).setAttribute("style", "display:none");
		this.tooltip = tooltip;
		if(this.tooltip){
			document.getElementById("tooltip-formpage-"+productid).setAttribute("style", "display:block");
		}
	}
	closeSuccessTooltip() {
		document.getElementById('success_tooltip_msg').setAttribute("style","display:none");
	}
	closepopupPrices() {
		document.querySelector('#prices-view').classList.remove("popVisible");
		document.querySelector("body, html").classList.remove("fixedPopupBody");
	}
	popup() {
		document.querySelector('#freeTrailer').classList.add('popVisible');
		document.querySelector('body,html').classList.add('fixedPopupBody');
	}
	closepopup() {
		document.querySelector('#freeTrailer').classList.remove("popVisible");
		document.querySelector("body, html").classList.remove("fixedPopupBody");
	}
	popupstorage() {
		document.querySelector('#storageFacility').classList.add('popVisible');
		document.querySelector('body,html').classList.add('fixedPopupBody');
	}
	closepopupstorage() {
		document.querySelector('#storageFacility').classList.remove("popVisible");
		document.querySelector("body, html").classList.remove("fixedPopupBody");
	}
	storageSlierPopupMobile() {
		document.querySelector('#storageSlierPopupMobile').classList.add('popVisible');
		document.querySelector('body,html').classList.add('fixedPopupBody');
	}
	closeStorageSlierPopupMobile() {
		document.querySelector('#storageSlierPopupMobile').classList.remove("popVisible");
		document.querySelector("body, html").classList.remove("fixedPopupBody");
	}

	storageSlierPopupVideo() {
		document.querySelector('#storageSlierPopupVideo').classList.add('popVisible');
		document.querySelector('body,html').classList.add('fixedPopupBody');
	}
	closestorageSlierPopupVideo() {
		document.querySelector('#storageSlierPopupVideo').classList.remove("popVisible");
		document.querySelector("body, html").classList.remove("fixedPopupBody");
	}

	setPopupContactTitleDropdownValue(event) {
		if(event.target.value != ""){
			document.getElementById("span-popup-contact-title").innerText = event.target.value;
		}else{
			document.getElementById("span-popup-contact-title").innerText = "Title";
		}
	}

	setContactTitleDropdownValue(event) {
		if(event.target.value != ""){
			document.getElementById("span-contact-title").innerText = event.target.value;
		}else{
			document.getElementById("span-contact-title").innerText = "Title";
		}
	}

	ngOnInit() {
		this.userDetailsExists = false;
		let sess = sessionStorage.getItem('subcat');
		if (sessionStorage.getItem('goback') == null || sessionStorage.getItem('goback') == '0') {
			sessionStorage.setItem("goback",'1');
		}
		this.api.getData().subscribe(data => {
			this.searchData = data;
			let LocNewIndex = [];
			let checkImage: any = data['selfstoragepricelist']['Data']['location'];
			LocNewIndex = checkImage;
			LocNewIndex['image'] = JSON.parse(checkImage['image']);
			this.LocstorageData = LocNewIndex;
			this.LimitLocation = data['Limitlocations'];
			this.calcumate = data['calcumate'];
			// this.LocstorageData = data['selfstoragepricelist']['Data']['location'];
			this.LocstorageName = data['selfstoragepricelist']['Data']['location']['name'];
			document.getElementById('locName').textContent = data['selfstoragepricelist']['Data']['location']['name'];
			this.LocstorageData.phone = this.LocstorageData.phone.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3");
			this.LocstorageData.fax = this.LocstorageData.fax.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3");

			if(this.LocstorageData.access_hours == '24_7_access'){
				this.LocstorageData.access_hours = '24/7 Access';
				let url = this.BaseUrl+'assets/images/features/full-access.svg';
				document.getElementById('access_hours_data').setAttribute('src', url);
			}else if(this.LocstorageData.access_hours == 'after_hours_access'){
				let url = this.BaseUrl+'assets/images/features/after-hours-access.png';
				this.LocstorageData.access_hours = 'After Hours Access';
				document.getElementById('access_hours_data').setAttribute('src', url);
			}
			this.MetaTitle = data['selfstoragepricelist']['SEO']['MetaTitle'];
			this.title.setTitle(this.MetaTitle.replace('[subcategory_name]', 'all'))
			this.MetaDescription = data['selfstoragepricelist']['SEO']['MetaDescription'];
			this.meta.updateTag({ name: 'description', content: this.MetaDescription.replace('[subcategory_name]', 'all') });

			this.meta.updateTag({ name: 'keywords', content: data['selfstoragepricelist']['SEO']['MetaKeywords'] });

			this.free_trailer_message = data['free_trailer_message'];
			this.statedd = data['statedd'];
			this.storageParentData = data['selfstoragepricelist']['Data'][0]['parent'];
			this.storageChildData = data['selfstoragepricelist']['Data'][0]['child'] as string[];
			this.filterstorageChildData = data['selfstoragepricelist']['Data'][0]['child'] as string[];
			this.storageTypeData = data['selfstoragepricelist']['Data']['Loc_Storage_Type'] as string[];
			this.LocationData = data['selfstoragepricelist']['LocationData'] as string[];
			this.LocationDataDeatils = data['selfstoragepricelist']['LocationData'] as string[];
			if (data['selfstoragepricelist']['DdOffers'] != null || data['selfstoragepricelist']['DdOffers'] != undefined) {
				this.DdOffers = data['selfstoragepricelist']['DdOffers'] as string[];
			}
			this.feature_location_names = data['selfstoragepricelist']['Data']['location']['feature_data_names'];
			this.feature_location_data = data['selfstoragepricelist']['Data']['location']['feature_location_data'];
			
			let Ldatas: any = [];
			let featurData = this.LocstorageData['facilities'];
			if (featurData.indexOf('\r\n') > -1) {
				Ldatas = featurData.split('\r\n');
			}
			this.feaArr = Ldatas;

			this.FindstorageTypeData = data['selfstoragepricelist']['StorageType'] as string[];
			this.detailAdditionImage = JSON.parse(this.LocstorageData['images']);
			this.detailAdditionImageThumb = JSON.parse(this.LocstorageData['thumbimages']);
			this.selfstorageBannerData = data['selfstoragepricelist']['Banner'];
			if (this.selfstorageBannerData['image_name'] != undefined && this.selfstorageBannerData['image_name'] != '') {
				this.selfstorageBannerData = this.selfstorageBannerData['image_name'];
			} else {
				this.selfstorageBannerData = this.selfstorageBannerData['default'];
			}

			this.LocstorageDataimage = this.LocstorageData['image'][0];
		
			let locD = [];
			let locData = (this.storageParentData['loc_name'].split(',')).sort();
			if (locData.indexOf(',') > -1) {
				locData.forEach((k1, v1) => {
					locD[v1] = k1.split('@');
				});
				this.locName = locD[2];
			} else {
				this.locName = locData[0].split('@');
			}

			if (this.storageChildData == undefined) {
				document.getElementById("storageChildData").setAttribute("style", "display:block");
			} else {
				document.getElementById("storageChildData").setAttribute("style", "display:none");
			}

			let newIndex = [];
			let locdat = this.FindstorageTypeData.filter(x => x['slug'] === this.storageParentData.slug);
			if (locdat[0]['loc_name'] != null) {
				if (locdat[0]['loc_name'].indexOf(',') > -1) {
					let Ldata = (locdat[0]['loc_name'].split(',')).sort();
					let locationOffers;
					if (this.DdOffers[locdat[0]['id']] != undefined && this.DdOffers[locdat[0]['id']] != null) {
						locationOffers = (this.DdOffers[locdat[0]['id']]['location_ids']).split(',');
					}
					Ldata.forEach((k1, v1) => {
						if (this.DdOffers[locdat[0]['id']] != undefined && this.DdOffers[locdat[0]['id']] != null) {
							let offer: any;
							offer = locationOffers.filter(x => x === k1.split('@')[1]);
							if (offer.length > 0) {
								if (this.LocstorageData['slug'] === k1.split('@')[2] && this.DdOffers[locdat[0]['id']]['offer_text'] != '') {
									document.querySelector('.locationSelect').setAttribute("class", "form-group locationSelect");
									// document.getElementById('offerText').innerHTML = "<span>&#9733;</span>" + this.DdOffers[locdat[0]['id']]['offer_text'] + "<span>&#9733;</span>";
									document.getElementById('offerText').innerHTML = "<span>&#9733;</span>" + this.DdOffers[locdat[0]['id']]['offer_text'] + "<span>&#9733;</span>";
								}
								let b1: any = k1 + '@' + this.DdOffers[locdat[0]['id']]['offer_text'];
								newIndex[v1] = b1.split('@');
							} else {
								let b2: any = k1 + '@' + '';
								newIndex[v1] = b2.split('@');
							}
						}
						else {
							let b3: any = k1 + '@' + '';
							newIndex[v1] = b3.split('@');
						}
					});
					this.LocationbannerFrm = newIndex;
				} else {
					let single;
					let locationOffers;
					if (this.DdOffers[locdat[0]['id']] != undefined && this.DdOffers[locdat[0]['id']] != null) {
						locationOffers = (this.DdOffers[locdat[0]['id']]['location_ids']).split(',');
						let offer: any;

						offer = locationOffers.filter(x => x === locdat[0]['loc_name'].split('@')[1]);
						if (offer.length > 0) {
							if (this.LocstorageData['slug'] === locdat[0]['loc_name'].split('@')[2] && this.DdOffers[locdat[0]['id']]['offer_text'] != '') {
								document.querySelector('.locationSelect').setAttribute("class", "form-group locationSelect");
								document.getElementById('offerText').innerHTML = "<span>&#9733;</span>" + this.DdOffers[locdat[0]['id']]['offer_text'] + "<span>&#9733;</span>";
							}
							let b1: any = locdat[0]['loc_name'] + '@' + this.DdOffers[locdat[0]['id']]['offer_text'];
							single = b1.split('@');
						} else {
							let b2: any = locdat[0]['loc_name'] + '@' + '';
							single = b2.split('@');
						}
						this.LocationbannerFrm = [single];
					} else {
						let b2: any = locdat[0]['loc_name'] + '@' + '';
						single = b2.split('@');
						this.LocationbannerFrm = [single];
						// this.LocationbannerFrm = [locdat[0]['loc_name']+'@'+''.split('@')];
					}
				}

				this.locationData = this.LocstorageData['slug'];
			}

			if (sess) {
				this.productData = sess;
				let FindstorageTypeDatasd = this.searchData['selfstoragepricelist']['Data'][0]['child'];
				let dat = FindstorageTypeDatasd.filter(x => x['id'] === sess);
				this.storageChildData = dat;

				sessionStorage.removeItem('subcat');
			}

			let freeTrailerArr: any = [];
			let freeTrailer = this.free_trailer_message;
			if (freeTrailer.indexOf('\r\n') > -1) {
				freeTrailerArr = freeTrailer.split('\r\n');
			}
			this.free_trailer_message_data = freeTrailerArr;
			this.minDate = new Date();
			this.minDate.setDate(this.minDate.getDate());

			let storage = this.searchData['selfstoragepricelist']['Data'][0]['parent']['slug'].split('-');
			this.storageName = this.titleCase(storage[0]);
			
			/* Start - Below script inserted for the Google reviews*/
				if (data['selfstoragepricelist']['SEO']['googlereview'] != null || data['selfstoragepricelist']['SEO']['googlereview'] != undefined) {
					var reivewjsonData = JSON.parse(data['selfstoragepricelist']['SEO']['googlereview']);
					this.genReviewCnt = reivewjsonData.loctotReview;
					this.getnRatingCnt = reivewjsonData.locRatings;
					//console.log("=========="+reivewjsonData.loctotReview);
					reivewjsonData = JSON.parse(reivewjsonData.generalReviews);
					
					//console.log(this.WhyUs.service);
					
					//this.GoogleReviews = reivewjsonData as string[];
				}
			/* End - Below script inserted for the Google reviews*/

			return new Promise(resolve => {
				const scriptElementHeader = document.createElement('script');
				// scriptElementHeader.src = "";
				scriptElementHeader.innerHTML = data['seo_header_content'];
				scriptElementHeader.onload = resolve;
				document.head.appendChild(scriptElementHeader);

				const scriptElementFooter = document.createElement('script');
				// scriptElementFooter.src = "";
				scriptElementFooter.innerHTML = data['seo_footer_content'];
				scriptElementFooter.onload = resolve;
				document.body.appendChild(scriptElementFooter);

				const scriptElementSchema = document.createElement('script');
				scriptElementSchema.type = "application/ld+json";
				// scriptElementSchema.src = "";
				scriptElementSchema.innerHTML = data['schema'];
				scriptElementSchema.onload = resolve;
				document.body.appendChild(scriptElementSchema);

				const BreadcrumbSchema = document.createElement('script');
				BreadcrumbSchema.type = "application/ld+json";
				BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"' + this.storageParentData['cat_name'] + '","item":"' + this.BaseUrl + 'self-storage-price-list/' + this.storageParentData.slug + '/' + this.LocstorageData['slug'] + '"}]}';
				BreadcrumbSchema.onload = resolve;
				document.body.appendChild(BreadcrumbSchema);
			});
		});
		
		this.bannerFrm = this.formBuilder.group({
			storage: [this.storageParentData['slug']],
			location: [this.LocstorageData['slug']],
		});

		this.isVisible = false;

		this.quoteForm = this.formBuilder.group({
			quote_type: [null, [Validators.required]],
			first_name: [null, [Validators.required,this.noSpaceAllowed]],
			last_name: [null, [Validators.required,this.noSpaceAllowed]],
			email: [null, [Validators.required, Validators.email]],
			contact_number: [null, [Validators.required]],
			move_date: [null, [Validators.required,this.noCharAllowedMoveDate]],
			storage_type: [null, [Validators.required]],
			contact_title: [null],
			suburb: [null],
			address: [null],
			region: [null, Validators.required],
			postcode: [null],
			move_assistance: [false],
			free_trailer: [false],
			FreeTruck: [false],
			removalist: [false],
			book_now_flag: [0],
			estimate_storage_period_year: ['0'],
			estimate_storage_period_month: ['0'],
			estimate_storage_period_week: ['0'],
			comment: [''],
			location: [null],
			locationseo: [null],
			ftValidhire: ['Bhrs', Validators.required],
			ftValidage: [true],
			ftPickupDate: [''],
			ftDropoffDate: [''],
			ftlocation: [null, [Validators.required]],
			category: [null],
			sub_category: [null],
			price_list: [null],
			request_type: ['Quote'],
			form_title: ['price_list_enquiry'],
			recaptcha: [false, [Validators.required]],
			PickupDate: [''],
			DropoffDate: [''],
			product_id: [0],
			code: [''],
			cubic: [null],
			sqm: [null],
			selectedPackg: [null],
			OfferImages: [null],
			ft_ampm_pickup:['AM'],
      		ft_ampm_dropoff:['AM'],
			frt_ampm_pickup:['AM'],
      		frt_ampm_dropoff:['AM'],
			status:['1'],
			quote_form_pricing:[1],
			vp_price_list: [null],
			vp_sub_category: [null],
			vp_category: [null],
			vp_location: [null],
			vp_product_id:[null]
		});
		this.priceForm = this.formBuilder.group({
			first_name_popup: [null, [Validators.required,this.noSpaceAllowed]],
			last_name_popup: [null, [Validators.required,this.noSpaceAllowed]],
			email_popup: [null, [Validators.required, Validators.email]],
			contact_number_popup: [null, [Validators.required]],
			contact_title_popup: [null],
			move_date_popup: [null, [Validators.required,this.noCharAllowed]],
			estimate_storage_period_month_popup: ['0'],
		});
		this.loadScript('assets/js/datePickerCustom.js');
		this.loadScript('assets/js/dateformPicker.js');
		this.rescaleCaptcha();
	}
	autocloseTooltip(){
		setTimeout(() => {document.getElementById('success_tooltip_msg').setAttribute("style","display:none")},5000);
	}
	popupPrices(product_id,price_list,sub_category_name){
		this.vp_price_list = price_list;
		this.vp_product_id = parseInt(product_id);
		this.vp_sub_category = sub_category_name;
		document.querySelector('#prices-view').classList.add('popVisible');
		document.querySelector('body,html').classList.add('fixedPopupBody');
	}
	titleCase(string) {
		var sentence = string.toLowerCase().split(" ");
		for (var i = 0; i < sentence.length; i++) {
			sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
		}
		sentence = sentence.join(" ");
		return sentence;
	}
	rescaleCaptcha() {
		document.querySelectorAll(".angular-google-recaptcha-container").forEach(function () {
			const width = document.querySelector('.captchaDiv').clientWidth;
			let scale;
			if (width < 302) {
				scale = width / 302;
			} else {
				scale = 1.0;
			}
			document.querySelector(".angular-google-recaptcha-container").setAttribute("style", "transform:scale(" + scale + ");-webkit-transform:scale(" + scale + ");transform-origin:0 0;-webkit-transform-origin:0 0;");
		});
	}
	public loadScript(url: string) {
		const body = <HTMLDivElement>document.body;
		const script = document.createElement('script');
		script.innerHTML = '';
		script.src = url;
		script.async = false;
		script.defer = true;
		body.appendChild(script);
	}
	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	noSpaceAllowed(control:FormControl){
		const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? null : { 'whitespace': true };
	}
	noCharAllowed(control:FormControl){
		const isAlphabet = control && control.value && control.value.toString() == "Invalid Date";
		if(isAlphabet){
			(<HTMLInputElement>document.getElementById('move_date_popup')).value = "";
			return {'alphabet' : true};
		}else{
			return null;
		}
	}
	noCharAllowedMoveDate(control:FormControl){ 
		const isAlphabet = control && control.value && control.value.toString() == "Invalid Date";
		if(isAlphabet){
			(<HTMLInputElement>document.getElementById('move_date')).value = "";
			return {'alphabet' : true};
		}else{
			return null;
		}
	}
	apistart(newurl = '') {
		this.api.getSpecificData(newurl).subscribe(data => {
			this.searchData = data;
			this.MetaTitle = data['selfstoragepricelist']['SEO']['MetaTitle'];
			this.title.setTitle(this.MetaTitle.replace('[subcategory_name]', 'all'))
			this.meta.updateTag({ name: 'description', content: this.MetaDescription.replace('[subcategory_name]', 'all') });
			this.MetaDescription = data['selfstoragepricelist']['SEO']['MetaDescription'];
			this.meta.updateTag({ name: 'description', content: this.MetaDescription });
			this.meta.updateTag({ name: 'keyword', content: data['selfstoragepricelist']['SEO']['MetaKeywords'] });
			if (this.LocstorageData['name'] != data['selfstoragepricelist']['Data']['location']['name']) {
				document.getElementById('locName').textContent = data['selfstoragepricelist']['Data']['location']['name'];
				this.LocstorageData = data['selfstoragepricelist']['Data']['location'];

				this.detailAdditionImage = JSON.parse(this.LocstorageData['images']);
				this.detailAdditionImageThumb = JSON.parse(this.LocstorageData['thumbimages']);
				this.storageTypeData = data['selfstoragepricelist']['Data']['Loc_Storage_Type'] as string[];
			}

			this.storageChildData = data['selfstoragepricelist']['Data'][0]['child'] as string[];
			this.filterstorageChildData = data['selfstoragepricelist']['Data'][0]['child'] as string[];
			this.storageParentData = data['selfstoragepricelist']['Data'][0]['parent'];
			this.FindstorageTypeData = data['selfstoragepricelist']['StorageType'] as string[];

			if (this.storageChildData == undefined || this.storageChildData[0]['childProduct'] == undefined) {
				document.getElementById("storageChildData").setAttribute("style", "display:block");
			} else {
				document.getElementById("storageChildData").setAttribute("style", "display:none");
			}

			let locD = [];
			let locData = (this.storageParentData['loc_name'].split(',')).sort();
			if (locData.indexOf(',') > -1) {
				locData.forEach((k1, v1) => {
					locD[v1] = k1.split('@');
				});
				this.locName = locD[2];
			} else {
				this.locName = locData[0].split('@');
			}

			let newIndex = [];
			this.FindstorageTypeData = this.searchData['selfstoragepricelist']['StorageType'];
			let dat = this.FindstorageTypeData.filter(x => x['slug'] === this.storageParentData.slug);

			if (dat[0]['loc_name'] != null) {
				if (dat[0]['loc_name'].indexOf(',') > -1) {
					let Ldata = (dat[0]['loc_name'].split(',')).sort();
					Ldata.forEach((k1, v1) => {
						newIndex[v1] = k1.split('@');
					});
					this.LocationbannerFrm = newIndex;
				} else {
					this.LocationbannerFrm = [dat[0]['loc_name'].split('@')];
				}
				this.locationData = this.LocstorageData['slug'];
			}

			this.minDate = new Date();
			this.minDate.setDate(this.minDate.getDate());
		});
		this.rescaleCaptcha();
	}
	slickInit(e) { }
	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		fade: true,
		infinite: true,
		asNavFor: '.slideConfig2'
	};
	slideConfig2 = {
		slidesToShow: 5,
		slidesToScroll: 1,
		asNavFor: '.slideConfig',
		dots: false,
		arrows: true,
		infinite: false,
		centerMode: false,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			}
		]
	};

	onScriptLoad() { }

	onScriptError() { }

	getSubCatData(event) {
		let backcount: number = parseInt(sessionStorage.getItem('goback'));
		sessionStorage.setItem("goback", (backcount + 1).toString());
		let urlData = '/self-storage-price-list/' + event.target.value;
		this.router.navigateByUrl(urlData);
		this.apistart(urlData);
		this.rescaleCaptcha();
	}

	getSubCatChildData(event) {
		this.storageChildData = this.searchData['selfstoragepricelist']['Data'][0]['child'];
		this.storageChildData.forEach((unit_item, unit_index) => { 
			if (unit_item['childProduct'] != undefined) {
				unit_item['childProduct'].forEach((child_unit_item, child_unit_index) => { 
					if(child_unit_item['hide'] == 1){
						child_unit_item['hide'] = null;
					}
				});
			}
		});
		if (event != null && event != 0 && event != -1) {
			// if (event.target.value != 0) {
			let FindData = this.searchData['selfstoragepricelist']['Data'][0]['child'];
			// use below code if use radio button
			let dat = FindData.filter(x => x['id'] === event);
			// let dat = this.FindstorageTypeData.filter(x => x['id'] === event.target.value);
			
			this.storageChildData = dat;
			if (dat[0]['childProduct'] == undefined) {
				document.getElementById('storageChildData').setAttribute("style", "display:block;");
				this.storageChildData = null;
			} else {
				document.getElementById('storageChildData').setAttribute("style", "display:none;");
			}
			this.title.setTitle(this.MetaTitle.replace('[subcategory_name]', dat[0]['name']))
			this.meta.updateTag({ name: 'description', content: this.MetaDescription.replace('[subcategory_name]', dat[0]['name']) });
		} else if(event != null && event == 0) {
			let checkchild = 0;
			this.storageChildData.forEach((k1, v1) => { 
				if (k1['childProduct'] == undefined) {
					checkchild = 0;
				} else {
					checkchild = 1;
				}
			});	
			if(checkchild == 0){
				document.getElementById('storageChildData').setAttribute("style", "display:block;");
			}else{
				document.getElementById('storageChildData').setAttribute("style", "display:none;");
			}
		}else{
			let array_ids = [];
			var count_hide = 0;
			var popular_flag = false;
			var FindData = this.searchData['selfstoragepricelist']['Data'][0]['child'];
			FindData.forEach((unit_item, unit_index) => { 
				count_hide = 0;
				if (unit_item['childProduct'] != undefined) {
					unit_item['childProduct'].forEach((child_unit_item, child_unit_index) => { 
						if(child_unit_item['popular'] == "1"){
							popular_flag = true;
							count_hide ++;
						}else{
							child_unit_item['hide'] = 1;
						}
					});
					if(count_hide > 0){
						array_ids.push(unit_item['id']);
					}	
				}
			});
			const filteredArray = FindData.filter(item => array_ids.includes(item['id']));
			FindData = filteredArray;
			if(!popular_flag){
				document.getElementById('storageChildData').setAttribute("style", "display:block;");
			}
			this.storageChildData = FindData;
		}
	}

	onSearch() {
		let backcount: number = parseInt(sessionStorage.getItem('goback'));
		sessionStorage.setItem("goback", (backcount + 1).toString());
		
		this.bannerformSumitAttempt = true;
		let estorage = (document.getElementById("storage")) as HTMLSelectElement;
		let storagesel = estorage.selectedIndex;
		let storageopt = estorage.options[storagesel];
		let storageValue = (<HTMLOptionElement>storageopt).value;

		let elocation = (document.getElementById("location")) as HTMLSelectElement;
		let locationsel = elocation.selectedIndex;
		let locationopt = elocation.options[locationsel];
		let locationValue;
		if (locationopt == undefined) {
			this.locationError = true;
			document.querySelector(".locationSelect .error-msg").setAttribute("style", "display:block;");
		} else if (locationopt.value == "") {
			this.locationError = true;
			document.querySelector(".locationSelect .error-msg").setAttribute("style", "display:block;");
		} else {
			this.locationError = false;
			locationValue = (<HTMLOptionElement>locationopt).value;
			if (storageValue != '' && locationValue != '' && storageValue.length != 0 && locationValue.length != 0) {
				let urlData = '/self-storage-price-list/'  + storageValue + '/' + locationValue;
				this.apistart(urlData);
				this.router.navigateByUrl(urlData);
			}
		}
	}

	StorageDataChange(event, formname) {
		let text = event.target.options[event.target.options.selectedIndex].text;
		event.target.parentNode.firstChild.textContent = text;
		event.target.parentNode.parentNode.nextSibling.firstChild.firstChild.textContent = "Select location";

		document.querySelector('.locationSelect').setAttribute("class", "form-group locationSelect offerNotSelected");

		if (text == "Select Storage") {
			this.storageError = true;
			this.locationError = true;
		} else {
			this.storageError = false;
			this.locationError = true;
		}

		let html = '';
		let newIndex = [];
		this.FindstorageTypeData = this.searchData['selfstoragepricelist']['StorageType'];
		let dat = this.FindstorageTypeData.filter(x => x['slug'] === event.target.value);
		if (dat[0] != undefined && dat[0]['loc_name'] != null) {
			if (dat[0]['loc_name'].indexOf(',') > -1) {
				let Ldata = (dat[0]['loc_name'].split(',')).sort();
				let locationOffers;
				if (this.DdOffers[dat[0]['id']] != undefined && this.DdOffers[dat[0]['id']] != null) {
					locationOffers = (this.DdOffers[dat[0]['id']]['location_ids']).split(',');
				}
				html += '<span class="spanOut selectlocation" id="locationSpan">Select location</span>\
					<select onchange="setSpanValue(this);changeOffer(this);" formControlName="location" id="location" title="Select Location"><option value=""> Select location </option>';
				Ldata.forEach((k1, v1) => {
					newIndex[v1] = k1.split('@');
					if (this.DdOffers[dat[0]['id']] != undefined && this.DdOffers[dat[0]['id']] != null) {
						let offer: any;
						offer = locationOffers.filter(x => x === k1.split('@')[1]);
						if (offer.length > 0) {
							html += '<option value="' + k1.split('@')[2] + '" id="' + k1.split('@')[2] + '" data-offer="' + this.DdOffers[dat[0]['id']]['offer_text'] + '">' + k1.split('@')[0];
							if (this.DdOffers[dat[0]['id']]['offer_text'].length > 0){
								html += ' ('; 
								html += this.DdOffers[dat[0]['id']]['offer_text'];
								html += ')';
							}
								html += '</option>';
						} else {
							html += '<option value="' + k1.split('@')[2] + '" id="' + k1.split('@')[2] + '" data-offer="">' + k1.split('@')[0] + '</option>';
						}
					} else {
						html += '<option value="' + k1.split('@')[2] + '" id="' + k1.split('@')[2] + '" data-offer="">' + k1.split('@')[0] + '</option>';
					}

					// html += '<option value="' + k1.split('@')[2] + '" id="' + k1.split('@')[2] + '">' + k1.split('@')[0] + '</option>';
				});
				html += '</select>';
				document.getElementById('locationDiv').innerHTML = html;
				document.getElementById('offerText').innerHTML = '';
				// this.LocationbannerFrm = newIndex;
				// event.target.parentNode.parentNode.nextSibling.firstChild.firstChild.textContent = this.LocstorageData['name'];
				this.locationError = false;
			} else {
				html += '<span class="spanOut selectlocation" id="locationSpan">Select location</span>\
					<select onchange="setSpanValue(this);changeOffer(this);" formControlName="location" id="location" title="Select Location"><option value=""> Select location </option>';
				let locationOffers;
				let offer: any;
				if (this.DdOffers[dat[0]['id']] != undefined && this.DdOffers[dat[0]['id']] != null) {
					locationOffers = (this.DdOffers[dat[0]['id']]['location_ids']).split(',');
					offer = locationOffers.filter(x => x === dat[0]['loc_name'].split('@')[1]);
					if (offer.length > 0) {
						html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" id="' + dat[0]['loc_name'].split('@')[2] + '" data-offer="' + this.DdOffers[dat[0]['id']]['offer_text'] + '">' + dat[0]['loc_name'].split('@')[0];
						if (this.DdOffers[dat[0]['id']]['offer_text'].length > 0){
							html += ' (';
							html += this.DdOffers[dat[0]['id']]['offer_text'];
							html += ')';
						}
						 '</option>';
					} else {
						html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" id="' + dat[0]['loc_name'].split('@')[2] + '" data-offer="">' + dat[0]['loc_name'].split('@')[0] + '</option>';
					}
				} else {
					html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" id="' + dat[0]['loc_name'].split('@')[2] + '" data-offer="">' + dat[0]['loc_name'].split('@')[0] + '</option>';
				}

				// html += '<option value="' + dat[0]['loc_name'].split('@')[2] + '" id="' + dat[0]['loc_name'].split('@')[2] + '">' + dat[0]['loc_name'].split('@')[0] + '</option>';
				html += '</select>';
				document.getElementById('locationDiv').innerHTML = html;
				document.getElementById("offerText").innerHTML = '';
				// this.LocationbannerFrm = [dat[0]['loc_name'].split('@')];
			}
		} else {
			html += '<span class="spanOut selectlocation" id="locationSpan">Select location</span>\
					<select onchange="setSpanValue(this);changeOffer(this);" formControlName="location" id="location" title="Select Location">\
					<option value=""> Select location </option>';
			html += '</select>';
			document.getElementById('locationDiv').innerHTML = html;
			document.getElementById("offerText").innerHTML = '';
			// this.LocationbannerFrm = null;
		}
		this.locationData = null;
	}

	setSpanValue(event) {
		let text = event.target.options[event.target.options.selectedIndex].text;
		text = text.split("(");
		event.target.parentNode.firstChild.textContent = text[0];
		if (text == "Select location") {
			this.locationError = true;
		} else {
			this.locationError = false;
		}
	}
	
	getQuotePriceForm(event, sub_category, i, displayPrice,current_price) {
		(<HTMLInputElement>document.getElementById('display_current_price')).innerHTML = "Price: <strong>$"+current_price+"/Month</strong>";
		document.getElementById('success_tooltip_msg').setAttribute("style","display:none");
		if(displayPrice != 0 ){
			document.querySelector('.quoteForm').setAttribute('id','book_now_form');
		}else if(displayPrice == 0){
			document.querySelector('.quoteForm').setAttribute('id','quote_now');			
		}
		document.getElementById("product_id").setAttribute("value", event.id);
		document.getElementById("code").setAttribute("value", event.code);		
		// document.getElementById('monthly_rack_rate' + event['id']).setAttribute('clicked', 'clicked');
		let checkMonthly = document.getElementById('monthly_rack_rate' + event['id']);
		if (checkMonthly != null) {
			checkMonthly.click();
		}

		let special = document.getElementById('special_price' + event['id']);
		if (special != null) {
			special.click();
		}
		let packageId = 0;

		if (this.selectedPackgData != null) {
			packageId = this.selectedPackgData.id.replace(/^\D+/g, '');
		}

		if (packageId == event['id'] || displayPrice == 0) {
			this.isVisible = true;
			document.querySelector('#tab-11').classList.add('current');
			document.querySelector('#tab-11').classList.add('in');

			document.querySelector('#tab-00').classList.remove('current');
			document.querySelector('#tab-00').classList.remove('in');

			document.querySelector('#step1').classList.add('disabledli');
			document.querySelector('#step1').classList.remove('current');

			document.querySelector('#step2').classList.remove('disabledli');
			document.querySelector('#step2').classList.add('current');

			document.querySelector('.contentMainSec').classList.remove('step1');
			document.querySelector('.contentMainSec').classList.add('step2');

			this.quoteUpperDetails = event;
			this.quoteUpperDetails.first_name = this.quoteForm.value.first_name;
			this.sub_category = sub_category;
			if (displayPrice == 0) {
				this.selectedPackgPrice = '';
				this.selectedPackgDuration = "-";
				this.selectedPackg = 'null';
			}

			window.scroll(0, 0);
			this.rescaleCaptcha();
		} else {
			document.querySelectorAll('.priceTest').forEach(element => {
				element.setAttribute("style", "display:none");
			});
			document.querySelector('#priceerror' + i + '--' + event['id']).setAttribute('style', 'display:block');
		}
	}

	updatecss() {
		this.isVisible = false;
		document.querySelector('#step2').classList.remove('current');
		document.querySelector('#step2').classList.add('disabledli');

		document.querySelector('#step1').classList.remove('disabledli');
		document.querySelector('#step1').classList.add('current');

		document.querySelector('#tab-11').classList.remove('current');
		document.querySelector('#tab-11').classList.remove('in');

		document.querySelector('#tab-00').classList.add('current');
		document.querySelector('#tab-00').classList.add('in');

		document.querySelector('.contentMainSec').classList.remove('step2');
		document.querySelector('.contentMainSec').classList.add('step1');

		// this.quoteForm.reset();
		// below code used to skip to reset form value;
		// const exclude: string[] = ['formControlName1', 'formControlName2'];
		const exclude: string[] = ['name', 'address', 'suburb', 'postcode', 'comment'];
		// document.querySelector('.selectTitle').textContent = 'Title';
		
		Object.keys(this.quoteForm.controls).forEach(key => {
			if (exclude.findIndex(q => q === key) >= 0) {
				this.quoteForm.get(key).reset();
			}
		});
	}

	isFieldValid(field: string) {
		if(this.quoteForm.get(field).invalid && this.quoteForm.get(field).touched){
			return true;
		}
		if(this.formSumitAttempt && this.quoteForm.get(field).invalid && this.quoteForm.get(field).untouched ){
			return true;
		}
		//return ((!this.quoteForm.get(field).valid && this.quoteForm.get(field).touched) || (this.quoteForm.get(field).untouched && this.formSumitAttempt));
	}

	isPopupFieldValid(field: string) {
		return ((!this.priceForm.get(field).valid && this.priceForm.get(field).touched) || (this.priceForm.get(field).untouched && this.formSumitAttempt));
	}

	displayFieldCss(field: string) {
		return { 'has-error': this.isFieldValid(field), 'has-feedback': this.isFieldValid(field) };
	}
	convert24Time(time) {
		var hours = Number(time.match(/^(\d+)/)[1]);
		var minutes = Number(time.match(/:(\d+)/)[1]);
		var AMPM = time.match(/\s(.*)$/)[1];
		if (AMPM == "PM" && hours < 12) hours = hours + 12;
		if (AMPM == "AM" && hours == 12) hours = hours - 12;
		var sHours = hours.toString();
		var sMinutes = minutes.toString();
		if (hours < 10) sHours = "0" + sHours;
		if (minutes < 10) sMinutes = "0" + sMinutes;
		return sHours + ":" + sMinutes;
	}
	onSubmit() {
		this.quoteForm.controls['region'].setValue(this.statedd);
		this.formSumitAttempt = true;
		let productId = (<HTMLInputElement>document.getElementById('product_id')).value;
		let getOfferImages = document.querySelectorAll('input[name = "OfferImages[' + productId + '][]"]');
		let ImagesArray = [];
		getOfferImages.forEach(element => {
			let ImageElement = element.getAttribute("value");
			ImagesArray = ImagesArray.concat(ImageElement.split('/').pop());
		});

		this.quoteForm.controls['OfferImages'].setValue(ImagesArray);
		this.quoteForm.controls['location'].setValue(this.LocstorageData['name']);
		this.quoteForm.controls['locationseo'].setValue(this.LocstorageData['slug']);
		this.quoteForm.controls['category'].setValue(this.storageParentData['cat_name']);
		this.quoteForm.controls['sub_category'].setValue(this.sub_category);
		this.quoteForm.controls['price_list'].setValue(this.quoteUpperDetails.name);
		this.quoteForm.controls['sqm'].setValue(this.quoteUpperDetails.sqm);
		this.quoteForm.controls['cubic'].setValue(this.quoteUpperDetails.cubic_m);
		this.quoteForm.controls['product_id'].setValue((<HTMLInputElement>document.getElementById('product_id')).value);
		this.quoteForm.controls['code'].setValue((<HTMLInputElement>document.getElementById('code')).value);
		var quote_type = document.querySelector('input[name="quote_type"]:checked');

		if (quote_type) {
			this.quoteForm.controls['quote_type'].setValue(quote_type.getAttribute('value'));
		} else {
			this.quoteForm.controls['quote_type'].setValue(0);
		}
		//this.quoteForm.value.move_date = this.datepipe.transform(this.quoteForm.value.move_date, 'yyyy-MM-dd');
		// this.quoteForm.controls['DropoffDate'].setValue((<HTMLInputElement>document.getElementById('DropoffDates')).value);
		// this.quoteForm.controls['PickupDate'].setValue((<HTMLInputElement>document.getElementById('PickupDates')).value);

		// this.quoteForm.controls['ftDropoffDate'].setValue((<HTMLInputElement>document.getElementById('DropoffDate')).value);
		// this.quoteForm.controls['ftPickupDate'].setValue((<HTMLInputElement>document.getElementById('PickupDate')).value);
		this.quoteForm.controls['status'].setValue('1');
		if((<HTMLInputElement>document.getElementById('move_date')).value != this.user_move_date){
			this.quoteForm.controls['move_date'].setValue((<HTMLInputElement>document.getElementById('move_date')).value);
			this.user_move_date = (<HTMLInputElement>document.getElementById('move_date')).value;
		}
		
		// this.quoteForm.value.status = '1';
		// this.quoteForm.value.ftDropoffDate = (<HTMLInputElement>document.getElementById('DropoffDate')).value;
		// this.quoteForm.value.ftPickupDate = (<HTMLInputElement>document.getElementById('PickupDate')).value;
		this.quoteForm.controls['selectedPackg'].setValue(this.selectedPackg);
		let FreeTruck = <HTMLInputElement>document.getElementById('FreeTruck');
		let depend = <HTMLInputElement>document.getElementById('free_trailer');
		let errorCount = 0;
		let formPost = false;
		let storageLen = document.querySelectorAll('input[name="storage_type"]:checked');
		if (storageLen.length > 0){
			var storageOp = document.querySelector('input[name="storage_type"]:checked');
		}
		if (this.storageName == 'Personal' || this.storageName == 'Business' ) {
			this.storage_typeRadio = false;
		}
		if (storageOp == undefined || storageOp.getAttribute("value") == '' || storageOp.getAttribute("value") == null){
			this.storage_typeRadio = true;
		}else{
			this.storage_typeRadio = false;
		}
		
		if (storageOp != undefined && this.storage_typeRadio == false){
			this.quoteForm.controls['storage_type'].setValue(storageOp.getAttribute("value"));
		}
		if (FreeTruck.checked) {
			if (this.quoteForm.value.ftlocation !== '') {
				this.ftlocationError = false;
			} else {
				errorCount++;
				this.ftlocationError = true;
			}
		} else {
			this.ftlocationError = false;
		}

		if (FreeTruck.checked) {
			if(this.quoteForm.value.ftValidhire == "24hrs"){
				this.quoteForm.value.ft_ampm_pickup = '';
				this.quoteForm.value.ft_ampm_dropoff = '';
			}
		} else {
			this.ftPickupDateError = false;
			this.ftDropoffDateError = false;
		}
		if (errorCount == 0) {
			formPost = true;
		} else {
			formPost = false;
		}
		// if (this.quoteForm.value.PickupDate != '' && this.quoteForm.value.PickupDate != undefined) {	
		// 	let PickupDate = this.quoteForm.value.PickupDate.split(/\-|\s/);
		// 	PickupDate = PickupDate[0] + '-'+PickupDate[1] + '-'+PickupDate[2] + " " + this.convert24Time(PickupDate[3] + " " + PickupDate[4]);
		// 	this.quoteForm.value.PickupDate = PickupDate;
		// }
		// if (this.quoteForm.value.DropoffDate != '' && this.quoteForm.value.DropoffDate != undefined) {	
		// 	let DropoffDate = this.quoteForm.value.DropoffDate.split(/\-|\s/);
		// 	DropoffDate = DropoffDate[0] + '-' + DropoffDate[1] + '-' + DropoffDate[2] + " " + this.convert24Time(DropoffDate[3] + " " + DropoffDate[4]);
		// 	this.quoteForm.value.DropoffDate = DropoffDate;
		// }
		// if (this.quoteForm.value.ftPickupDate != '' && this.quoteForm.value.ftPickupDate != undefined) {	
		// 	let ftPickupDate = this.quoteForm.value.ftPickupDate.split(/\-|\s/);
		// 	ftPickupDate = ftPickupDate[0] + '-'+ftPickupDate[1] + '-'+ftPickupDate[2] + " " + this.convert24Time(ftPickupDate[3] + " " + ftPickupDate[4]);
		// 	this.quoteForm.value.ftPickupDate = ftPickupDate;
		// }
		// if (this.quoteForm.value.ftDropoffDate != '' && this.quoteForm.value.ftDropoffDate != undefined) {	
		// 	let ftDropoffDate = this.quoteForm.value.ftDropoffDate.split(/\-|\s/);
		// 	ftDropoffDate = ftDropoffDate[0] + '-' + ftDropoffDate[1] + '-' + ftDropoffDate[2] + " " + this.convert24Time(ftDropoffDate[3] + " " + ftDropoffDate[4]);
		// 	this.quoteForm.value.ftDropoffDate = ftDropoffDate;
		// }
		
		//this.formSumitAttempt = true;
		//console.log(this.quoteForm.value);
		if (this.quoteForm.valid && formPost == true) {
			document.getElementById('submit').setAttribute('style', "pointer-events:none;");
			document.getElementById('step2Loading').setAttribute('style', "display:block;");			
			// if (this.selectedPackgPrice.length > 0) {
			// 	this.quoteForm.value.book_now_flag = true;
			// }
			this.api.postData(this.quoteForm.value).subscribe(data => {
				if (data['status'] != undefined && data['status'] == 200 && data['EmailMessage'] == "Email Sent.") {
					let urlData = '/self-storage-price-list/' + this.storageParentData['slug'] + '/' + this.locName[2];
					localStorage.setItem('locName', this.locName[2]);
					localStorage.setItem('returnUrl', urlData);
					let url = data['thankyou']+'/'+this.storageParentData['slug'];
					window.location.href=url;
				} else {
					document.getElementById('submit').setAttribute('style', "pointer-events:all");
					document.getElementById('errorMsg').setAttribute("style", "display:block");
					document.getElementById('step2Loading').setAttribute('style', "display:none;");
					(<HTMLInputElement>document.getElementById('errorMsg')).textContent = data['EmailMessage'];
					let urlData = '/self-storage-price-list/' + this.storageParentData['slug'] + '/' + this.locName[2];
					this.apistart(urlData);
				}
			});
		}

	}

	readmoreless(data) {
		let currentStatus = document.getElementById('moreless' + data).textContent;
		if (currentStatus == "Read More") {
			document.getElementById('moreless' + data).textContent = "Read Less";
			document.getElementById('fullPos' + data).setAttribute("style", "display:inline");
			document.getElementById('fullPos' + data).setAttribute("style", "margin-right:5px");
			document.getElementById('limitedPos' + data).setAttribute("style", "display:none");
		} else {
			document.getElementById('moreless' + data).textContent = "Read More";
			document.getElementById('fullPos' + data).setAttribute("style", "display:none");
			document.getElementById('limitedPos' + data).setAttribute("style", "display:inline");
		}
	}
	openTruckHire(event) {
		let check = <HTMLInputElement>document.getElementById('FreeTruck');
		if (check.checked) {
			// console.log(this.quoteForm.get('free_trailer').value)	
			// if (this.quoteForm.get('free_trailer').value == false) {
			document.querySelector('.truck-main-group').setAttribute('style', 'display:block');
		} else {
			document.querySelector('.truck-main-group').setAttribute('style', 'display:none');
		}
	}
	openDivDate(event) {
		let check = <HTMLInputElement>document.getElementById('free_trailer');
	}
	selectRadioData(event) {
		this.selectedPackg = event.target.value;
		this.selectedPackgData = event.target;
		this.selectedPackgPrice = this.selectedPackg.replace(/^\D+/g, '');
		if ((this.selectedPackg.split('=')[0]).split('_')[0] == 'special') {
			this.selectedPackgDuration = '/ Month - (' + (this.selectedPackg.split('=')[0]).split('_')[0] + ' Price)';
		} else {
			// this.selectedPackgDuration = '- (' + (this.selectedPackg.split('=')[0]).split('_')[0] + ')';
			this.selectedPackgDuration = '/ Month';
		}
	}

	getChangeLocationData(event){
		let backcount:number = parseInt(sessionStorage.getItem('goback'));
		sessionStorage.setItem("goback", (backcount + 1).toString());
		let urlData = '/self-storage-price-list/' + event.target.value;
		this.router.navigateByUrl(urlData);
		this.apistart(urlData);
		this.rescaleCaptcha();
	}

	goback(){
		if (window.history.length > 2) {
			let countgoback = sessionStorage.getItem("goback");
			sessionStorage.setItem("goback",'0');
			window.history.go(-countgoback);
		} else {
			this.router.navigateByUrl('');
		}
	}
	setCookie(name,value) {
		var expires = "";
		var date = new Date();
		date.setTime(date.getTime() + (24*60*60*1000));
		expires = "; expires=" + date.toUTCString();
		document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	}
	getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}
	eraseCookie(name) {   
		document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}
}
