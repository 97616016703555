import { Component, OnInit } from '@angular/core';
import { ApiService } from "../api.service";
import { DatePipe } from '@angular/common';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
@Component({
  selector: 'app-proposal-pricelist',
  templateUrl: './proposal-pricelist.component.html',
  styleUrls: ['./proposal-pricelist.component.css']
})
export class ProposalPricelistComponent implements OnInit {
  proposalPriceData: any = Object;
  pageDetails: any = Object;
  storageBannerData: any ;
  locTr: any ='';
  Location : string[];
  myRecaptcha: boolean;
  form: FormGroup;
  displayError: any = Object;
  private formSumitAttempt: boolean; 
  minDate:Date;

  constructor(private api:ApiService,private router:Router,private formBuilder: FormBuilder,public datepipe: DatePipe,private route:ActivatedRoute) { }
  data:any;
  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  ngOnInit() {
    this.api.getData().subscribe(data => {
      if(data['error']){
        document.getElementById('proposalError').setAttribute("style","display:block");
        document.getElementById('proposalPage').setAttribute("style","display:none");
      }else{
        this.proposalPriceData = data;
        if(data['proposalpricelist']['Data'] != null || data['proposalpricelist']['Data'] != undefined){
          this.pageDetails  = data['proposalpricelist']['Data'];
          this.Location = JSON.parse(this.pageDetails['all_location']);
        }
        
        if(data['proposalpricelist']['Banner'] != null || data['proposalpricelist']['Banner'] != undefined){
          this.storageBannerData  = data['proposalpricelist']['Banner'];
          if(data['proposalpricelist']['Banner']['image_name'] != undefined && data['proposalpricelist']['Banner']['image_name'] != ''){
            this.storageBannerData  = data['proposalpricelist']['Banner']['image_name'];
          }else{
            this.storageBannerData  = data['proposalpricelist']['Banner']['default'];
          }
        }
      }

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);
      });
    });

    this.form = this.formBuilder.group({
      move_date: [this.pageDetails['move_date']],
      storage_type: [this.pageDetails['storage_type']],
      estimate_storage_period_year: [this.pageDetails['estimate_storage_period_year']],
      estimate_storage_period_month: [this.pageDetails['estimate_storage_period_month']],
      estimate_storage_period_week: [this.pageDetails['estimate_storage_period_week']],
      comment: [''],
      additional_comment: [''],
      form_title :['proposal_pricelist_enquiry'],
      recaptcha:[false, [Validators.required]],
      locTr:[false, [Validators.required]],
    });
    
    this.loadScript('../../assets/js/datePickerCustom.js');
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
  }

  setSpanValue(event) {
    let text = event.target.options[event.target.options.selectedIndex].text;
    event.target.parentNode.firstChild.textContent = text;  
  }
  onScriptLoad() {}

  onScriptError() {}

  isFieldValid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSumitAttempt)
    );
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  getTrDataLoc(event){
    let dat = this.Location.filter(x => x['location'] === event.target.value);
    this.locTr = dat.concat(this.locTr);
    var filtered = this.locTr.filter(function (el) {
      return el != '';
    });
    this.locTr = filtered;
  }
  clickChangeEvent(event){
    if(event.target.value == 'Yes'){
      (<HTMLInputElement>document.getElementById(event.target.id)).value = 'No'
    }else if(event.target.value == ''){
      (<HTMLInputElement>document.getElementById(event.target.id)).value = 'Yes'
    }else if(event.target.value == 'No'){
      (<HTMLInputElement>document.getElementById(event.target.id)).value = 'Yes'
    }else{
      (<HTMLInputElement>document.getElementById(event.target.id)).value = 'No'
    }
  }
  onSubmit() {
    this.form.value.free_truck=(<HTMLInputElement>document.getElementById('free_truck')).value;
    this.form.value.free_trailer=(<HTMLInputElement>document.getElementById('free_trailer')).value;
    this.form.value.free_truck_check=(<HTMLInputElement>document.getElementById('free_truck_check')).value;
    this.form.value.removalist=(<HTMLInputElement>document.getElementById('removalist')).value;
 
    this.form.value.locTrData = this.locTr;
    this.form.value.userid = this.route.params['_value']['userid'];
    this.formSumitAttempt = true;
    if (this.form.valid) {
      this.api.postData(this.form.value).subscribe(data=>{
        if(data['status'] == 200 && data['EmailMessage'] == "Email Sent."){
          this.router.navigateByUrl('/thank-you');
        }else{
          (<HTMLInputElement>document.getElementById('errorMsg')).textContent = data['EmailMessage'];
        }
      });
    }
  }  
}
