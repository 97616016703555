import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-pay-my-account-location',
  templateUrl: './pay-my-account-location.component.html',
  styleUrls: ['./pay-my-account-location.component.css']
})
export class PayMyAccountLocationComponent implements OnInit {
  contactusData: any = Object;
  locationDetailsData: any = Object;
  locationUrl: string[];
  BaseUrl:string;
  Locations: string[];
  constructor(private api:ApiService,private router:Router,private meta:Meta,private title:Title, @Inject('BASE_URL') baseUrl: string) { 
    this.BaseUrl = baseUrl;
  }

  ngOnInit() {
    this.api.getData().subscribe(data => {
      this.contactusData  = data;
      this.title.setTitle(data['paymyaccountlocation']['SEO']['MetaTitle']);
      // this.meta.updateTag({ name: 'title', content: data['paymyaccountlocation']['SEO']['MetaTitle'] });
			this.meta.updateTag({ name: 'description', content: data['paymyaccountlocation']['SEO']['MetaDescription'] });
      this.meta.updateTag({ name: 'keywords', content: data['paymyaccountlocation']['SEO']['MetaKeywords'] });

      if(data['paymyaccountlocation']['LocationData'] != null || data['paymyaccountlocation']['LocationData'] != undefined){
        this.locationDetailsData = data['paymyaccountlocation']['LocationData'];
      }
      if(data['paymyaccountlocation']['locationUrl'] != null || data['paymyaccountlocation']['locationUrl'] != undefined){
        this.locationUrl = data['paymyaccountlocation']['locationUrl'];
        let html = '<iframe src="'+this.locationUrl+'" frameborder="0"></iframe>';
        document.getElementById('thirdPartyUrl').innerHTML = html; 
      }

      if(data['paymyaccountlocation']['Locations'] != null || data['paymyaccountlocation']['Locations'] != undefined){
        this.Locations = data['paymyaccountlocation']['Locations'];
      }

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);
      });
    });
  }
}
