import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  storageError: boolean = false;
  locationError: boolean = false;
  PickupDateError: boolean = false;
  DropoffDateError: boolean = false;

  contactusData: any = Object;
  pageDetails: string[];
  storageBannerData: any;
  myRecaptcha: boolean;
  form: FormGroup;
  displayError: any = Object;
  private formSumitAttempt: boolean;
  FindstorageTypeData: string[];
  LocationQuote: string[];
  StorageType: string[];
  minDate: Date;
  free_trailer_message_data: string[];
  free_trailer_message: any;
  BaseUrl:string;
  constructor(@Inject('BASE_URL') baseUrl: string,private api: ApiService, private router: Router, private formBuilder: FormBuilder, private meta: Meta, private title: Title, public datepipe: DatePipe) {
    this.BaseUrl = baseUrl;
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
  }
  slug = this.router.url;
  data: any;
  lat: number;
  lng: number;
  ngOnInit() {
    this.loadScript('assets/js/datePickerCustom.js');
    this.loadScript('assets/js/equalheight.js');

    this.api.getData().subscribe(data => {
      this.contactusData = data;
      this.title.setTitle(data['contactus']['SEO']['MetaTitle']);
      // this.meta.updateTag({ name: 'title', content: data['contactus']['SEO']['MetaTitle'] });
      this.meta.updateTag({ name: 'description', content: data['contactus']['SEO']['MetaDescription'] });
      this.meta.updateTag({ name: 'keywords', content: data['contactus']['SEO']['MetaKeywords'] });

      if (data['contactus']['LocationData'] != null || data['contactus']['LocationData'] != undefined) {
        let pageDetailsData:any = data['contactus']['LocationData'];
        let NewIndexPageDetails = [];
        pageDetailsData.forEach((k,v) => {
          NewIndexPageDetails[v] = k;
          NewIndexPageDetails[v]['phone'] = k.phone.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3");
          NewIndexPageDetails[v]['fax'] = k.fax.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3");
        });
        this.pageDetails = NewIndexPageDetails;
        this.lat = parseFloat(this.pageDetails['latitude']);
        this.lng = parseFloat(this.pageDetails['longitude']);
      }
      if (data['contactus']['Banner'] != null || data['contactus']['Banner'] != undefined) {
        this.storageBannerData = data['contactus']['Banner'];
        if (data['contactus']['Banner']['image_name'] != undefined && data['contactus']['Banner']['image_name'] != '') {
          this.storageBannerData = data['contactus']['Banner']['image_name'];
        } else {
          this.storageBannerData = data['contactus']['Banner']['default'];
        }
      }
      if (data['contactus']['StorageType'] != null || data['contactus']['StorageType'] != undefined) {
        this.StorageType = data['contactus']['StorageType'] as string[];
      }
      this.free_trailer_message = data['free_trailer_message'];
      let freeTrailerArr: any = [];
      let freeTrailer = this.free_trailer_message;
      if (freeTrailer.indexOf('\r\n') > -1) {
        freeTrailerArr = freeTrailer.split('\r\n');
      }
      this.free_trailer_message_data = freeTrailerArr;

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";

        BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"Contact Us","item":"' + location.href + '"}]}';
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
      });
    });

    this.form = this.formBuilder.group({
      fname: [null, Validators.required],
      lname: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      contact_number: [null, [Validators.required]],
      storage_option: [''],
      location: [''],
      move_date: [null, [Validators.required]],
      suburb: [null],
      address: [null],
      move_assistance: [false],
      free_trailer: [false],
      removalist: [false],
      estimate_storage_period_year: ['0'],
      estimate_storage_period_month: ['0'],
      estimate_storage_period_week: ['0'],
      comment: [''],
      form_title: ['contactus_enquiry'],
      recaptcha: [false, [Validators.required]],
      PickupDate: [''],
      DropoffDate: [''],
    });
    this.rescaleCaptcha();
  }

  rescaleCaptcha() {
    document.querySelectorAll(".angular-google-recaptcha-container").forEach(function () {
      var width = document.querySelector('.captchaDiv').clientWidth;
      var scale;
      if (width < 302) {
        scale = width / 302;
      } else {
        scale = 1.0;
      }
      document.querySelector(".angular-google-recaptcha-container").setAttribute("style", "transform:scale(" + scale + ");-webkit-transform:scale(" + scale + ");transform-origin:0 0;-webkit-transform-origin:0 0;");
    });
  }
  popup() {
    document.querySelector('#freeTrailer').classList.add('popVisible');
    document.querySelector('body,html').classList.add('fixedPopupBody');
  }
  closepopup() {
    document.querySelector('#freeTrailer').classList.remove("popVisible");
    document.querySelector("body, html").classList.remove("fixedPopupBody");
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onScriptLoad() { }

  onScriptError() { }

  isFieldValid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSumitAttempt)
    );
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  onSubmit() {
    this.formSumitAttempt = true;
    let estorage = (document.getElementById("storage")) as HTMLSelectElement;
    let storagesel = estorage.selectedIndex;
    let storageopt = estorage.options[storagesel];
    if (storageopt == undefined) {
      this.storageError = true;
    } else if (storageopt.value == "") {
      this.storageError = true;
    } else {
      this.storageError = false;
    }

    let elocation = (document.getElementById("location")) as HTMLSelectElement;
    let locationsel = elocation.selectedIndex;
    let locationopt = elocation.options[locationsel];
    let locationValue;
    if (locationopt == undefined) {
      this.locationError = true;
      document.querySelector(".locationSelect .error-msg").setAttribute("style", "display:block;");
    } else if (locationopt.value == "") {
      this.locationError = true;
      document.querySelector(".locationSelect .error-msg").setAttribute("style", "display:block");
    } else {
      this.locationError = false;
      document.querySelector('.selectlocation').textContent = locationopt.text;
      locationValue = (<HTMLOptionElement>locationopt).value;
    }

    if (this.form.valid && this.locationError == false && this.storageError == false) {
      this.form.value.location = locationopt.text;
      this.form.value.DropoffDate = (<HTMLInputElement>document.getElementById('DropoffDates')).value;
      this.form.value.PickupDate = (<HTMLInputElement>document.getElementById('PickupDates')).value;
      let depend = <HTMLInputElement>document.getElementById('free_trailer');
      if (depend.checked) {
        if (this.form.value.DropoffDate != '' && this.form.value.PickupDate != '') {
          document.getElementById('contactusSubmit').setAttribute('style','pointer-events:none');
          this.PickupDateError = false;
          this.DropoffDateError = false;
          this.api.postData(this.form.value).subscribe(data => {
            if (data['status'] == 200 && data['EmailMessage'] == "Email Sent.") {
              this.router.navigateByUrl('/thank-you');
            } else {
              document.getElementById('contactusSubmit').setAttribute('style', 'pointer-events:all');
              document.getElementById('errorMsg').setAttribute("style", "display:block");
              (<HTMLInputElement>document.getElementById('errorMsg')).textContent = data['EmailMessage'];
            }
          });
        } else {
          this.PickupDateError = true;
          this.DropoffDateError = true;
        }
      } else {
        document.getElementById('contactusSubmit').setAttribute('style', 'pointer-events:none');
        this.api.postData(this.form.value).subscribe(data => {
          if (data['status'] == 200 && data['EmailMessage'] == "Email Sent.") {
            this.router.navigateByUrl('/thank-you');
          } else {
            document.getElementById('contactusSubmit').setAttribute('style', 'pointer-events:all');
            document.getElementById('errorMsg').setAttribute("style", "display:block");
            (<HTMLInputElement>document.getElementById('errorMsg')).textContent = data['EmailMessage'];
          }
        });
      }
    }
  }

  StorageDataChange(event, formname) {
    this.setSpanValueQuoteForm(event);
    let text = event.target.options[event.target.options.selectedIndex].text;
    event.target.parentNode.parentNode.parentNode.parentNode.children[5].firstChild.firstChild.firstChild.textContent = "Select storage location"

    if (text == "Select Storage") {
      this.storageError = true;
      // this.locationError = true;
    } else {
      this.storageError = false;
      // this.locationError = true;
    }
    let html = '';
    var newIndex = [];
    this.StorageType = this.contactusData['contactus']['StorageType'];
    var dat = this.StorageType.filter(x => x['cat_name'] === event.target.value);
    if (dat[0] != undefined && dat[0]['loc_name'] != null) {
      if (dat[0]['loc_name'].indexOf(',') > -1) {
        var Ldata = (dat[0]['loc_name'].split(',')).sort();
        html += '<span class="spanOut selectlocation">Storage location</span>\
				<select onchange="setSpanValue(this);" formControlName="location" id="location" title="Select Location">\
				<option value="">Select Storage location</option>';
        Ldata.forEach((k1, v1) => {
          newIndex[v1] = k1.split('@');
          html += '<option value="' + k1.split('@')[0] + '">' + k1.split('@')[0] + '</option>';
        });
        html += '</select>';
        // this.LocationbannerFrm = newIndex;
        document.getElementById("locationDiv").innerHTML = html;
      } else {
        html += '<span class="spanOut selectlocation">Storage location</span>\
				<select onchange="setSpanValue(this);" formControlName="location" id="location" title="Select Location">\
				<option value="">Select Storage location</option>';
        html += '<option value="' + dat[0]['loc_name'].split('@')[0] + '">' + dat[0]['loc_name'].split('@')[0] + '</option>';
        html += '</select>';
        document.getElementById("locationDiv").innerHTML = html;
        // this.LocationQuote = [dat[0]['loc_name'].split('@')];
      }
    } else {
      html += '<span class="spanOut selectlocation">Storage location</span>\
				<select onchange="setSpanValue(this);" formControlName="location" id="location" title="Select Location">\
				<option value="">Select Storage location</option>';
      html += '</select>';
      document.getElementById("locationDiv").innerHTML = html;
    }

  }
  setSpanValueQuoteForm(event) {
    let text = event.target.options[event.target.options.selectedIndex].text;
    event.target.parentNode.firstChild.textContent = text;

    if (text == "Select Storage location") {
      this.locationError = true;
    } else {
      this.locationError = false;
    }
  }

  openDivDate(event) {
    let check = <HTMLInputElement>document.getElementById('free_trailer');
    if (check.checked) {
      // if (this.form.get('free_trailer').value == false) {
      document.querySelector('.main-group').setAttribute('style', 'display:block');
    } else {
      document.querySelector('.main-group').setAttribute('style', 'display:none');
    }
  }
}
