import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Lightbox } from 'ngx-lightbox';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-boxes-and-supplies',
  templateUrl: './boxes-and-supplies.component.html',
  styleUrls: ['./boxes-and-supplies.component.css']
})
export class BoxesAndSuppliesComponent implements OnInit {
  boxesandsuppliesData: any = Object;
  boxesBannerData: any;
  boxesParentData: string[];
  boxesChildData: string[];
  urlData: string;
  braedcumData: string;
  cartArr:any[];
  bannerFrm: FormGroup;
  Count_img : any;
  currentpage = 1;
  BaseUrl: string;
  adminurl: string;
  private bannerformSumitAttempt: boolean;
  public p: any;
  public _albums = [];
  box_description: any;
  constructor(@Inject('BASE_URL') baseUrl: string, private api: ApiService, private router: Router, private formBuilder: FormBuilder, private _lightbox: Lightbox, private meta: Meta, private title: Title) {
    this.BaseUrl = baseUrl;
    this.cartArr = JSON.parse(localStorage.getItem('cart'));
  }
  slug = this.router.url;

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  getcurrent(event){
    this.currentpage = event;
  }

  ngOnInit() {
    let urldata = this.router.url.split('/');
    this.api.getData().subscribe(data => {
      this.boxesandsuppliesData  = data;
      this.adminurl  = data['adminUrl'];
      if (urldata[2]){
        let locdat = data['boxesandsupplies']['All_Supply']['parent'].filter(x => x['slug'] === urldata[2]);
        this.box_description = locdat[0]['description'];
        this.title.setTitle(locdat[0]['meta_title']);
        // this.meta.updateTag({ name: 'title', content: locdat[0]['MetaTitle'] });
        
        this.meta.updateTag({ name: 'description', content: locdat[0]['meta_description'] });
        this.meta.updateTag({ name: 'keywords', content: locdat[0]['meta_keyword'] });
      }else{
        this.title.setTitle(data['boxesandsupplies']['SEO']['MetaTitle']);
        // this.meta.updateTag({ name: 'title', content: data['boxesandsupplies']['SEO']['MetaTitle'] });

        this.meta.updateTag({ name: 'description', content: data['boxesandsupplies']['SEO']['MetaDescription'] });
        this.meta.updateTag({ name: 'keywords', content: data['boxesandsupplies']['SEO']['MetaKeywords'] });
      }
      
      if(data['boxesandsupplies']['Banner'] != null || data['boxesandsupplies']['Banner'] != undefined){
        if(data['boxesandsupplies']['Banner']['image_name'] != undefined && data['boxesandsupplies']['Banner']['image_name'] != ''){
          this.boxesBannerData  = data['boxesandsupplies']['Banner']['image_name'];
        }else{
          this.boxesBannerData  = data['boxesandsupplies']['Banner']['default'];
        }
      }
      if(data['boxesandsupplies']['All_Supply']['parent'] != null || data['boxesandsupplies']['All_Supply']['parent'] != undefined){
        this.boxesParentData  = data['boxesandsupplies']['All_Supply']['parent'] as string[];
      }
     
      if(data['boxesandsupplies']['All_Supply']['child'] != null || data['boxesandsupplies']['All_Supply']['child'] != undefined){
        this.boxesChildData  = data['boxesandsupplies']['All_Supply']['child'] as string[];
        this.Count_img = Object.keys(this.boxesChildData).length;
        for (let i = 0; i < this.Count_img; i++) {        
          const src = this.boxesChildData[i]['main_lg_image'];
          const caption = this.boxesChildData[i]['name'];
          const thumb = this.boxesChildData[i]['main_image'];
          const album = {
            src: src,
            caption: caption,
            thumb: thumb
          };
          this._albums.push(album);
        }
      }
    //  console.log(this.boxesParentData)
      if (this.router.url.indexOf('/') > -1) {
        let urldata = this.router.url.split('/');
        if(urldata[2]){
          this.urlData = urldata[2];
          this.boxesParentData.forEach((k1, v1) => {
            if(k1['slug'] == urldata[2]){
              this.braedcumData = k1['headertitle'];
            }
          });
        }else{
          this.braedcumData = "Boxes & Supplies";
        }
      }

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        if (this.braedcumData && this.braedcumData != "Boxes & Supplies") {
          BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"Boxes & Supplies","item":"' + this.BaseUrl + 'boxes-and-supplies"},{"@type":"ListItem","position":3,"name":"'+this.braedcumData+'","item":"' + location.href + '"}]}';
        }else{
          BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"Boxes & Supplies","item":"' + location.href + '"}]}';
        }

        const ProductSchema = document.createElement('script');
        ProductSchema.type = "application/ld+json";
        let path = document.getElementsByTagName('base')[0].href;
        let hrefPath = location.href.split(path)[1];
        if(hrefPath == "boxes-and-supplies/moving-packs-cartons"){
          ProductSchema.innerHTML ='{"@context": "https://schema.org/","@type": "Product","name": "Moving Packs","image": "'+this.adminurl+'/image-catalog/product_box/hills-self-storage-small-moving-pack-lg.jpg", "description": "Starter, small, medium, large & jumbo moving pack includes wine cartons, crystal cartons, tea chest cartons, tape gun & 2 tape.","brand": "Hills Self Storage","sku": "","offers": {"@type": "AggregateOffer","url": "'+path+'boxes-and-supplies/moving-packs-cartons","priceCurrency": "AUD","lowPrice": "45.00","highPrice": "270.00","offerCount": "5"},"aggregateRating": {"@type": "AggregateRating","ratingValue": "5","bestRating": "5","worstRating": "1","ratingCount": "1","reviewCount": "1"},"review": {"@type": "Review","name": "Storage Box","reviewBody": "We bought boxes that were great to move with and the kids are now loving playing in.","reviewRating": {"@type": "Rating","ratingValue": "5","bestRating": "5","worstRating": "1"},"datePublished": "2017-08-03","author": {"@type": "Person", "name": "Carly Wheeler"},"publisher": {"@type": "Organization", "name": "Feefo"}}}';
        }else if(hrefPath == "boxes-and-supplies/boxes"){
          ProductSchema.innerHTML ='{"@context": "https://schema.org/", "@type": "Product", "name": "Boxes", "image": "'+this.adminurl+'/image-catalog/product_box/Tea-Chest-Carton-Box-Packing-ib.jpg", "description": "Tea chest carton, crystal carton, archive carton, book & wine carton. Best for large and lighter items such as tuppaware, toys, linen, documents, and books.", "brand": "Hills Self Storage", "sku": "", "offers": {"@type": "AggregateOffer", "url": "'+path+'boxes-and-supplies/boxes", "priceCurrency": "AUD", "lowPrice": "2.80", "highPrice": "18.00", "offerCount": "5"}, "aggregateRating": {"@type": "AggregateRating", "ratingValue": "5", "bestRating": "5", "worstRating": "1", "ratingCount": "1", "reviewCount": "1"}, "review": {"@type": "Review", "name": "Packing Boxes", "reviewBody": "We bought boxes that were great to move with and the kids are now loving playing in.", "reviewRating": {"@type": "Rating", "ratingValue": "5", "bestRating": "5", "worstRating": "1"}, "datePublished": "2017-08-03", "author": {"@type": "Person", "name": "Carly Wheeler"}, "publisher": {"@type": "Organization", "name": "Feefo"} } }';
        }else if(hrefPath == "boxes-and-supplies/tapes"){
          ProductSchema.innerHTML ='{"@context": "https://schema.org/", "@type": "Product", "name": "Tape & Roll", "image": "'+this.adminurl+'/image-catalog/product_box/packing-tapes-ib.jpg", "description": "We also have heavy duty packaging tape. Turn packaging from a chore into fun with a Tape Gun.", "brand": "Hills Self Storage", "sku": "", "offers": {"@type": "AggregateOffer", "url": "'+path+'boxes-and-supplies/tapes", "priceCurrency": "AUD", "lowPrice": "3.65", "highPrice": "22.50", "offerCount": "2"}, "aggregateRating": {"@type": "AggregateRating", "ratingValue": "5", "bestRating": "5", "worstRating": "1", "ratingCount": "1", "reviewCount": "1"}, "review": {"@type": "Review", "name": "Packing Boxes", "reviewBody": "We bought boxes that were great to move with and the kids are now loving playing in.", "reviewRating": {"@type": "Rating", "ratingValue": "5", "bestRating": "5", "worstRating": "1"}, "datePublished": "2017-08-03", "author": {"@type": "Person", "name": "Carly Wheeler"}, "publisher": {"@type": "Organization", "name": "Feefo"} } }';
        }else if(hrefPath == "boxes-and-supplies/furniture-protection"){
          ProductSchema.innerHTML ='{"@context": "https://schema.org/", "@type": "Product", "name": "Furniture Protection", "image": "'+this.adminurl+'/image-catalog/product_box/1.5x1m-bubble-wrap-ib.jpg", "description": "Protect your items from damage with durable high quality bubble wrap, mattress cover, and lounge cover", "brand": "Hills Self Storage", "sku": "", "offers": {"@type": "AggregateOffer", "url": "'+path+'boxes-and-supplies/furniture-protection", "priceCurrency": "AUD", "lowPrice": "2.80", "highPrice": "9.00", "offerCount": "5"}, "aggregateRating": {"@type": "AggregateRating", "ratingValue": "5", "bestRating": "5", "worstRating": "1", "ratingCount": "1", "reviewCount": "1"}, "review": {"@type": "Review", "name": "Packing Boxes", "reviewBody": "We bought boxes that were great to move with and the kids are now loving playing in.", "reviewRating": {"@type": "Rating", "ratingValue": "5", "bestRating": "5", "worstRating": "1"}, "datePublished": "2017-08-03", "author": {"@type": "Person", "name": "Carly Wheeler"}, "publisher": {"@type": "Organization", "name": "Feefo"} } }';
        }else if(hrefPath == "boxes-and-supplies/other-supplies"){
          ProductSchema.innerHTML ='{"@context": "https://schema.org/", "@type": "Product", "name": "Box Packing Accessories", "image": "'+this.adminurl+'/image-catalog/product_box/marker-pen-black-ib.png", "description": "Keep track of everything you put into your boxes. This powder coated shelving is extra strong and ideal for archive storage amongst other uses.", "brand": "Hills Self Storage", "sku": "", "offers": {"@type": "AggregateOffer", "url": "'+path+'boxes-and-supplies/other-supplies", "priceCurrency": "AUD", "lowPrice": "2.50", "highPrice": "185.00", "offerCount": "5"}, "aggregateRating": {"@type": "AggregateRating", "ratingValue": "5", "bestRating": "5", "worstRating": "1", "ratingCount": "1", "reviewCount": "1"}, "review": {"@type": "Review", "name": "Packing Boxes", "reviewBody": "We bought boxes that were great to move with and the kids are now loving playing in.", "reviewRating": {"@type": "Rating", "ratingValue": "5", "bestRating": "5", "worstRating": "1"}, "datePublished": "2017-08-03", "author": {"@type": "Person", "name": "Carly Wheeler"}, "publisher": {"@type": "Organization", "name": "Feefo"} } }';
        }
        ProductSchema.onload = resolve;
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
        document.body.appendChild(ProductSchema);
      });
    });
    this.bannerFrm = this.formBuilder.group({
      sortname: [null],
    });
  }
  
  apistart(newurl = ''){
    let urldata = newurl.split('/');
    this.api.getSpecificData(newurl).subscribe(data => {
      this.boxesandsuppliesData  = data;
      let locdat = data['boxesandsupplies']['All_Supply']['parent'].filter(x => x['slug'] === urldata[2]);
      this.title.setTitle(locdat[0]['meta_title']);
      // this.meta.updateTag({ name: 'title', content: locdat[0]['MetaTitle'] });
      this.meta.updateTag({ name: 'description', content: locdat[0]['meta_description'] });
      this.meta.updateTag({ name: 'keywords', content: locdat[0]['meta_keyword'] });

      if(data['boxesandsupplies']['All_Supply']['child'] != null || data['boxesandsupplies']['All_Supply']['child'] != undefined){
        this.boxesChildData  = data['boxesandsupplies']['All_Supply']['child'] as string[];
        this.Count_img = Object.keys(this.boxesChildData).length;
        for (let i = 0; i < this.Count_img; i++) {        
          const src = this.boxesChildData[i]['main_image'];
          const caption = this.boxesChildData[i]['name'];
          const thumb = this.boxesChildData[i]['main_image'];
          const album = {
            src: src,
            caption: caption,
            thumb: thumb
          };         
          this._albums.push(album);
        }
      }
     // console.log(this.boxesChildData)
      if (this.router.url.indexOf('/') > -1) {
        let urldata = this.router.url.split('/');
        if(urldata[2]){
          this.urlData = urldata[2];
          this.boxesParentData.forEach((k1, v1) => {
            if(k1['slug'] == urldata[2]){
              this.braedcumData = k1['headertitle'];
            }
          });
        }
      }else{
        this.braedcumData = "Boxes & Supplies";
      }
    });
    this.bannerFrm = this.formBuilder.group({
      sortname: [null],
    });
  }

  getcatdata(catslug){
    let urlData = '/boxes-and-supplies/'+catslug;
    this.router.navigateByUrl(urlData);
    this.apistart(urlData);
  }

  plus(pid){
    let count = 1;
    let countEl = (<HTMLInputElement>document.getElementById(pid)).value;
    let inccount = parseInt(countEl) + count;
    (<HTMLInputElement>document.getElementById(pid)).value = String(inccount);
  }
  minus(pid){
    let count = 1;
    let countEl = (<HTMLInputElement>document.getElementById(pid)).value;
    if (parseInt(countEl) > 1) {
      (<HTMLInputElement>document.getElementById(pid)).value = String(parseInt(countEl) - count);
    }
  }  
  addtocart(event){
    let urlData = '/shopping-cart';
    let tprice;
    let unitprice;
    let Newunitpprice;
    let newcartArr = [];
    let cart = this.cartArr;
    let qtydata;    
    if (cart != undefined){
      let index = this.cartArr.map((i) => i.id).indexOf(event.id);
      if (index >= 0){
        qtydata = parseInt((<HTMLInputElement>document.getElementById(event.id)).value) + parseInt(this.cartArr[index]['qty']);
      }else{
        qtydata = (<HTMLInputElement>document.getElementById(event.id)).value;
      }
    }else{
      qtydata = (<HTMLInputElement>document.getElementById(event.id)).value;
    }
    if(parseInt(event.more_price) != 0){
      if(parseInt(qtydata) >= 1 && parseInt(qtydata) < event.more_qty){
        tprice = parseInt(qtydata) * event.price;
        unitprice = event.price;
        Newunitpprice = event.price;
      }else if(parseInt(qtydata) >= event.more_qty){
        tprice = parseInt(qtydata) * event.more_price;
        unitprice = event.price;
        Newunitpprice = event.more_price;
      }else{
        tprice = event.price;
        unitprice = event.price;
        Newunitpprice = event.price;
      }
    }else{
      if(parseInt(qtydata) >= 1){
        tprice = parseInt(qtydata) * event.price;
        unitprice = event.price;
        Newunitpprice = event.price;
      }else{
        tprice = event.price;
        unitprice = event.price;
          Newunitpprice = event.price;
      }
    }
    
    newcartArr = [{ "id": event.id, 'parentCat': event.parentCat, "name": event.name, "qty": qtydata, "price": tprice,"unitpprice":unitprice,"Newunitpprice":Newunitpprice,"more_qty":event.more_qty,"more_price":event.more_price}];

    if(cart == undefined){
      this.cartArr = newcartArr;
      localStorage.setItem('cart', JSON.stringify(newcartArr));
    }else{
      let index =this.cartArr.map((i) => i.id).indexOf(event.id);
      delete this.cartArr[index];
      var filtered = cart.filter(function (el) {
        return el != null;
      });
      this.cartArr = filtered.concat(newcartArr);
      localStorage.setItem('cart', JSON.stringify(filtered.concat(newcartArr)));
    }
    this.router.navigateByUrl(urlData);

  }
  refreshUrl(){
    let urlData = '/boxes-and-supplies';
    this.router.navigateByUrl(urlData);
  }
  getDataByFilter(){
    if(this.bannerFrm.value['sortname'] == "latest"){
      this.boxesChildData.sort(function ( a, b ) { return b['id'] - a['id']; } );
    }else if(this.bannerFrm.value['sortname'] == "pricel2h"){
      this.boxesChildData.sort(function ( a, b ) { return a['price'] - b['price']; } );
    }else if(this.bannerFrm.value['sortname'] == "priceh2l"){
      this.boxesChildData.sort(function ( a, b ) { return b['price'] - a['price']; } );
    }else{
      this.boxesChildData.sort(function ( a, b ) { return b['sort_order'] - a['sort_order']; } );
    }
  }
}
