import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe, Location  } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-instant-quote',
  templateUrl: './instant-quote.component.html',
  styleUrls: ['./instant-quote.component.css']
})
export class InstantQuoteComponent implements OnInit {
	FindstorageTypeData: string[];
	FindstorageLocationData: string[];
	FindstorageSizeData: string[];
	FindReviews: string[];
	FindCategoryDetails: string[];
	FindProductDetails: string[];
	BaseUrl: any;
	minDate: Date;
	storage_type_id: number;
	location_id: number;
	hiring_type: number;
	price_list: string;
	quoteForm: FormGroup;
	instantquoteData: any = Object;
	formSumitAttempt: any;
	displayError: any = Object;
	display_error_msg: boolean = false; 
	locationError: boolean = false;
	PickupDateError: boolean = false;
	DropoffDateError: boolean = false;
	ftlocationError: boolean = false;
	ftPickupDateError: boolean = false;
	ftDropoffDateError: boolean = false;
	storage_typeRadio: boolean = false;
	radioclicked:boolean = false;
	sub_categories: any = Object;
	storageName: any;
	summary: any = Object;
	summaryHtml: string = "";
	constructor(private api: ApiService, private formBuilder: FormBuilder, public datepipe: DatePipe, @Inject('BASE_URL') baseUrl: string,private meta: Meta,private title: Title) { 
		this.BaseUrl = baseUrl; 
	}

	//function when more details button is clicked
	MoreDetailsBtnClicked(locationid){
		//show the card whose button is pressed
		var location_card = document.getElementById("more-details-card-"+locationid);
		var more_details_btn = document.getElementById("more-detail-btn-"+locationid);
		if(more_details_btn.classList.contains('less-details')){
			location_card.setAttribute('style','display:none');
			more_details_btn.classList.remove('less-details');
			more_details_btn.classList.add('more-details');
			(<HTMLInputElement>more_details_btn).innerHTML = "<span>+More Details</span>";
		}else{
			location_card.setAttribute('style','display:block');
			more_details_btn.classList.remove('more-details');
			more_details_btn.classList.add('less-details');
			(<HTMLInputElement>more_details_btn).innerHTML = "<span>-Less Details</span>";
		}
	}

	// function rescaling for captcha
	rescaleCaptcha() {
		document.querySelectorAll(".angular-google-recaptcha-container").forEach(function () {
			const width = document.querySelector('.captchaDiv').clientWidth;
			let scale;
			if (width < 302) {
				scale = width / 302;
			} else {
				scale = 1.0;
			}
			document.querySelector(".angular-google-recaptcha-container").setAttribute("style", "transform:scale(" + scale + ");-webkit-transform:scale(" + scale + ");transform-origin:0 0;-webkit-transform-origin:0 0;");
		});
	}
	ngOnInit() {
		this.storageName = "Personal";
		this.sub_categories = {};
		this.summary = {};
		this.api.getData().subscribe(data => {
			this.instantquoteData = data;
			this.FindstorageTypeData = this.instantquoteData['getaquote']['StorageType'];
			this.FindReviews = this.instantquoteData['getaquote']['LocationSpecificReviews'];
			this.FindstorageSizeData = this.instantquoteData['getaquote']['CategoryList'];
			this.FindProductDetails = this.instantquoteData['getaquote']['ProductDetails'];
			this.FindstorageLocationData = this.instantquoteData['getaquote']['LocationData'];
			Object.keys(this.FindstorageLocationData).forEach(loca_items =>{
                if(this.FindstorageLocationData[loca_items].image != null){
                    this.FindstorageLocationData[loca_items].image = JSON.parse(this.FindstorageLocationData[loca_items].image)
                }
				if(this.FindstorageLocationData[loca_items].phone != null){
					this.FindstorageLocationData[loca_items].phone = this.FindstorageLocationData[loca_items].phone.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3");
				}	
            });
			this.loadScript('assets/js/datePickerCustom.js');
			this.loadScript('assets/js/dateformPicker.js');
			this.rescaleCaptcha();
			this.title.setTitle("Get a Quote for Reliable Storage Solutions | Hills Self Storage");
			this.meta.updateTag({ name: 'description', content: "Request a quote for secure and convenient self-storage solutions at Hills Self Storage. We offer a range of storage options tailored to your needs. Get a quote now!"});

			// this.instantquoteData['instantquote']['CategoryList'].forEach(items =>{
			// 	if(new_array[items.id] == null){
			// 		new_array[items.id] = items.id;
			// 		storage_data_array.push(items);
			// 	}
			// });
		});
		this.minDate = new Date();
		this.minDate.setDate(this.minDate.getDate());
		this.quoteForm = this.formBuilder.group({
			first_name: [null, [Validators.required,this.noSpaceAllowed]],
			last_name: [null, [Validators.required,this.noSpaceAllowed]],
			email: [null, [Validators.required, Validators.email]],
			contact_number: [null, [Validators.required]],
			move_date: [null, [Validators.required]],
			storage_type: [null, [Validators.required]],
			storage_for: [null, [Validators.required]],
			storage_size: [null, [Validators.required]],
			hire_type: [null, [Validators.required]],
			comment: [''],
			location: [null, [Validators.required]],
			category: [null],
			sub_category: [null],
			request_type: ['Quote'],
			form_title: ['price_list_enquiry'],
			recaptcha: [false, [Validators.required]],
			ft_ampm_pickup:['AM'],
			ft_ampm_dropoff:['AM'],
		 	frt_ampm_pickup:['AM'],
			frt_ampm_dropoff:['AM'],
			PickupDate: [''],
			DropoffDate: [''],
			product_id: [null, [Validators.required]],
			price_list:[null],
			ftValidhire: ['Bhrs'],
			ftValidage: [true],
			ftPickupDate: [''],
			ftDropoffDate: [''],
			free_trailer: [false],
			FreeTruck: [false],
			book_now_flag:[false],
			ftlocation: [null],
			sub_categories:[null]
		});
	}
	noSpaceAllowed(control:FormControl){
		const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? null : { 'whitespace': true };
	}
	onSubmit() {
		let errorCount = 0;
		let formPost = false;
		this.formSumitAttempt = true;
		this.quoteForm.controls['storage_for'].setValue(this.storageName);
		this.quoteForm.controls['category'].setValue(this.quoteForm.controls['storage_type'].value);
		this.quoteForm.controls['storage_type'].setValue(this.quoteForm.controls['storage_for'].value);
		this.quoteForm.controls['location'].setValue(this.quoteForm.controls['location'].value);
		this.quoteForm.controls['ftlocation'].setValue(this.quoteForm.controls['location'].value);
		this.quoteForm.controls['product_id'].setValue(0);
		this.quoteForm.controls['sub_categories'].setValue(this.sub_categories);
		this.quoteForm.value.move_date = this.datepipe.transform(this.quoteForm.value.move_date, 'yyyy-MM-dd');
		// this.quoteForm.controls['DropoffDate'].setValue((<HTMLInputElement>document.getElementById('DropoffDates')).value);
		// this.quoteForm.controls['PickupDate'].setValue((<HTMLInputElement>document.getElementById('PickupDates')).value);
		// this.quoteForm.controls['ftDropoffDate'].setValue((<HTMLInputElement>document.getElementById('DropoffDate')).value);
		// this.quoteForm.controls['ftPickupDate'].setValue((<HTMLInputElement>document.getElementById('PickupDate')).value);

		var hire_type = this.hiring_type;
		if(hire_type == 1){
			this.quoteForm.controls['FreeTruck'].setValue(1);
			this.quoteForm.controls['free_trailer'].setValue(0);
		}else if(hire_type == 2) {
			this.quoteForm.controls['FreeTruck'].setValue(0);
			this.quoteForm.controls['free_trailer'].setValue(1);
		}

		//truck hire
		if (hire_type == 1) { 
			if (this.quoteForm.value.ftlocation !== '') {
				this.ftlocationError = false;
			}else{
				errorCount++;
				this.ftlocationError = true;
			}
		}else{
			this.ftlocationError = false;
		}
		
		if (errorCount == 0){
			formPost = true;
		}else{
			formPost = false;
		}
		//console.log(this.quoteForm.value);
		if (this.quoteForm.valid && formPost == true) {
			this.formSumitAttempt = false;
			document.getElementById('submit').setAttribute('style', "pointer-events:none;");
			//document.getElementById('instant-quote-form-details').setAttribute('style', "display:none;");
			document.getElementById('step2Loading').setAttribute('style', "display:block;");
			this.api.postData(this.quoteForm.value).subscribe(data => {
				if (data != null && data['status'] != undefined && data['status'] == 200 && data['EmailMessage'] == "Email Sent.") {
					let temp = this.quoteForm.controls['storage_type'].value.toLowerCase().split(" ");
					var slug_name = temp[0]+"-"+temp[1];
					let url = "/quote-thank-you";
					window.location.href=url;
				} else {
					console.log("error");
					document.getElementById('submit').setAttribute('style', "pointer-events:all");
					document.getElementById('errorMsg').setAttribute("style", "display:block");
					document.getElementById('step2Loading').setAttribute('style', "display:none;");
					(<HTMLInputElement>document.getElementById('errorMsg')).textContent = data['EmailMessage'];
				}
			});
		}
	}

	public loadScript(url: string) {
		const body = <HTMLDivElement>document.body;
		const script = document.createElement('script');
		script.innerHTML = '';
		script.src = url;
		script.async = false;
		script.defer = true;
		body.appendChild(script);
	}

	isFieldValid(field: string) {
		if(this.quoteForm.get(field).invalid && this.quoteForm.get(field).touched){
			return true;
		}
		if(this.formSumitAttempt && this.quoteForm.get(field).invalid && this.quoteForm.get(field).untouched ){
			return true;
		}
	}

	//function to toogle form to next sections
	HideandShowFormSections(form_section,move_direction,field=null,selected_class = null){
		this.display_error_msg = false;

		//move in forward direction
		if(move_direction == 1){
			this.formSumitAttempt = true;

			//special validation checking when form section is 3
			if(form_section == 3){
				
				if(Object.keys(this.sub_categories).length === 0){
					this.quoteForm.controls[field].reset();
					this.quoteForm.controls['product_id'].reset();
				}
				if(this.quoteForm.get(field).valid && this.quoteForm.get('product_id').valid){
					
					//uncheck all the storage-size card (parent) elements whose details-available (childs) are not checked
					for (const cat_id in this.sub_categories) {
						if(Object.keys(this.sub_categories[cat_id]).length == 1){
							delete this.sub_categories[cat_id];
							var uncheck_parent_element = document.getElementsByClassName('size-'+cat_id);
							for (var i = 0; i < uncheck_parent_element.length; i++) {
								if(typeof uncheck_parent_element.item(i) !== null){
									(<HTMLInputElement>uncheck_parent_element.item(i)).checked = false;
									(<HTMLElement>uncheck_parent_element.item(i).parentNode.parentNode).classList.remove('active');
								}	
							}
						}
					}
					this.formSumitAttempt = false;
					var next_form_section = parseInt(form_section ) + 1;
					document.getElementById("progress-"+next_form_section).classList.add('active');
					document.getElementById("progress-"+form_section).classList.add('step_success');
					document.getElementById("progress-text-"+form_section).setAttribute("style","display:none");
					var hide_form_section = document.getElementById("tabnum"+form_section);
					var show_form_section = document.getElementById("tabnum"+next_form_section);
					if(hide_form_section){
						hide_form_section.setAttribute("style", "display:none");
					}
					if(show_form_section){
						show_form_section.setAttribute("style", "display:block");
					}
				}else if(!this.quoteForm.get(field).valid){
					document.getElementById("storage-size-error").setAttribute("style","display:block");
				}else if(!this.quoteForm.get('product_id').valid){
					document.getElementById("product-error").setAttribute("style","display:block");
				}
				return;
			}else if(this.quoteForm.get(field).valid){
				document.getElementById("progress-"+form_section).classList.add('step_success');
				document.getElementById("progress-text-"+form_section).setAttribute("style","display:none");
				if(form_section == 1){
					var location_cards = document.getElementsByClassName("storageLocationli")
					for (var i = 0; i < location_cards.length; i++) {
						if(typeof location_cards.item(i) !== null){
							location_cards.item(i).setAttribute("style", "display:none");
						}	
					}
					Object.keys(this.FindstorageTypeData).forEach(storage_items =>{
						if(this.FindstorageTypeData[storage_items].cat_name === this.quoteForm.controls['storage_type'].value){
							this.storage_type_id = this.FindstorageTypeData[storage_items].id;
							//console.log(this.storage_type_id);
							if(this.storage_type_id == 10){
								this.storageName = "Business";
							}else{
								this.storageName = "Personal";
							}
							var location_key = Object.keys(this.FindstorageTypeData[storage_items].location);
							if(location_key != null){
								var html = "";
								for(var iterator = 0; iterator<location_key.length;iterator++){
									var location_card_id = this.FindstorageTypeData[storage_items].location[iterator]['id'];
									document.getElementById("location-card-"+location_card_id).setAttribute("style", "display:block");
								}
							}
						}
					});
				}else if(form_section == 2){
					var storage_size_cards = document.getElementsByClassName("storageSizeli")
					for (var i = 0; i < storage_size_cards.length; i++) {
						if(typeof storage_size_cards.item(i) !== null){
							storage_size_cards.item(i).setAttribute("style", "display:none");
						}	
					}
					Object.keys(this.FindstorageLocationData).forEach(location_items =>{
						if(this.FindstorageLocationData[location_items].name == this.quoteForm.controls['location'].value){
							this.location_id = this.FindstorageLocationData[location_items].id;
						}
					});
					Object.keys(this.FindstorageSizeData).forEach(storage_size_items =>{
						if(this.FindstorageSizeData[storage_size_items].category_id == this.storage_type_id){
							var show_storage_size_cards = document.getElementsByClassName("category-card-"+this.location_id+"-"+this.storage_type_id);
							for (var i = 0; i < show_storage_size_cards.length; i++) {
								if(typeof show_storage_size_cards.item(i) !== null){
									show_storage_size_cards.item(i).setAttribute("style", "display:block");
								}	
							}
						}
					});
				}else if(form_section == 4){ 
                    //document.getElementById("trailer-fields").setAttribute("style", "display:none");
                    document.getElementById("truck-fields").setAttribute("style", "display:none");
                    this.hiring_type = parseInt(this.quoteForm.controls['hire_type'].value);
                    if(this.hiring_type == 2){
                        //document.getElementById("trailer-fields").setAttribute("style", "display:block");
                    }else if(this.hiring_type == 1 ){
                        document.getElementById("truck-fields").setAttribute("style", "display:block");
                    }
                }
				this.formSumitAttempt = false;
				var next_form_section = parseInt(form_section ) + 1;
				document.getElementById("progress-"+next_form_section).classList.add('active');
			}else{
				this.display_error_msg = true;
				return ;
			}
		}else{ 
			document.getElementById("storage-size-error").setAttribute("style","display:none");
			document.getElementById("product-error").setAttribute("style","display:none");

			//reset the progress of progress bar to active again
			document.getElementById("progress-"+form_section).classList.remove('step_success');
			document.getElementById("progress-text-"+form_section).setAttribute("style","display:block");

			//hide active card which are clicked
			var hide_active_elements = document.getElementsByClassName(selected_class);
			for (var i = 0; i < hide_active_elements.length; i++) {
				if(typeof hide_active_elements.item(i) !== null && hide_active_elements.item(i).classList.contains("active")){
					hide_active_elements.item(i).classList.remove("active");
				}	
			}

			//reset the form
			if (field != null) {
				this.quoteForm.controls[field].reset();
			}

			//for form section 3 make the product field reset
			if(form_section == 3 && field != null){
				this.quoteForm.controls['product_id'].reset();
				this.sub_categories = {};
			}

			//when the back button is pressed and the form is traversed from section 4 to section 3
			//show the details available card whose first storage-size card in checked from the hashmap
			if(form_section == 4){
				var details_card_hide = document.getElementsByClassName('space_detail_box');
				for (var i = 0; i < details_card_hide.length; i++) {
					if(typeof details_card_hide.item(i) !== null){
						details_card_hide.item(i).setAttribute("style", "display:none");
					}	
				}
				for (const cat_id in this.sub_categories) {
					if(Object.keys(this.sub_categories[cat_id]).length > 1){
						var details_card_show = document.getElementsByClassName('detail-card-'+cat_id+"-"+this.location_id);
						for (var i = 0; i < details_card_show.length; i++) {
							if(typeof details_card_show.item(i) !== null){
								details_card_show.item(i).setAttribute("style", "display:flex");
							}	
						}
						break;
					}
				}
			}
			
			if(form_section == 2){
				delete this.summary['Storage Location'];
			}else if(form_section == 3){
				delete this.summary['Storage Sizes'];
			}
			else if(form_section == 4){
				delete this.summary['Hire Type'];
			}

			this.updateSummaryHtml(this.summary);

			//hide the details available in 3 section of form
			if(field == "storage_size"){
				document.getElementById("details-available-section").setAttribute('style','display:none');
				var details_card_hide = document.getElementsByClassName('space_detail_box');
				for (var i = 0; i < details_card_hide.length; i++) {
					if(typeof details_card_hide.item(i) !== null){
						details_card_hide.item(i).setAttribute("style", "display:none");
						details_card_hide.item(i).classList.remove("active");
					}	
				}
			}
			var next_form_section = parseInt(form_section) - 1;
		}
		var hide_form_section = document.getElementById("tabnum"+form_section);
		var show_form_section = document.getElementById("tabnum"+next_form_section);
		if(hide_form_section){
			hide_form_section.setAttribute("style", "display:none");
		}
		if(show_form_section){
			show_form_section.setAttribute("style", "display:block");
		}
	}

	//function to check radio button's status
	checkStatus(event:any,class_name,form_step = null){
		if(form_step == 1){
			this.summary['Storage Type'] = this.quoteForm.controls['storage_type'].value;
		}else if(form_step == 2){
			this.summary['Storage Location'] = this.quoteForm.controls['location'].value;
		}else if(form_step == 4){
			var type_hiring = parseInt(event.target.value);
			if(type_hiring == 2){
				this.summary['Hire Type'] = "Trailer hire";
			}else if(type_hiring == 1){
				this.summary['Hire Type'] = "Truck hire";
			}else{
				this.summary['Hire Type'] = "No hire";
			} 
		}
		this.updateSummaryHtml(this.summary);
		//don't remove active card for step 3 of form
		if(event.target.classList[0] != "storage-size" && event.target.classList[0] != "product-name"){
			var cards = document.getElementsByClassName(class_name);
			if(cards.length > 0){
				for (var i = 0; i < cards.length; i++) {
					if(typeof cards.item(i) !== null && cards.item(i).classList.contains("active")){
						cards.item(i).classList.remove("active");
					}	
				}
			}
		}
		// Check if radio button is checked
		if(event.target.checked == true){
			//make the parent active
			event.target.parentNode.parentNode.classList.add('active');

			//make the details section visible in form section 3 when radio is clicked
			//the code will get executed in the step 3 section of form when storage size card is checked
			if(event.target.classList[0] == "storage-size"){
				document.getElementById("details-available-section").setAttribute("style","display:block");
				var category_id = event.target.id.split('-')[3];
				this.sub_categories[category_id] = {
					n: event.target.value
				};
				var details_card_hide = document.getElementsByClassName('space_detail_box');
				for (var i = 0; i < details_card_hide.length; i++) {
					if(typeof details_card_hide.item(i) !== null){
						details_card_hide.item(i).setAttribute("style", "display:none");
					}	
				}
				var details_card_show = document.getElementsByClassName('detail-card-'+category_id+"-"+this.location_id);
				for (var i = 0; i < details_card_show.length; i++) {
					if(typeof details_card_show.item(i) !== null){
						details_card_show.item(i).setAttribute("style", "display:flex");
					}	
				}
				for (const cat_id in this.sub_categories) {
					if(cat_id != category_id && Object.keys(this.sub_categories[cat_id]).length == 1){
						delete this.sub_categories[cat_id];
						var uncheck_parent_element = document.getElementsByClassName('size-'+cat_id);
						for (var i = 0; i < uncheck_parent_element.length; i++) {
							if(typeof uncheck_parent_element.item(i) !== null){
								(<HTMLInputElement>uncheck_parent_element.item(i)).checked = false;
								(<HTMLElement>uncheck_parent_element.item(i).parentNode.parentNode).classList.remove('active');
							}	
						}
					}
				}
			}

			//the code will get executed in the step 3 section of form when product card is checked
			else if(event.target.classList[0] == "product-name"){
				event.target.parentNode.classList.add('active');
				this.price_list = event.target.id.split('-')[2];
				var parent_cat_id = event.target.classList[1].split('-')[1];
				if(this.sub_categories[parent_cat_id] === undefined){
					var check_parent_element = document.getElementsByClassName('size-'+parent_cat_id);
					var parent_name = event.target.classList[2].split('|')[2];
					for (var i = 0; i < check_parent_element.length; i++) {
						if(typeof check_parent_element.item(i) !== null){
							(<HTMLInputElement>check_parent_element.item(i)).checked = true;
							(<HTMLElement>check_parent_element.item(i).parentNode.parentNode).classList.add('active');
							parent_name = (<HTMLInputElement>check_parent_element.item(i)).value;
						}	
					}
					this.sub_categories[parent_cat_id] = {
						n: parent_name,
					};
				}
				this.sub_categories[parent_cat_id][event.target.value]={
					['n']:this.price_list
				};
				this.summary['Storage Sizes'] = this.sub_categories;
				this.updateSummaryHtml(this.summary);
			}
		}else{
			//the code will get executed in the step 3 section of form when product card is unchecked
			if(event.target.classList[0] == "product-name"){
				event.target.parentNode.classList.remove('active');
				var parent_cat_id = event.target.classList[1].split('-')[1];
				delete this.sub_categories[parent_cat_id][event.target.value];
				if(Object.keys(this.sub_categories[parent_cat_id]).length == 1){
					delete this.sub_categories[parent_cat_id];
					var uncheck_parent_element = document.getElementsByClassName('size-'+parent_cat_id);
					for (var i = 0; i < uncheck_parent_element.length; i++) {
						if(typeof uncheck_parent_element.item(i) !== null){
							(<HTMLInputElement>uncheck_parent_element.item(i)).checked = false;
							(<Element>uncheck_parent_element.item(i).parentNode.parentNode).classList.remove('active');
						}	
					}
				}
				this.summary['Storage Sizes'] = this.sub_categories;
				this.updateSummaryHtml(this.summary);
			}

			//the code will get executed in the step 3 section of form when storage size card is unchecked
			else if(event.target.classList[0] == "storage-size"){
				var category_id = event.target.id.split('-')[3];
				if(Object.keys(this.sub_categories[category_id]).length != 1){
					event.target.checked = true;
					var details_card_hide = document.getElementsByClassName('space_detail_box');
					for (var i = 0; i < details_card_hide.length; i++) {
						if(typeof details_card_hide.item(i) !== null){
							details_card_hide.item(i).setAttribute("style", "display:none");
						}	
					}
					var details_card_show = document.getElementsByClassName('detail-card-'+category_id+"-"+this.location_id);
					for (var i = 0; i < details_card_show.length; i++) {
						if(typeof details_card_show.item(i) !== null){
							details_card_show.item(i).setAttribute("style", "display:flex");
						}	
					}
				}else{
					event.target.parentNode.parentNode.classList.remove('active');
					delete this.sub_categories[category_id];
					var uncheck_child_elements = document.getElementsByClassName('category-'+category_id);
					for (var i = 0; i < uncheck_child_elements.length; i++) {
						if(typeof uncheck_child_elements.item(i) !== null){
							(<HTMLInputElement>uncheck_child_elements.item(i)).checked = false;
							(<HTMLElement>uncheck_child_elements.item(i).parentNode).classList.remove('active');
	
						}	
					}
					this.summary['Storage Sizes'] = this.sub_categories;
					this.updateSummaryHtml(this.summary);
				}
			}
		}
	}
	updateSummaryHtml(summary){
		this.summaryHtml = "";
		this.summaryHtml += `<div class="summary-div">`
		for (var keys in summary) {
			if(keys != "Storage Sizes"){
				this.summaryHtml += `<ul class="summary-items">`
				this.summaryHtml += `<li><b class="summary-label">`+keys+`:</b> `+summary[keys]+`</li>`;
				this.summaryHtml += `</ul>`
			}else{
				this.summaryHtml += `<ul class="summary-items storage-size"><li><b class="summary-label">`+keys+`:</b><ul class="summary-rooms"><li>`;
				var size_items = summary[keys];
				for (var storage_side_id in size_items) {
					this.summaryHtml += `<ul class="summary-inner-room"><li><b class="summary-label">`+size_items[storage_side_id]['n']+`</b></li>`;
					var product_items = size_items[storage_side_id];
					for (var product_id in product_items) {
						if(product_id == "n"){
							continue;
						}
						this.summaryHtml += `<li><p>`+product_items[product_id]['n']+`</p>`;
					}
					this.summaryHtml += `</li></ul>`
				}
				this.summaryHtml += `</li></ul></li></ul>`;
			}
		}
		this.summaryHtml += `</div>`;
		(<HTMLElement>document.getElementById('summary-box')).innerHTML = this.summaryHtml;
	}
	
	displayFieldCss(field: string) {
		return { 'has-error': this.isFieldValid(field), 'has-feedback': this.isFieldValid(field) };
	}
	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	openCollapsible(event:any){
		var content = event.target.nextElementSibling;
		event.target.classList.toggle('active_summary');
		if (content.style.display === "block") {
			(<HTMLElement>document.getElementById('summary-box')).setAttribute('style','display:none');
		} else {
			(<HTMLElement>document.getElementById('summary-box')).setAttribute('style','display:block');
		}
	}
	onScriptLoad() { }
	onScriptError() { }
}
