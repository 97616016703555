import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-self-storage-locations',
  templateUrl: './self-storage-locations.component.html',
  styleUrls: ['./self-storage-locations.component.css']
})
export class SelfStorageLocationsComponent implements OnInit {
  searchData: any = Object;
  LocationData: string[];
  FindstorageTypeData: string[];
  LocationbannerFrm: string[];
  LocationConfigData: any = Object;
  storageBannerData: any;
  BaseUrl:string;
  constructor(private api: ApiService, private router: Router, private meta: Meta, private title: Title, @Inject('BASE_URL') baseUrl: string) {
    this.BaseUrl = baseUrl;}
  slug = this.router.url;
  ngOnInit(){
    this.api.getData().subscribe(data => {
      this.searchData = data;
      this.title.setTitle(data['selfstoragelocations']['SEO']['MetaTitle']);
      // this.meta.updateTag({ name: 'title', content: data['selfstoragelocations']['SEO']['MetaTitle'] });
			this.meta.updateTag({ name: 'description', content: data['selfstoragelocations']['SEO']['MetaDescription'] });
      this.meta.updateTag({ name: 'keywords', content: data['selfstoragelocations']['SEO']['MetaKeywords'] });

      if(data['selfstoragelocations']['LocationData'] != null || data['selfstoragelocations']['LocationData'] != undefined){
        let newIndex = [];
        let checkImage:any = data['selfstoragelocations']['LocationData'] as string[];
        checkImage.forEach((k1, v1) => {
          newIndex[v1] = k1;
          newIndex[v1]['image'] = JSON.parse(newIndex[v1]['image']);
        });
        this.LocationData = newIndex;
      }
      if(data['selfstoragelocations']['Banner'] != null || data['selfstoragelocations']['Banner'] != undefined){
        this.storageBannerData  = data['selfstoragelocations']['Banner'];
        if(this.storageBannerData['image_name'] != undefined && this.storageBannerData['image_name'] != ''){
          this.storageBannerData  = this.storageBannerData['image_name'];
        }else{
            this.storageBannerData  = this.storageBannerData['default'];
        }
      }
      if(data['selfstoragelocations']['LocationConfig'] != null || data['selfstoragelocations']['LocationConfig'] != undefined){
        this.LocationConfigData  = JSON.parse(data['selfstoragelocations']['LocationConfig']);
      }

      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);

        const BreadcrumbSchema = document.createElement('script');
        BreadcrumbSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";

        BreadcrumbSchema.innerHTML = '{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"' + this.BaseUrl + '"},{"@type":"ListItem","position":2,"name":"Our Locations","item":"' + location.href + '"}]}';
        BreadcrumbSchema.onload = resolve;
        document.body.appendChild(BreadcrumbSchema);
      });
    });

    setTimeout(function () {
			this.equalheight('.eqheight');
		}, 2000);

  }
  gotolink(locData){
    var urlData = '/self-storage-location/'+locData;
    this.router.navigateByUrl(urlData);
  }
  StorageDataChange(event, formname) {    
    let text = event.target.options[event.target.options.selectedIndex].text;
    event.target.parentNode.firstChild.textContent = text;
    event.target.parentNode.parentNode.nextSibling.firstChild.firstChild.textContent = "Select Storage location";

    let newIndex = [];
    this.FindstorageTypeData = this.searchData['selfstoragelocations']['StorageType'];
    let dat = this.FindstorageTypeData.filter(x => x['slug'] === event.target.value);
    if (dat[0]['loc_name'] != null) {
      if (dat[0]['loc_name'].indexOf(',') > -1) {
        let Ldata = dat[0]['loc_name'].split(',');
        Ldata.forEach((k1, v1) => {
          newIndex[v1] = k1.split('@');
        });
        this.LocationbannerFrm = newIndex;
      } else {
        this.LocationbannerFrm = [dat[0]['loc_name'].split('@')];
      }
    }
  }
}
