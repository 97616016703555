import { Component, OnInit ,Inject} from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  BaseUrl:string;
  data: any= Object;
  showonlinemovein:any;
  showDefault:any;
  constructor( private api: ApiService, private router: Router,@Inject('BASE_URL') baseUrl: string) {
    this.BaseUrl = baseUrl; 
  }

  ngOnInit() {
    if(this.router.url != '/'){
      let urls= this.router.url.replace(/^\/|\/$/g, '');
      var baseUrl = this.BaseUrl + urls;       
    }else{
      var baseUrl = this.BaseUrl;
    }
	console.log(222);
	var pos = 0;
	pos = baseUrl.search("online-move-in-thank-you");
	if(pos >0){
		this.showonlinemovein = 1;
		this.showDefault = 0;
		console.log("1===="+pos);
	}else{
		this.showonlinemovein = 0;
		this.showDefault = 1;
		console.log("0===="+pos);
	}
	console.log(this.showonlinemovein+"=="+this.showDefault);
	
    document.getElementById('canonical_data').setAttribute('href', baseUrl);
    localStorage.removeItem('cart');
    let lname = {'form_title':"thank_you"};
    this.api.postData(lname).subscribe(data=>{
      this.data = data;
      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = data['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = data['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = data['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);
      });
    });
  }

}
