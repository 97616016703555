import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  BaseUrl: string;
  //public apiUrl = "http://localhost/2017/hills-self-storage/admin/apirequest";
  //public apiUrl= "https://hills.webential.com/admin/apirequest";
  public apiUrl = "https://www.hillsselfstorage.com.au/admin/apirequest";
  //public apiUrl = "https://site1.webential.com/admin/apirequest";
  genReviewCnt: any = 0;
  getnRatingCnt: any = 0;
  constructor(private http: HttpClient, private router: Router,@Inject('BASE_URL') baseUrl: string) {
    this.BaseUrl = baseUrl;
  }

  getAQuoteData(): Observable<any[]> {
    let data = this.http.get<any[]>(this.apiUrl + '/get_a_quote', {
      headers: new HttpHeaders({ 'Content-Type': 'text/plain' })
    }).pipe(map(res => res));
	
    return data;
  }

  getData(): Observable<any[]> {
    if(this.router.url != '/'){
      let urls= this.router.url.replace(/^\/|\/$/g, '');
      var baseUrl = this.BaseUrl + urls;      
    }else{
      var baseUrl = this.BaseUrl;
    }
    document.getElementById('canonical_data').setAttribute('href', baseUrl);
    
    let data = this.http.get<any[]>(this.apiUrl + this.router.url, {
      headers: new HttpHeaders({ 'Content-Type': 'text/plain' })
    }).pipe(map(res => res));
	this.setReviewData(data);
    return data;
  }
  
  getSpecificData(newurl): Observable<any[]> {
    if(this.router.url != '/'){
      let urls= this.router.url.replace(/^\/|\/$/g, '');
      var baseUrl = this.BaseUrl + urls;      
    }else{
      var baseUrl = this.BaseUrl;
    }
    document.getElementById('canonical_data').setAttribute('href', baseUrl);

    return this.http.get<any[]>(this.apiUrl + newurl, {
      headers: new HttpHeaders({ 'Content-Type': 'text/plain' })
    }).pipe(map(res => res));
  }

  postData(formData): Observable<any[]> {
    if(this.router.url != '/'){
      let urls= this.router.url.replace(/^\/|\/$/g, '');
      var baseUrl = this.BaseUrl + urls;      
    }else{
      var baseUrl = this.BaseUrl;
    }
    document.getElementById('canonical_data').setAttribute('href', baseUrl);
    return this.http.post<any[]>(this.apiUrl, formData, {
      headers: new HttpHeaders({ 'Content-Type': 'text/plain' })
    }).pipe(map(data => data));
  }

  getSearchFormData(): Observable<any[]> {
    let data = this.http.get<any[]>(this.apiUrl + '/search_form', {
    }).pipe(map(res => res));
    return data;
  }
  
  setReviewData(globData) {
	globData.subscribe(data => {
		console.log(data);
		for (const key in data) {
			//console.log(`${key}:= ${data[key]}`);
			if(data[key]['SEO'] != null || data[key]['SEO'] != undefined){
				console.log("Success");
				var gReviews = JSON.parse(data[key]['SEO']['googlereview']);
				var reivewjsonData = JSON.parse(gReviews.generalReviews);
				this.genReviewCnt = gReviews.totalReviews;
				this.getnRatingCnt = gReviews.generalratings;
			}
		}
		$('.topRatingTxt').html(this.getnRatingCnt);
		$('.topRatingSericesTxtnum').html(this.genReviewCnt);
	  });
  }
}
