import { Component, OnInit ,Inject} from '@angular/core';
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-pricelist-quote-thankyou',
  templateUrl: './pricelist-quote-thankyou.component.html',
  styleUrls: ['./pricelist-quote-thankyou.component.css']
})
export class PricelistQuoteThankyouComponent implements OnInit {
  data: any= Object;
  LocstorageData: any= Object;
  feaArr: string[];
  BaseUrl:string;
  constructor(private api: ApiService, private router: Router, @Inject('BASE_URL') baseUrl: string) { this.BaseUrl = baseUrl; }

  ngOnInit() {
    let lname = {'locName':localStorage.getItem('locName'),'form_title':"price_list_qoute_thank_you"};
    if(localStorage.getItem('returnUrl')){
      if(localStorage.getItem('locName')){
        this.api.postData(lname).subscribe(data=>{
          this.data = data;
          let LocNewIndex = [];
          let checkImage: any = data['location'];
          LocNewIndex = checkImage;
          LocNewIndex['image_name'] = JSON.parse(checkImage['image'])[0]['name'];
          LocNewIndex['image_alt'] = JSON.parse(checkImage['image'])[0]['alt'];
          LocNewIndex['image_title'] = JSON.parse(checkImage['image'])[0]['title'];
          this.LocstorageData = LocNewIndex;
          
          // this.LocstorageData = data['location'];

          let Ldatas:any = [];
          let featurData = this.LocstorageData.facilities;
          if (featurData.indexOf('\r\n') > -1){
            Ldatas = featurData.split('\r\n');
          }
          this.feaArr = Ldatas;
          // this.feaArr = [];

          localStorage.removeItem('locName');      

          return new Promise(resolve => {
            const scriptElementHeader = document.createElement('script');
            // scriptElementHeader.src = "";
            scriptElementHeader.innerHTML = data['seo_header_content'];
            scriptElementHeader.onload = resolve;
            document.head.appendChild(scriptElementHeader);

            const scriptElementFooter = document.createElement('script');
            // scriptElementFooter.src = "";
            scriptElementFooter.innerHTML = data['seo_footer_content'];
            scriptElementFooter.onload = resolve;
            document.body.appendChild(scriptElementFooter);

            const scriptElementSchema = document.createElement('script');
            scriptElementSchema.type = "application/ld+json";
            // scriptElementSchema.src = "";
            scriptElementSchema.innerHTML = data['schema'];
            scriptElementSchema.onload = resolve;
            document.body.appendChild(scriptElementSchema);
          });
        });
      }else{
        this.router.navigateByUrl(localStorage.getItem('returnUrl'));
        localStorage.removeItem('returnUrl');    
      }
    }else{
      this.router.navigateByUrl('/');
    }
  }

}
